import React, { useState, useEffect } from "react";
import ActiveSection from "../ActiveSection/ActiveSection";
import { IoAddOutline, } from "react-icons/io5";
// import sbi from "../../assets/sbilogo.png";
import authFetch from "../../utils/authFetch";
import UseSellSelectorStore from "../../store/SellSelector";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/Loading";
import { StageSpinner } from "react-spinners-kit";
const SelectBankAcc = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    globalSelectedBank,
    setGlobalSelectedBank,
    globalCoin,
    globalYouSell,
    globalYouGet,
    globalQuoteData,
  } = UseSellSelectorStore();
  // Function to fetch bank accounts from the API
  const fetchBankAccounts = async () => {
    setLoading(true); // Start loading
    try {
      const response = await authFetch('/api/v1/offramp/getAllFiatAccount', { method: 'GET' });
      if (response?.code === 200) {
        setBankAccounts(response?.data); // Update state with fetched bank accounts
        setGlobalSelectedBank(response?.data[0]); // Set the first bank account as the selected bank account
        setLoading(false); // Stop loading
      }
    } catch (error) {
      console.error('Error fetching bank accounts:', error);
    }
  };
  const handleSelectedBankProceed = async () => {
    try {
      setIsSubmitting(true);
      setTimeout(() => {
        // navigate('/wallet-address');
        navigate('/review-confirm');
        setIsSubmitting(false);
      }, 1500);
    } catch (error) {
      console.error('Error fetching handleSelectedBankProceed:', error);
    }
  };
  useEffect(() => {
    fetchBankAccounts();
  }, []);

  // Function to handle the selection of a bank
  const handleBankSelect = (account) => {
    setGlobalSelectedBank(account); // Set the selected bank account object in global state
  };
  const navigateToAddBank = () => {
    navigate("/add-bank", { state: { type: 'selectbank' } }); // Navigate to the add bank page
  };
  if (loading) return <LoadingScreen />;
  return (
    <div className='w-full sm:w-80'>
      <ActiveSection type={"selectbank"} />
      <div className='flex flex-col text-center justify-center gap-6'>
        <h3 className='text-lg text-[#F4F4F4] font-medium font-general'>
          Select Bank Account
        </h3>
        <div className="flex flex-col gap-4 max-h-96 overflow-y-auto no-scrollbar">
          {
            bankAccounts.map((bank, index) => {
              return (
                <div onClick={() => handleBankSelect(bank)} key={index} className={`w-full  h-auto bg-[#F4F4F40A] p-4 rounded-lg flex flex-col justify-between ${globalSelectedBank?.fiatAccountId === bank?.fiatAccountId ? 'border border-[#A9EA2E] bg-[#F4F4F40A]' : 'bg-[#F4F4F40A]'
                  }`}>
                  {/* Header Section */}
                  <div className='flex justify-between items-center mb-2'>
                    <p className='uppercase text-white font-medium'>{bank?.shortName}</p>
                    <div className='w-6 h-6 flex items-center justify-center bg-[#1A1A1A] rounded-full'>
                      <img src={bank?.imageUrl} alt='' className="w-[22px] h-[22px] rounded-full object-cover" />
                    </div>
                  </div>
                  <div className='space-y-1 text-[#F4F4F480] text-sm'>
                    <div className='flex justify-between'>
                      <p>Account Number</p>
                      <p className='text-[#F4F4F480]'>{bank?.fiatAccount}</p>
                    </div>
                    <div className='flex justify-between'>
                      <p>Account Name</p>
                      <p className='text-[#F4F4F480]'>{bank?.account_name}</p>
                    </div>
                    <div className='flex justify-between'>
                      <p>IFSC Code</p>
                      <p className='text-[#F4F4F480]'>{bank?.ifsc}</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <button onClick={() => navigateToAddBank()} className='w-full h-11 sm:h-8 text-xs rounded-lg py-2 bg-[#F4F4F40F] text-[#A9EA2E] flex items-center justify-center gap-1'>
          <IoAddOutline className='text-base' />
          <span>Add Bank Accounts</span>
        </button>
        <button
          onClick={() => handleSelectedBankProceed()}
          disabled={globalYouSell < Number(globalCoin?.minSellValue) ||
            !globalQuoteData ||
            globalYouGet === "" || isSubmitting}
          className={`text-center  px-2 h-11 sm:h-8 text-black font-medium text-xs font-general  rounded-lg  shadow-[0px_20px_40px_0px_rgba(0,0,0,0.25)]  ${globalYouSell < Number(globalCoin?.minSellValue) ||
            !globalQuoteData ||
            globalYouGet === ""
            ? "bg-[#F4F4F480]"
            : "bg-[#A9EA2E]"
            }`}
        >
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <StageSpinner size={30} color="#000" />
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </div>
  );
};

export default SelectBankAcc;
