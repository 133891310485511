import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authFetch from "../utils/authFetch";
import useCheckOnBoardStore from '../store/CheckOnBoardStore';
import useProfileStore from '../store/profileStore';
const CheckOnboard = ({ type = "signin" }) => {
    const navigate = useNavigate();
    const { setProfile } = useProfileStore()
    const { setPhoneAdded, setBankAdded, setAadhaarAdded } = useCheckOnBoardStore();

    useEffect(() => {
        const fetchData = async () => {
            const response = await authFetch(`/api/v1/user/checkPhoneBank`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                const isPhoneAdded = response.data?.isPhoneAdded;
                const isBankAdded = response.data?.isBankAdded;
                const isKycCompleted = response.data?.userKycData?.isKycCompleted;

                setPhoneAdded(isPhoneAdded);
                setBankAdded(isBankAdded);
                setAadhaarAdded(isKycCompleted);

                if (!isPhoneAdded) {
                    setProfile();
                    navigate(type === "signin" ? "/existinguser-login" : "/user-registration", { state: { type: "addPhone" } });
                    return;
                }

                if (!isKycCompleted) {
                    setProfile();
                    navigate(type === "signin" ? "/existinguser-login" : "/user-registration", { state: { type: "addAadhaar" } });
                    return;
                }

                if (!isBankAdded) {
                    setProfile();
                    navigate(type === "signin" ? "/existinguser-login" : "/user-registration", { state: { type: "addBank" } });
                    return;
                }

                if (isPhoneAdded && isBankAdded && isKycCompleted && type !== "sell") {
                    setProfile();
                    navigate("/sell");
                } else {
                    setProfile();
                    navigate("/fiataccount")
                }
            }
        };
        fetchData();
    }, [navigate, setPhoneAdded, setBankAdded, setAadhaarAdded]);

    return null; // This component does not render anything
};

export default CheckOnboard;