import React, { useState, useEffect } from 'react'
import { StageSpinner } from "react-spinners-kit";
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import useSignUpStore from '../../store/SignupStore';
import useLoadingStore from '../../store/LoadingStore';
import LoadingScreen from '../LoadingScreen/Loading';
const GetStarted = () => {
  const navigate = useNavigate();
  const { globalEmail } = useSignUpStore()
  const { loading, setLoading } = useLoadingStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
    setLoading(true);
    navigate("/term-conditions")

  }
  // Redirect to base route if globalEmail is empty
  useEffect(() => {
    if (!globalEmail) {
      navigate('/'); // Change '/' to your desired base route
    } else {
      setLoading(false); // Stop loading when data is available
    }
  }, [globalEmail, navigate, setLoading]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [])

  if (loading) return <LoadingScreen />;
  return (
    <div className='font-general w-full sm:w-[320px] h-auto text-center -mt-10'>
      <h2 className='text-2xl font-[500] leading-[33.6px] mb-2 text-[#fff]'>Create Your Account</h2>
      <p className='font-[500] text-white/40 text-sm mb-6 opacity-80'>
        Let's set up your account in just a few <br /> steps.
      </p>
      <button
        className={`w-full h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center ${"bg-[#A7E92D] text-black"}`}
        onClick={handleSubmit}>
        {isSubmitting ? <StageSpinner size={40} color="#000" loading={isSubmitting} /> : 'Continue'}
        {!isSubmitting && <GoArrowUpRight className='text-lg ml-2' />}
      </button>
    </div>
  )
}

export default GetStarted;