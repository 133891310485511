import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authFetch from "../utils/authFetch";
import useCheckOnBoardStore from '../store/CheckOnBoardStore';
const CheckOnboard = ({ type = "signin" }) => {
    const navigate = useNavigate();
    const { setPhoneAdded, setBankAdded, setAadhaarAdded } = useCheckOnBoardStore();
    const userToken = localStorage.getItem('userToken');
    useEffect(() => {
        const fetchData = async () => {
            const response = await authFetch(`/api/v1/user/checkPhoneBank`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                const isPhoneAdded = response.data?.isPhoneAdded;
                const isBankAdded = response.data?.isBankAdded;
                const isKycCompleted = response.data?.userKycData?.isKycCompleted;

                setPhoneAdded(isPhoneAdded);
                setBankAdded(isBankAdded);
                setAadhaarAdded(isKycCompleted);

                if (isPhoneAdded && isBankAdded && isKycCompleted) {
                    if (type !== "sell") {
                        navigate("/sell"); // ✅ Navigate to /sell if all conditions are true
                    } else {
                        navigate("/fiataccount")
                    }
                } else {
                    navigate("/identity", { state: { type } }); // ❌ Navigate to /identity if any condition is false
                }
                return;
            }
        };
        if (userToken) {
            fetchData();
        } else {
            navigate("/welcome");
        }


    }, [navigate, setPhoneAdded, setBankAdded, setAadhaarAdded]);

    return null; // This component does not render anything
};

export default CheckOnboard;