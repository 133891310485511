import React, { useEffect, useState } from "react";
import { StageSpinner } from "react-spinners-kit";
import { useNavigate } from "react-router-dom";
import authFetch from "../../utils/authFetch";
import useForgetPasswordStore from "../../store/ForgetPasswordStore";
import LoadingScreen from "../LoadingScreen/Loading";
import useLoadingStore from "../../store/LoadingStore";
import { FaChevronLeft } from "react-icons/fa";
import { showToast } from "../../utils/toast";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [timer, setTimer] = useState(60);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseError, setResponseError] = useState("")
  const [verificationCode, setVerificationCode] = useState("");
  const [view, setView] = useState({
    new: false,
    confirm: false,
  });
  const [passwords, setPasswords] = useState({
    new: "",
    confirm: "",
  });
  const [focus, setFocus] = useState({
    new: false,
    confirm: false,
  });
  const [hover, setHover] = useState({
    new: false,
    confirm: false,
  });
  const { loading, setLoading } = useLoadingStore();
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const { globalEmail, globalNewPassword, setGlobalNewPassword } = useForgetPasswordStore();
  const isValidPassword = passwords.new.length >= 6 && passwords.new === passwords.confirm;

  useEffect(() => {
    if (!globalEmail) {
      navigate('/'); // Change '/' to your desired base route
    }
  }, [globalEmail, navigate]);


  const getForgetPasswordOtp = async () => {
    try {
      if (globalEmail) {
        await authFetch(`/api/v1/user/forgetOtp?email=${globalEmail}`, {
          method: 'GET', // Assuming it's a GET request
        });
      }
    } catch (err) {
      console.log("getForgetPasswordOtp:", err)
    }
  }

  useEffect(() => {
    getForgetPasswordOtp()
  }, [globalEmail])

  // Submit OTP for verification
  const handleSubmitForgetPassword = async (e) => {
    e.preventDefault()
    setIsSubmitting(true);
    let body = {
      email: globalEmail,
      otp: verificationCode,
      newPassword: globalNewPassword,
    };
    const response = await authFetch(`/api/v1/user/changeForgetPassword`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response?.code === 200) {
      showToast("Password has been Updated", "white")
      // clearGlobalEmail();
      // clearGlobalNewPassword();
      setLoading(true);
      navigate("/existinguser-login", { state: { type: "signin" } });
    } else {
      setResponseError(response?.error)
    }

  };
  const validatePassword = (type, value) => {
    let errorMessage = "";
    if (value.length < 8) errorMessage = "8 characters minimum";
    else if (!/[A-Z]/.test(value)) errorMessage = "Include uppercase letter";
    else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) errorMessage = "Include special character";
    else if (!/[0-9]/.test(value)) errorMessage = "Include at least one number";

    setError((prev) => ({ ...prev, [type]: errorMessage }));
    if (type === "new") setGlobalNewPassword(value);
  };

  const handleToggleView = (field) => {
    const input = document.querySelector(`input[name="${field}"]`);
    if (input) {
      const cursorPosition = input.selectionStart;
      setView((prevView) => ({
        ...prevView,
        [field]: !prevView[field],
      }));
      setTimeout(() => {
        input.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  };

  const handleResendOtp = async () => {
    try {
      error && setError("")
      setIsResendDisabled(true);
      setTimer(60);
      getForgetPasswordOtp();
    } catch (error) {
      console.log("handleResendOtp:", error);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
    if (error[name]) {
      setError((prev) => ({ ...prev, [name]: "" }));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [])

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  if (loading) return <LoadingScreen />;

  return (
    <div className='font-general w-full sm:w-[320px] text-center -mt-10'>
      <div className='flex items-center justify-center relative mb-2'>
        <FaChevronLeft
          onClick={() => navigate(-1)}
          className='text-gray-600 text-lg cursor-pointer absolute left-0'
        />
        <h3 className='text-lg text-[#F4F4F4] font-medium font-general'>
          Reset Password
        </h3>
      </div>
      <p className='font-[500] text-white/40 text-sm mb-5 opacity-80'>
        We've sent a 4-digit verification code to<br />
        {globalEmail}<br />
        If you don't see it,please check your spam folder and make sure your email address is spelled correctly
      </p>
      <form onSubmit={handleSubmitForgetPassword} className="flex flex-col gap-2">
        <div className="relative w-full" >
          <input
            type='text'
            name='code'
            value={verificationCode}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              if (value.length <= 4) {
                setVerificationCode(value);
              }
            }}
            maxLength={4}
            inputMode="numeric"
            pattern="\d*"
            disabled={isSubmitting}
            className={`w-full py-2 px-3 h-11  sm:h-8 text-[12px] font-medium leading-[16.8px] border border-transparent rounded-lg 
               bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
               focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
               pr-24 text-ellipsis overflow-hidden`}
            placeholder='Verification Code'
            autoComplete='off'
          />
          <div className="absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2" onMouseDown={(e) => e.preventDefault()}>
            <div className='flex flex-col justify-center gap-2 font-[500] text-white/40 text-sm  opacity-80'>
              {isResendDisabled ? (
                <p className="text-[#FFFFFF66]">
                  Resend code 00:
                  {timer < 10 ? `0${timer}` : timer}
                </p>
              ) : (
                <div className='flex justify-center items-center'>
                  <button
                    onClick={handleResendOtp}
                    className='text-white font-[500] text-xs border-b-2 border-white leading-none'
                    disabled={isResendDisabled}>
                    Resend code
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='relative w-full'>
          <input
            type={view.new ? "text" : "password"}
            name="new"
            value={passwords.new}
            onFocus={() => setFocus((prev) => ({ ...prev, new: true }))}
            onBlur={(e) => {
              validatePassword("new", passwords.new)
              setFocus((prev) => ({ ...prev, new: false }))
            }}
            onChange={handleChange}
            disabled={isSubmitting}
            className={`w-full py-2 px-3 h-11 sm:h-8 text-sm sm:text-[12px]  font-medium leading-[16.8px] border border-transparent rounded-lg 
               bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
               focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
             ${error.new ? "pr-48" : "pr-24"} text-ellipsis overflow-hidden `}
            placeholder='New Password'
            autoComplete='off'
          />

          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {(error?.new) && (
              <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general">
                {error?.new}
              </div>
            )}
            {focus.new && passwords.new.length > 0 && (
              <button
                type='button'
                onClick={() => handleToggleView("new")}
                onMouseEnter={() => setHover({ ...hover, new: true })}
                onMouseLeave={() => setHover({ ...hover, new: false })}
                disabled={isSubmitting}
              >
                {hover.new ? (
                  view.new ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M1.62373 2.12397C1.90035 1.84735 2.34884 1.84735 2.62546 2.12397L5.2017 4.70021L5.20637 4.70483L12.795 12.2934L12.7995 12.298L15.3755 14.874C15.6521 15.1506 15.6521 15.5991 15.3755 15.8757C15.0988 16.1523 14.6503 16.1523 14.3737 15.8757L12.1851 13.6871C10.354 14.708 8.30377 14.9356 6.3645 14.3479C4.25251 13.7079 2.33215 12.1208 0.970778 9.66785C0.741323 9.25441 0.73932 8.74936 0.970118 8.3334C1.70783 7.00385 2.60923 5.92866 3.61808 5.12006L1.62373 3.1257C1.3471 2.84908 1.3471 2.40059 1.62373 2.12397ZM5.66645 9C5.66645 8.47486 5.80974 7.98258 6.05883 7.56081L7.13098 8.63296C7.09974 8.74997 7.08312 8.87297 7.08312 9C7.08312 9.7824 7.71738 10.4167 8.49979 10.4167C8.62682 10.4167 8.74982 10.4 8.86682 10.3688L9.93897 11.441C9.51721 11.69 9.02493 11.8333 8.49979 11.8333C6.93498 11.8333 5.66645 10.5648 5.66645 9Z'
                        fill='white'
                      />
                      <path
                        d='M16.0275 9.6691C15.5533 10.5232 15.0111 11.2721 14.4171 11.9124L6.20581 3.70114C6.9523 3.45702 7.7226 3.33318 8.49929 3.33317C11.4004 3.33315 14.2123 5.06093 16.0275 8.3305C16.2577 8.74524 16.2577 9.25436 16.0275 9.6691Z'
                        fill='white'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.4997 3.3335C11.4008 3.33348 14.2128 5.06126 16.0279 8.33083C16.2581 8.74557 16.2581 9.25468 16.0279 9.66943C14.2128 12.939 11.4008 14.6668 8.49971 14.6668C5.59862 14.6669 2.78665 12.9391 0.971514 9.6695C0.741266 9.25476 0.741266 8.74564 0.971514 8.3309C2.78665 5.06134 5.59861 3.33352 8.4997 3.3335ZM6.02054 9.00016C6.02054 7.63096 7.1305 6.521 8.4997 6.521C9.86891 6.521 10.9789 7.63096 10.9789 9.00016C10.9789 10.3694 9.86891 11.4793 8.4997 11.4793C7.1305 11.4793 6.02054 10.3694 6.02054 9.00016Z'
                        fill='white'
                      />
                    </svg>
                  )
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='18'
                    viewBox='0 0 17 18'
                    fill='none'>
                    <g opacity='0.3'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.50019 3.33331C11.4013 3.33329 14.2132 5.06108 16.0284 8.33064C16.2586 8.74538 16.2586 9.2545 16.0284 9.66924C14.2133 12.9388 11.4013 14.6666 8.5002 14.6666C5.59911 14.6667 2.78714 12.9389 0.972002 9.66932C0.741754 9.25458 0.741755 8.74546 0.972002 8.33072C2.78714 5.06115 5.5991 3.33333 8.50019 3.33331ZM6.02103 8.99998C6.02103 7.63077 7.13099 6.52081 8.50019 6.52081C9.8694 6.52081 10.9794 7.63077 10.9794 8.99998C10.9794 10.3692 9.8694 11.4791 8.50019 11.4791C7.13099 11.4791 6.02103 10.3692 6.02103 8.99998Z'
                        fill='white'
                      />
                    </g>
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        <div className='relative w-full'>
          <input
            type={view.confirm ? "text" : "password"}
            name="confirm"
            value={passwords.confirm}
            onFocus={() => setFocus((prev) => ({ ...prev, confirm: true }))}
            onBlur={() => {
              validatePassword("confirm", passwords.confirm)
              setFocus((prev) => ({ ...prev, confirm: false }))
            }}
            onChange={handleChange}
            disabled={isSubmitting}
            className={`w-full py-2 px-3 h-11 sm:h-8 text-sm sm:text-[12px] font-medium leading-[16.8px] border border-transparent rounded-lg 
               bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
               focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general ${error.confirm ? "pr-48" : "pr-24"} text-ellipsis overflow-hidden `}
            placeholder='Re-Enter New Password'
            autoComplete='off'
          />
          {error?.confirm && (
            <span className="ml-10 absolute inset-0  rounded-lg  bg-gradient-to-r from-[rgba(255,255,255,0.01)] to-[rgba(255,0,0,0.2)] outline-none pointer-events-none"></span>
          )}
          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {/* Eye Icon for Show/Hide Password */}
            {(error?.confirm) && (
              <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general">
                {error?.confirm}
              </div>
            )}
            {focus.confirm && passwords.confirm.length > 0 && (
              <button
                type='button'
                onClick={() => handleToggleView("confirm")}
                onMouseEnter={() => setHover({ ...hover, confirm: true })}
                onMouseLeave={() => setHover({ ...hover, confirm: false })}
                disabled={isSubmitting}
              >
                {hover.confirm ? (
                  view.confirm ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M1.62373 2.12397C1.90035 1.84735 2.34884 1.84735 2.62546 2.12397L5.2017 4.70021L5.20637 4.70483L12.795 12.2934L12.7995 12.298L15.3755 14.874C15.6521 15.1506 15.6521 15.5991 15.3755 15.8757C15.0988 16.1523 14.6503 16.1523 14.3737 15.8757L12.1851 13.6871C10.354 14.708 8.30377 14.9356 6.3645 14.3479C4.25251 13.7079 2.33215 12.1208 0.970778 9.66785C0.741323 9.25441 0.73932 8.74936 0.970118 8.3334C1.70783 7.00385 2.60923 5.92866 3.61808 5.12006L1.62373 3.1257C1.3471 2.84908 1.3471 2.40059 1.62373 2.12397ZM5.66645 9C5.66645 8.47486 5.80974 7.98258 6.05883 7.56081L7.13098 8.63296C7.09974 8.74997 7.08312 8.87297 7.08312 9C7.08312 9.7824 7.71738 10.4167 8.49979 10.4167C8.62682 10.4167 8.74982 10.4 8.86682 10.3688L9.93897 11.441C9.51721 11.69 9.02493 11.8333 8.49979 11.8333C6.93498 11.8333 5.66645 10.5648 5.66645 9Z'
                        fill='white'
                      />
                      <path
                        d='M16.0275 9.6691C15.5533 10.5232 15.0111 11.2721 14.4171 11.9124L6.20581 3.70114C6.9523 3.45702 7.7226 3.33318 8.49929 3.33317C11.4004 3.33315 14.2123 5.06093 16.0275 8.3305C16.2577 8.74524 16.2577 9.25436 16.0275 9.6691Z'
                        fill='white'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.4997 3.3335C11.4008 3.33348 14.2128 5.06126 16.0279 8.33083C16.2581 8.74557 16.2581 9.25468 16.0279 9.66943C14.2128 12.939 11.4008 14.6668 8.49971 14.6668C5.59862 14.6669 2.78665 12.9391 0.971514 9.6695C0.741266 9.25476 0.741266 8.74564 0.971514 8.3309C2.78665 5.06134 5.59861 3.33352 8.4997 3.3335ZM6.02054 9.00016C6.02054 7.63096 7.1305 6.521 8.4997 6.521C9.86891 6.521 10.9789 7.63096 10.9789 9.00016C10.9789 10.3694 9.86891 11.4793 8.4997 11.4793C7.1305 11.4793 6.02054 10.3694 6.02054 9.00016Z'
                        fill='white'
                      />
                    </svg>
                  )
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='18'
                    viewBox='0 0 17 18'
                    fill='none'>
                    <g opacity='0.3'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.50019 3.33331C11.4013 3.33329 14.2132 5.06108 16.0284 8.33064C16.2586 8.74538 16.2586 9.2545 16.0284 9.66924C14.2133 12.9388 11.4013 14.6666 8.5002 14.6666C5.59911 14.6667 2.78714 12.9389 0.972002 9.66932C0.741754 9.25458 0.741755 8.74546 0.972002 8.33072C2.78714 5.06115 5.5991 3.33333 8.50019 3.33331ZM6.02103 8.99998C6.02103 7.63077 7.13099 6.52081 8.50019 6.52081C9.8694 6.52081 10.9794 7.63077 10.9794 8.99998C10.9794 10.3692 9.8694 11.4791 8.50019 11.4791C7.13099 11.4791 6.02103 10.3692 6.02103 8.99998Z'
                        fill='white'
                      />
                    </g>
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>

        {/* Error Message */}
        {responseError && (
          <div className='flex items-center mt-0 text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general'>
            {/* Error Icon (Correct JSX SVG) */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              className='mr-2'>
              <path
                d='M8.00016 11.3333C8.18905 11.3333 8.34738 11.2694 8.47516 11.1416C8.60294 11.0139 8.66683 10.8555 8.66683 10.6666C8.66683 10.4778 8.60294 10.3194 8.47516 10.1916C8.34738 10.0639 8.18905 9.99998 8.00016 9.99998C7.81127 9.99998 7.65294 10.0639 7.52516 10.1916C7.39738 10.3194 7.3335 10.4778 7.3335 10.6666C7.3335 10.8555 7.39738 11.0139 7.52516 11.1416C7.65294 11.2694 7.81127 11.3333 8.00016 11.3333ZM8.00016 8.66665C8.18905 8.66665 8.34738 8.60276 8.47516 8.47498C8.60294 8.3472 8.66683 8.18887 8.66683 7.99998V5.33331C8.66683 5.14442 8.60294 4.98609 8.47516 4.85831C8.34738 4.73054 8.18905 4.66665 8.00016 4.66665C7.81127 4.66665 7.65294 4.73054 7.52516 4.85831C7.39738 4.98609 7.3335 5.14442 7.3335 5.33331V7.99998C7.3335 8.18887 7.39738 8.3472 7.52516 8.47498C7.65294 8.60276 7.81127 8.66665 8.00016 8.66665ZM8.00016 14.6666C7.07794 14.6666 6.21127 14.4916 5.40016 14.1416C4.58905 13.7916 3.8835 13.3166 3.2835 12.7166C2.6835 12.1166 2.2085 11.4111 1.8585 10.6C1.5085 9.78887 1.3335 8.9222 1.3335 7.99998C1.3335 7.07776 1.5085 6.21109 1.8585 5.39998C2.2085 4.58887 2.6835 3.88331 3.2835 3.28331C3.8835 2.68331 4.58905 2.20831 5.40016 1.85831C6.21127 1.50831 7.07794 1.33331 8.00016 1.33331C8.92238 1.33331 9.78905 1.50831 10.6002 1.85831C11.4113 2.20831 12.1168 2.68331 12.7168 3.28331C13.3168 3.88331 13.7918 4.58887 14.1418 5.39998C14.4918 6.21109 14.6668 7.07776 14.6668 7.99998C14.6668 8.9222 14.4918 9.78887 14.1418 10.6C13.7918 11.4111 13.3168 12.1166 12.7168 12.7166C12.1168 13.3166 11.4113 13.7916 10.6002 14.1416C9.78905 14.4916 8.92238 14.6666 8.00016 14.6666Z'
                fill='#FF5A5A'
              />
            </svg>
            {responseError}
          </div>
        )}
        {/* Confirm Password Input Field */}
        <button
          type='submit'
          disabled={!isValidPassword || isSubmitting || error.password || error.confirmPassword || responseError}
          className={`w-full h-11 sm:h-8 text-sm sm:text-[12px] font-[500] mt-5 rounded-lg transition flex justify-center items-center ${isValidPassword && !error.password && !error.confirmPassword && !responseError
            ? "bg-[#A7E92D] text-black"
            : "bg-white/50 text-black cursor-not-allowed"
            } ${isSubmitting ? "cursor-wait" : ""}`}>
          {isSubmitting ? (
            <div className='flex justify-center items-center'>
              <StageSpinner size={30} color='#000' />
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </form>
    </div>
  );
};

export default ForgetPassword;
