import React from 'react'
import logo from "../../assets/logo.jpeg";
import useLoadingStore from '../../store/LoadingStore';

export default function LoadingScreen() {
    const { loading } = useLoadingStore();
  
    if (!loading) return null; 
  
    return (
      <div className="fixed inset-0 flex justify-center items-center blur-2xl z-50">
        <div className="flex flex-col justify-between items-center">
          <img src={logo} alt="Logo" className="w-[68px] h-[68px] rounded-lg" />
        </div>
      </div>
    );
  }