// authFetch.js
import { showToast } from './toast';
import config from '../config'; // Adjust the path according to your folder structure

const authFetch = async (url, options = {}) => {
    try {
        // Get the token from local storage (or another secure place)
        const token = localStorage.getItem('userToken');

        // Check if the request body is FormData (for image uploads)
        const isFormData = options.body instanceof FormData;

        // Prepare headers (only set 'Content-Type' for JSON requests)
        const headers = {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...(isFormData ? {} : { 'Content-Type': 'application/json' }), // Let browser handle multipart/form-data
            ...options.headers,
        };

        // Prepare the full URL
        const fullUrl = `${config.API_BASE_URL}${url}`;

        // Make the request using fetch
        const response = await fetch(fullUrl, {
            method: options.method || 'GET',
            headers,
            body: options.body ? (isFormData ? options.body : JSON.stringify(options.body)) : undefined,
        });

        // Handle unauthorized access (403)
        if (response.status === 403) {
            const res = await response.json();
            localStorage.removeItem('userToken');
            showToast(res?.error, 'error');
            return;
        }

        // Parse JSON response (unless it's an image upload)
        const data = await response.json();

        return data;
    } catch (error) {
        throw error;
    }
};

export default authFetch;
