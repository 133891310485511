import authFetch from "../../utils/authFetch";
import { showToast } from "../../utils/toast";
import { MdOutlineError } from "react-icons/md";
import { StageSpinner } from "react-spinners-kit";
import React, { useState, useEffect } from "react";
import useLoginStore from "../../store/LoginStore";
import useSignUpStore from "../../store/SignupStore";
import usePhoneStore from "../../store/addPhoneStore";
import CheckOnboard from "../../utils/checkOnBoardNew";
import useAadhaarStore from "../../store/addAadhaarStore";
import { useNavigate, useLocation } from "react-router-dom";
import useForgetPasswordStore from "../../store/ForgetPasswordStore";
import useLoadingStore from "../../store/LoadingStore";
import LoadingScreen from "../LoadingScreen/Loading";
import useProfileStore from "../../store/profileStore";
const OTPVerification = () => {
  const location = useLocation();
  let type = location.state?.type;
  let field = location.state?.field
  const { loading, setLoading } = useLoadingStore();
  const [referenceId, setReferenceId] = useState(null);
  const { globalAadhaar, clearGlobalAadhaar } = useAadhaarStore();
  const {
    globalEmail,
    globalNewPassword,
    clearGlobalEmail,
    clearGlobalNewPassword,
  } = useForgetPasswordStore();
  const {
    globalUpdateEmail, globalUpdatePhone, clearGlobalUpdateEmail, clearGlobalUpdatePhone, globalConfirmPassword, clearGlobalConfirmPassword
  } = useProfileStore()
  const {
    globalEmail: globalSignupEmail,
    globalPassword,
    clearGlobalEmail: clearGlobalSignupEmail,
    clearGlobalPassword,
  } = useSignUpStore();
  const {
    globalEmail: globalLoginEmail,
    globalPassword: globalLoginPassword,
    clearGlobalEmail: clearGlobalLoginEmail,
    clearGlobalPassword: clearGlobalLoginPassword,
  } = useLoginStore();
  const {
    globalPhone,
    clearGlobalPhone,
    globalSelectedCountry,
    clearGlobalSelectedCountry,
  } = usePhoneStore();
  const [otp, setOtp] = useState(
    type === "addAadhaar" ? ["", "", "", "", "", ""] : ["", "", "", ""]
  );
  const [email, setEmail] = useState(""); // Example email, change dynamically if needed
  const [error, setError] = useState("")
  const [timer, setTimer] = useState(60); // 60 seconds countdown
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add a state for form submission
  const [showCheckOnboard, setShowCheckOnboard] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setEmail(
      type === "forgetPassword"
        ? globalEmail
        : type === "signin"
          ? globalLoginEmail
          : (type === "updateProfile" && field === "email") ? globalUpdateEmail :
            type === "signup" ? globalSignupEmail : ""
    );
  }, [globalEmail, globalSignupEmail, globalLoginEmail]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  // Function to handle OTP input changes
  const handleChange = (element, index) => {
    if (!isNaN(element.value) && element.value !== "") {
      let newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      // Focus to next input
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };


  // Resend OTP function
  const handleResendOtp = async () => {
    try {
      error && setError("")
      setIsResendDisabled(true);
      setTimer(60);
      let url = "";
      let response = "";
      if (type === "addAadhaar") {
        let body = {
          aadharNumber: globalAadhaar,
        };
        url = `/api/v1/user/kyc/otp`;
        response = await authFetch(url, {
          method: "Post",
          body: body,
        });
      } else if (type === "updateProfile" && field === "email") {
        let body = {
          new_email: globalUpdateEmail,
          password: globalConfirmPassword
        };
        url = `/api/v1/user/sendUpdateEmailOtp`;
        response = await authFetch(url, {
          method: "POST",
          body: body,
        });
      } else if (type === "updateProfile" && field === "phone") {
        let body = {
          new_phone: `+91${globalUpdatePhone}`,
          password: globalConfirmPassword
        };
        url = `/api/v1/user/sendUpdatePhoneOtp`;
        response = await authFetch(url, {
          method: "POST",
          body: body,
        });
      } else {
        url =
          type === "forgetPassword"
            ? `/api/v1/user/forgetOtp?email=${globalEmail}`
            : type === "signin"
              ? `/api/v1/user/loginOtp?email=${globalLoginEmail}`
              : type === "addPhone"
                ? `/api/v1/user/sendAddPhoneOtp?phone=${globalSelectedCountry?.dialCode}${globalPhone}`
                : type === "signup" ? `/api/v1/user/signupOtp?email=${globalSignupEmail}`
                  : null;

        response = await authFetch(url, {
          method: "GET",
        });
      }

      if (response?.code === 200) {
        setReferenceId(response?.data?.id);
      } else {
        setError(response?.error)
      }
    } catch (error) {
      console.log("handleResendOtp:", error);
    }
  };

  // Submit OTP for verification
  const handleSubmitForgetPassword = async () => {
    const otpValue = otp.join("");

    if (otpValue.length === 4) {
      let body = {
        email: globalEmail,
        otp: otpValue,
        newPassword: globalNewPassword,
      };
      const response = await authFetch(`/api/v1/user/changeForgetPassword`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.code === 200) {
        setLoading(true);
        clearGlobalEmail();
        clearGlobalNewPassword();
        navigate("/login");
      } else {
        setOtp(["", "", "", ""]);
        setIsResendDisabled(false);
      }
    } else {
      console.error("Incomplete OTP");
    }
  };
  // Submit OTP for verification
  const handleSubmitForSignup = async () => {
    const otpValue = otp.join("");

    if (otpValue.length === 4) {
      let body = {
        emailId: globalSignupEmail,
        otp: otpValue,
        password: globalPassword,
      };
      const response = await authFetch(`/api/v1/user/signup`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.code === 200) {
        localStorage.setItem("userToken", response.data?.token);
        setShowCheckOnboard(true); // Show CheckOnboard component
      } else {
        setOtp(["", "", "", ""]);
        setError(response?.error)
        setIsResendDisabled(false);
      }
    }
  };

  // Submit OTP for verification
  const handleSubmitUpdateEmail = async () => {
    const otpValue = otp.join("");

    if (otpValue.length === 4) {
      let body = {
        new_email: globalUpdateEmail,
        otp: otpValue
      };
      const response = await authFetch(`/api/v1/user/verifyUpdateEmailOtp`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.code === 200) {
        setLoading(true);
        clearGlobalUpdateEmail();
        clearGlobalConfirmPassword()
        navigate("/profile");
        showToast(response?.data, "white")
      } else {
        setOtp(["", "", "", ""]);
        setError(response?.error)
        setIsResendDisabled(false);
      }
    } else {
      console.error("handleSubmitUpdateEmail", error);
    }
  };
  // Submit OTP for verification
  const handleSubmitUpdatePhone = async () => {
    const otpValue = otp.join("");

    if (otpValue.length === 4) {
      let body = {
        new_phone: `+91${globalUpdatePhone}`,
        otp: otpValue
      };
      const response = await authFetch(`/api/v1/user/verifyUpdatePhoneOtp`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.code === 200) {
        setLoading(true);
        clearGlobalUpdatePhone();
        clearGlobalConfirmPassword()
        navigate("/profile");
        showToast(response?.data, "white")
      } else {
        setOtp(["", "", "", ""]);
        setError(response?.error)
        setIsResendDisabled(false);
      }
    } else {
      console.error("handleSubmitUpdatePhone", error);
    }
  };



  // Submit OTP for verification
  const handleSubmitForLogin = async () => {
    const otpValue = otp.join("");
    if (otpValue.length === 4) {

      let body = {
        emailId: globalLoginEmail,
        otp: otpValue,
        password: globalLoginPassword,
      };
      const response = await authFetch(`/api/v1/user/login`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.code === 200) {
        localStorage.setItem("userToken", response.data);
        setShowCheckOnboard(true); // Show CheckOnboard component
        // Replace history entry to prevent going back to login/OTP
        window.history.pushState(null, "", "/sell");
      } else {
        setOtp(["", "", "", ""]);
        setError(response?.error)
        setIsResendDisabled(false);
      }
    }
  };

  // Submit OTP for verification
  const handleSubmitForAddPhone = async () => {
    const otpValue = otp.join("");

    if (otpValue.length === 4) {
      let body = {
        phone_number: `${globalSelectedCountry?.dialCode}${globalPhone}`,
        otp: otpValue,
      };
      const response = await authFetch(`/api/v1/user/add/phone`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.code === 200) {
        setShowCheckOnboard(true); // Show CheckOnboard component
        clearGlobalPhone();
        clearGlobalSelectedCountry();
      } else {
        setOtp(["", "", "", ""]);
        setIsResendDisabled(false);
      }
    } else {
      showToast("Incomplete OTP", "error");
    }
  };

  // Submit OTP for verification
  const handleSubmitForAadhaar = async () => {
    const otpValue = otp.join("");
    try {
      let body = {
        aadharNumber: globalAadhaar,
        referenceId: referenceId,
        otp: otpValue,
      };

      const response = await authFetch(`/api/v1/user/kyc/verifyOtp`, {
        method: "POST",
        body: body,
      });

      if (response?.code === 200) {
        setShowCheckOnboard(true); // Show CheckOnboard component
        clearGlobalAadhaar();
      } else {
        setOtp(["", "", "", "", "", ""]);
        setError(response?.error)
        setIsResendDisabled(false);
      }
    } catch (err) {
      console.log("handleSubmitForAadhaar", err)
    }
  };

  // Keydown handler for Backspace and Delete keys
  const handleKeyDown = (e, index) => {
    try {
      if ((e.key === "Backspace" || e.key === "Delete") && otp[index] === "") {
        if (e.target.previousSibling) {
          e.target.previousSibling.focus();
        }
      } else if (e.key === "Backspace" || e.key === "Delete") {
        let newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    } catch (error) {
      console.log("handleKeyDown:", error)
    }

  };
  // Function to handle OTP paste event
  const handlePaste = (e, currentIndex) => {
    e.preventDefault(); // Prevent the default paste behavior
    const paste = e.clipboardData.getData("text").trim(); // Get the pasted text
    if (/^\d{1,4}$/.test(paste)) {
      // Ensure only 1 to 4 digits are pasted
      const pasteArray = paste.split(""); // Split pasted value into digits
      let newOtp = [...otp]; // Clone the current OTP array

      // Fill the OTP inputs starting from the current index
      for (let i = 0; i < pasteArray.length; i++) {
        if (currentIndex + i < 4) {
          newOtp[currentIndex + i] = pasteArray[i]; // Fill forward
        }
      }

      setOtp(newOtp); // Update the OTP state
    } else {
      showToast("Invalid OTP format. Please paste up to 4 digits.", "error");
    }
  };
  useEffect(() => {
    const otpLength = type === "addAadhaar" ? 6 : 4;
    if (otp.join("").length === otpLength && !isSubmitting) {
      setIsSubmitting(true)
      setError("")
      setTimeout(() => {
        if (type === "forgetPassword") {
          handleSubmitForgetPassword();
        } else if (type === "signin") {
          handleSubmitForLogin();
        } else if (type === "addPhone") {
          handleSubmitForAddPhone();
        } else if (type === "addAadhaar") {
          handleSubmitForAadhaar();
        } else if (type === "updateProfile" && field === "email") {
          handleSubmitUpdateEmail()
        } else if (type === "updateProfile" && field === "phone") {
          handleSubmitUpdatePhone()
        }
        else if (type === "signup") {
          handleSubmitForSignup();
        }
        setIsSubmitting(false)
      }, 2000)
    }
  }, [otp, type]);

  // Redirect only if type is "login" and either email or password is empty
  useEffect(() => {
    if (type === "signin" && (!globalLoginEmail || !globalLoginPassword)) {
      navigate('/welcome'); // Redirect to base route
    } else if (type === "signup" && (!globalSignupEmail || !globalPassword)) {
      navigate('/welcome'); // Redirect to base route
    }
    else if (type === "addAadhaar" && (!globalAadhaar)) {
      navigate('/welcome'); // Redirect to base route
    }
    else if (type === "updateProfile" && field === "email") {
      if (!globalUpdateEmail || !globalConfirmPassword) {
        navigate("/profile"); // Redirect to profile page
      }
    }
    else if (type === "updateProfile" && field === "phone") {
      if (!globalUpdatePhone || !globalConfirmPassword) {
        navigate("/profile"); // Redirect to profile page
      }
    }
  }, [globalLoginEmail, globalLoginPassword, globalSignupEmail, globalPassword, globalUpdatePhone, globalConfirmPassword, globalUpdateEmail, navigate, type]);

  const handleChangeEmail = (type) => {
    if (type === 'signin' || type === 'signup') navigate('/welcome')
    else if (type === 'addPhone') navigate(-1, { state: { type: 'addPhone' } });
  }
  useEffect(() => {
    if (location.state?.id) {
      setReferenceId(location.state.id); // ✅ Set referenceId when component mounts
    }
  }, [location.state?.id]);
  const getVerificationMessage = (type, globalPhone) => {
    switch (type) {
      case "addPhone":
        return (
          <>
            Enter the 4-digit code sent to your phone <br />
            number {maskPhoneNumber(globalPhone)} to proceed.
          </>
        );
      case "addAadhaar":
        return "Enter the 6-digit code sent to you at your Aadhaar-linked phone number.";
      case "signin":
      case "signup":
        return (
          <>
            We've sent you a 4-digit verification code, <br />
            enter it below to continue.
          </>
        );
      case "updateProfile":
        return (<>

          {
            field === "email" ? (
              <>
                We've sent you a 4-digit verification code, <br />
                enter it below to continue.
              </>
            ) : field === "phone" ? (
              <>
                Enter the 4-digit code sent to your phone <br />
                number {maskPhoneNumber(globalUpdatePhone)} to proceed.
              </>
            ) : null
          }
        </>
        );
      default:
        return "";
    }
  };


  const maskPhoneNumber = (phone) => {
    if (!phone || phone.length < 2) return phone; // Ensure valid input
    return "*".repeat(phone.length - 2) + phone.slice(-2);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [])

  if (loading) return <LoadingScreen />;


  return (
    <div
      className='font-general w-[320px] text-center -mt-10'
      onKeyDown={(e) => handleKeyDown(e)}>
      <h2 className='text-2xl font-[500] leading-[33.6px] mb-2 text-[#fff]'>{type === "addAadhaar" ? 'Aadhaar ' : type === "addPhone" ? "Phone Number " : 'Email '}Verification</h2>
      <p className='font-[500] text-white/40 text-sm mb-5 opacity-80'>
        <span className='block'>
          {getVerificationMessage(type, globalPhone)}
          {(type !== "addAadhaar" && type !== "updateProfile") && <span onClick={() => handleChangeEmail(type)} className="mx-2 border-b-2 border-white/40 hover:text-white hover:border-white cursor-pointer">change</span>}
        </span>
      </p>

      <div className='flex justify-center gap-2 mb-5'>
        {otp.map((data, index) => (
          <input
            key={index}
            inputMode='numeric'
            pattern='[0-9]*'
            type='text'
            className="w-9 h-10 text-center bg-white/10 font-[500] text-white text-2xl border border-transparent rounded-lg focus:border focus:border-[#FFFFFF33] focus:ring-0 outline-none caret-transparent leading-[1.2]"
            maxLength='1'
            value={data}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={(e) => handlePaste(e, index)} // Pass the current index to the onPaste handler
          />
        ))}
      </div>
      {error?.trim() && (
        <div className="flex items-center justify-center mb-2 text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general">
          <MdOutlineError className="flex text-lg " />
          <span className="m-0 text-center whitespace-normal">
            {error}
          </span>
        </div>
      )}
      {
        isSubmitting ? (
          <div className="flex justify-center items-center">
            <StageSpinner size={30} color="#fff" />
          </div>
        ) : (
          <div className='flex flex-col justify-center gap-2 font-[500] text-white/40 text-sm  opacity-80'>
            {isResendDisabled ? (
              <p className="text-[#FFFFFF66]">
                Resend code 00:
                {timer < 10 ? `0${timer}` : timer}
              </p>
            ) : (
              <div className='flex justify-center items-center'>
                <button
                  onClick={handleResendOtp}
                  className='text-white font-[500] text-sm border-b-2 border-white leading-none'
                  disabled={isResendDisabled}>
                  Resend code
                </button>
              </div>
            )}
          </div>
        )
      }
      {showCheckOnboard && <CheckOnboard type={(type === "signin" || type === "signup") && type} />}
    </div>
  );
};

export default OTPVerification;
