import React, { useEffect, useRef, useState } from "react";
import Tether from "../../assets/tether-1.svg";
import authFetch from "../../utils/authFetch";
import UseSellSelectorStore from "../../store/SellSelector";
import { FaDollarSign, FaInfoCircle, FaRupeeSign } from "react-icons/fa";
import CheckOnboard from "../../utils/checkOnBoardNew";
import { StageSpinner } from "react-spinners-kit";
import { MdOutlineError } from "react-icons/md";
const Sell = () => {
  const {
    globalCurrency,
    globalCoin,
    globalNetwork,
    setGlobalCurrency,
    setGlobalCoin,
    setGlobalNetwork,
    globalYouSell,
    globalYouGet,
    setGlobalYouSell,
    setGlobalYouGet,
    globalQuoteData,
    setGlobalQuoteData,
    clearGlobalQuoteData,
    clearGlobalYouGet
  } = UseSellSelectorStore();
  const [focusedInput, setFocusedInput] = useState("sell");
  const [searchTerm, setSearchTerm] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [coins, setCoins] = useState([]);
  const [filteredCurrency, setFilteredCurrency] = useState([]);
  const [filteredCoin, setFilteredCoin] = useState([]);
  const [coinImageLoaded, setCoinImageLoaded] = useState(false);
  const [showCheckOnboard, setShowCheckOnboard] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sellInputRef = useRef(null);
  const debounceTimeout = 1000;
  const maxLimit = 100000;
  const [maxValue, setMaxvalue] = useState(null);
  const [rightOffset, setRightOffset] = useState(18);
  const [quoteError, setQuoteError] = useState(null)

  const preventNegative = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
  };

  const handleButtonClick = (e, amount) => {
    e.preventDefault();
    setGlobalYouSell(amount);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setGlobalYouGet(value);
    }
  };

  const handleYouSellInputChange = (e) => {
    let value = e.target.value;

    // Prevent leading zeros (except if the input is just "0")
    if (value.length > 1 && value.startsWith("0")) {
      return;
    }
    // Prevent numbers larger than 100000
    if (value && Number(value) > maxLimit) {
      setMaxvalue(value);
      return;
    }
    // If value is within the valid range, reset maxValue error
    setMaxvalue(null);
    if (!isNaN(value) && value >= 0) {
      setGlobalYouSell(value);
    }
  };

  const getAllCurrencies = async () => {
    try {
      const response = await authFetch("/api/v1/user/getAllCurrencies", {
        method: "GET",
      });
      if (!response?.code === 200) return;
      const currencyData = response?.data;
      setCurrencies(currencyData);
      setGlobalCurrency(currencyData[0]);
    } catch (error) {
      console.error("Error fetching getAllCurrencies:", error);
    }
  };

  const getAllCoins = async () => {
    try {
      const response = await authFetch("/api/v1/user/getAllCoins", {
        method: "GET",
      });
      if (!response?.code === 200) return;
      const coinData = response?.data;
      setCoins(coinData);
      setGlobalCoin(coinData[0]);
      if (!globalNetwork) getAllNetworks(coinData[0]);
    } catch (error) {
      console.error("Error fetching getAllCoins:", error);
    }
  };

  const getAllNetworks = async (coinData) => {
    try {
      const response = await authFetch(
        `/api/v1/user/getAllNetworks?id=${coinData?.coinid}`,
        { method: "GET" }
      );
      if (!response?.code === 200) return;
      const networkData = response?.data;
      const defaultNetwork = networkData.find((n) => n.chainSymbol === "trc20");
      setGlobalNetwork(defaultNetwork);
    } catch (error) {
      console.error("Error fetching getAllNetworks:", error);
    }
  };

  const getQuote = async () => {
    try {
      let body = {
        fromCurrency: globalCoin.coin.toUpperCase(),
        toCurrency: globalCurrency.fiatSymbol,
        fromAmount: globalYouSell,
        chain: globalNetwork.chainSymbol,
      };
      const response = await authFetch(`/api/v1/offramp/getQuotes`, {
        method: "POST",
        body: body,
        headers: { "Content-Type": "application/json" },
      });
      if (response?.code !== 200) {
        console.log(response?.error)
        setQuoteError(response?.error)
        return;
      };
      const quoteData = response?.data;
      setGlobalQuoteData(quoteData);
      setGlobalYouGet(quoteData?.toAmount);
      setQuoteError(null)
    } catch (error) {
      console.error("Error fetching getQuote:", error);
    }
  };
  const preventLeadingZero = (e) => {
    let value = e.target.value;

    // Remove leading zeros
    value = value.replace(/^0+/, "");

    // Set the cleaned value
    e.target.value = value;
  };
  const resetValidation = () => {
    setMaxvalue(null);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (globalYouSell !== "" && globalYouSell >= Number(globalCoin?.minSellValue) && globalYouSell <= globalCoin?.maxSellValue) {
        getQuote();
      } else {
        setGlobalQuoteData(null);
        setGlobalYouGet("");
        globalYouSell === ""
          ? setQuoteError(null)
          : globalYouSell < Number(globalCoin?.minSellValue)
            ? setQuoteError(`Amount should be at least $${globalCoin?.minSellValue}`)
            : globalYouSell > Number(globalCoin?.maxSellValue)
              ? setQuoteError(`Amount should not exceed $${globalCoin?.maxSellValue}`)
              : setQuoteError(null);

      }
    }, debounceTimeout);

    // Cleanup function to clear timeout if effect dependencies change
    return () => clearTimeout(debounceTimer);
  }, [globalYouSell, globalNetwork, globalCurrency]);

  useEffect(() => {
    sellInputRef.current.focus();
    const fetchInitialData = async () => {
      await getAllCurrencies();
      await getAllCoins();
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    let filteredFiat = currencies.filter(
      (currency) =>
        currency?.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
        currency?.fiatSymbol.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCurrency(filteredFiat);

    let filteredCrypto = coins.filter(
      (coin) =>
        coin.coinName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        coin.coin.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCoin(filteredCrypto);
  }, [searchTerm, currencies, coins]);

  const handleProceed = () => {
    if (
      !(
        globalYouSell < Number(globalCoin?.minSellValue) ||
        !globalQuoteData ||
        globalYouGet === ""
      )
    ) {
      setIsSubmitting(true);
      setTimeout(() => {
        setIsSubmitting(false); // Stop loading
        setShowCheckOnboard(true);
      }, 1500);
    }
  };
  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth < 400) {
        setRightOffset(16);
      } else if (window.innerWidth >= 400 && window.innerWidth < 480) {
        setRightOffset(17);
      } else if (window.innerWidth >= 480 && window.innerWidth < 768) {
        setRightOffset(18);
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setRightOffset(20);
      } else {
        setRightOffset(21);
      }
    };

    updateOffset(); // Initial check
    window.addEventListener("resize", updateOffset);

    return () => window.removeEventListener("resize", updateOffset);
  }, []);
  return (
    <div className='flex flex-col  gap-8 w-full max-w-full items-center'>
      <div className='flex flex-col items-center gap-3 w-full max-w-full'>
        <div className='py-5 px-6 w-full max-w-full md:w-[80%] lg:w-[545px] rounded-[20px] h-40 bg-[#A7E92D] shadow-lg'>
          <div className='flex flex-col h-28 w-full gap-6'>
            <h3 className='font-general font-medium text-[#111111] md:text-base'>
              You Sell
            </h3>
            <div className='flex flex-col w-full'>
              <div className='flex w-full justify-between items-center h-12'>
                <div className='flex items-center gap-3 '>
                  {!coinImageLoaded && (
                    <div className=' rounded-full  animate-pulse'></div>
                  )}
                  <img
                    src={Tether}
                    alt='Coin Icon'
                    className={`w-[42px] h-[42px] object-cover ${!Tether ? "hidden" : ""
                      }`}
                    onLoad={() => setCoinImageLoaded(true)}
                    onError={() => setCoinImageLoaded(false)}
                  />
                  <span className='uppercase font-figtree flex gap-2 font-semibold text-[#111111] text-[28px] '>
                    {globalCoin?.coin ? (
                      globalCoin?.coin
                    ) : (
                      <span className='animate-pulse  inline-block rounded'></span>
                    )}
                  </span>
                </div>
                <div className='relative flex w-full max-w-[150px] md:max-w-[200px] lg:max-w-[250px]'>
                  <FaDollarSign
                    className='absolute text-[#11111180] text-xl transition-all duration-300'
                    style={{
                      right: `${10 + (globalYouSell?.toString().length || 0) * 20
                        }px`,
                    }}
                  />
                  <input
                    ref={sellInputRef}
                    type='number'
                    placeholder='0'
                    className='h-full bg-transparent text-black text-4xl font-semibold appearance-none outline-none  text-right  w-full '
                    onFocus={() => setFocusedInput("sell")}
                    onBlur={() => {
                      setFocusedInput("");
                      resetValidation();
                    }}
                    onInput={(e) => {
                      preventLeadingZero(e);
                      preventNegative(e);
                    }}
                    onKeyDown={(e) => {
                      if ([".", "e", "E", "+", "-"].includes(e.key)) {
                        e.preventDefault(); // Prevent typing ".", "e", "E", "+", and "-"
                      }
                    }}
                    value={globalYouSell}
                    onChange={handleYouSellInputChange}
                    max={maxLimit} // Ensures that browser also enforces the max limit
                  />
                </div>
              </div>
              <div className="flex justify-between items-center w-full">
                {/* Left Side: Tether(...) */}
                <span className='text-[#11111180] text-sm font-medium'>
                  Tether(
                  {globalNetwork?.chainSymbol ? (
                    globalNetwork?.chainSymbol?.toUpperCase()
                  ) : (
                    <span className='animate-pulse inline-block rounded'></span>
                  )}
                  )
                </span>

                {/* Right Side: Error Message */}
                {quoteError && (
                  <div className="flex items-center gap-2 text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general">
                    <MdOutlineError className="text-lg" />
                    <span className="text-left whitespace-normal">
                      {quoteError}
                    </span>
                  </div>
                )}
              </div>


            </div>
          </div>
        </div>
        <div className='py-5 px-6 w-full max-w-full md:w-[80%] lg:w-[545px] rounded-[20px] h-40 bg-[#F4F4F41F] shadow-lg'>
          <div className='flex flex-col h-28 w-full gap-6'>
            <h3 className='font-general font-medium text-[#F4F4F4]'>
              You Recieved
            </h3>
            <div className='flex flex-col w-full'>
              <div className='flex w-full justify-between items-center h-12'>
                <div className='flex items-center gap-3 shrink-0'>
                  {!globalCurrency?.flagUrl || !coinImageLoaded ? (
                    <div className='w-[42px] h-[42px] rounded-full bg-gray-600 animate-pulse'></div>
                  ) : null}
                  <img
                    src={globalCurrency?.flagUrl}
                    alt=''
                    className={`w-[42px] h-[42px] object-cover rounded-full overflow-hidden ${(!globalCurrency?.flagUrl || !coinImageLoaded) ? "hidden" : ""
                      }`}
                    onLoad={() => setCoinImageLoaded(true)}
                    onError={() => setCoinImageLoaded(false)}
                  />
                  <span className='uppercase font-figtree flex gap-2 font-semibold text-[#F4F4F4] text-[28px] '>
                    {globalCurrency?.fiatSymbol ? (
                      globalCurrency?.fiatSymbol
                    ) : (
                      <span className=" bg-gray-600 animate-pulse rounded"></span>
                    )}
                  </span>
                </div>
                <div className='relative flex w-full max-w-[180px] md:max-w-[200px] lg:max-w-[250px]'>
                  <FaRupeeSign
                    className='mt-2 absolute text-[#F4F4F480] text-xl transition-all duration-300'
                    style={{
                      right: `${15 +
                        (globalYouGet?.toString().length || 0) * rightOffset
                        }px`,
                    }}
                  />
                  <div>
                    <input
                      type='number'
                      value={globalYouGet}
                      placeholder={
                        globalCurrency?.currencySymbol &&
                          globalCurrency?.minSellValue
                          ? `0`
                          : ""
                      }
                      className='h-full bg-transparent text-[#F4F4F4]  text-4xl font-semibold appearance-none outline-none  text-right  w-full'
                      onFocus={() => setFocusedInput("get")}
                      onBlur={() => setFocusedInput("")}
                      onInput={preventNegative}
                      onChange={handleInputChange}
                      disabled // 👈 This disables the input
                    />
                  </div>
                </div>
                {/*<div className="relative flex items-center  w-full max-w-[250px]">
  <FaRupeeSign className="text-[#F4F4F480] text-2xl" />
  <input
    type="number"
    value={globalYouGet}
    className="bg-transparent text-[#F4F4F4] text-4xl font-semibold text-right w-full appearance-none outline-none "
    onFocus={() => setFocusedInput("get")}
    onBlur={() => setFocusedInput("")}
    onInput={preventNegative}
    onChange={handleInputChange}
  />
</div>*/}
              </div>
              <span className='flex text-[#F4F4F480] items-center text-sm font-medium text-nowrap'>
                Indian Rupees (<FaRupeeSign className='' />)
              </span>
            </div>
          </div>
        </div>
        {globalQuoteData && (
          <div className='flex items-center gap-2 text-[#F4F4F480]  text-base font-medium'>
            <FaInfoCircle className='' /> 1 USDT = ₹ {globalQuoteData?.rate}
          </div>
        )}
      </div>
      <button
        disabled={
          globalYouSell < Number(globalCoin?.minSellValue) ||
          !globalQuoteData ||
          globalYouGet === ""
        }
        onClick={() => handleProceed()}
        className={`text-center mt-3 py-3 w-full max-w-full md:w-[80%] lg:w-[545px] h-12 text-black text-sm md:text-base font-general font-medium rounded-3xl  shadow-[0px_20px_40px_0px_rgba(0,0,0,0.25)] 
                    ${globalYouSell < Number(globalCoin?.minSellValue) ||
            !globalQuoteData ||
            globalYouGet === ""
            ? "bg-[#F4F4F480]"
            : "bg-[#A9EA2E]"
          }`}>
        {isSubmitting ? (
          <div className='flex justify-center items-center'>
            <StageSpinner size={30} color='#000' />
          </div>
        ) : (
          "Continue"
        )}
      </button>
      {showCheckOnboard && <CheckOnboard type={"sell"} />}
    </div>
  );
};

export default Sell;
