import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StageSpinner } from "react-spinners-kit";
import useAadhaarStore from "../../store/addAadhaarStore";
import authFetch from "../../utils/authFetch";
import {MdOutlineError} from "react-icons/md";
import useLoadingStore from "../../store/LoadingStore";
import LoadingScreen from "../LoadingScreen/Loading";

const AddAadhaar = () => {
  const navigate = useNavigate();
  const { globalAadhaar, setGlobalAadhaar, clearGlobalAadhaar } =
    useAadhaarStore();
  const [maskedAadhaar,setMaskedAadhaar]=useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState("")
  const { loading, setLoading } = useLoadingStore(); 
  // Check form validation whenever inputs change
  const checkFormValidation = (value) => {
    setError("")
    if (value.length === 12 && /^\d{12}$/.test(value)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };
  
  const handleAddAadhaarOTP = async (e) => {
    e.preventDefault();
    const aadhaarNumber = maskedAadhaar.replace(/-/g, '');
    setGlobalAadhaar(aadhaarNumber);
    let body = {
      aadharNumber: aadhaarNumber,
    };
    setIsSubmitting(true);
    try {
      const response = await authFetch(`/api/v1/user/kyc/otp`, {
        method: "POST",
        body: body,
      });
      if (response.code === 200) {
        onSuccess(response.data.id);
      } else {
        setError(response?.error)
      }
    } catch (error) {
      console.error("handleAddAadhaarOTP", error);
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };
  const onSuccess = async (id) => {
    try {
      setLoading(true); 
      navigate("/otp", { state: { type: "addAadhaar", id } }); // Navigate to OTP page
    } catch (err) {
      console.error("onSuccess", err);
    }
  };
  useEffect(() => {
    clearGlobalAadhaar();
  }, []);
  useEffect(()=>{
    setTimeout(() => {
      setLoading(false); 
    }, 500);
  },[])
  
  if (loading) return <LoadingScreen />; 

  return (
    <div className='flex flex-col justify-between font-general w-full sm:w-[320px] h-auto text-center gap-6 '>
      <span className='flex flex-col gap-1'>
      <h2 className='text-2xl font-[500] leading-[33.6px]  text-[#fff]'>Verify Your Aadhaar</h2>
      <p className='font-[500] text-white/40 text-sm opacity-80'>
        We will verify it using an OTP sent to your registered mobile number
      </p>
      </span>
      <form onSubmit={handleAddAadhaarOTP} className="flex flex-col gap-4" >
      <input
        type='text'
        id='number'
        value={maskedAadhaar}
        onChange={(e) => {
          let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
          if (value.length > 12) return;
          value = value
          .replace(/(\d{4})(\d{0,4})?(\d{0,4})?/, (_, p1, p2, p3) =>
            [p1, p2, p3].filter(Boolean).join("-")
          );
            setMaskedAadhaar(value);
            checkFormValidation(value.replace(/-/g, ''));
        }}
        onKeyDown={(e) => {
          if (["e", "E", ".", "+", "-"].includes(e.key)) {
            e.preventDefault(); 
          }
        }}
        className='w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-xs border border-transparent rounded-lg bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow'
        placeholder="Enter Aadhaar Number"
      />
      {/* Error Message */}
      {error && (
        <div className='flex items-center text-[#FF5A5A] gap-2 text-[12px] -mt-2 font-medium leading-[16.8px] font-general'>
          <MdOutlineError className="text-lg" />
          <span className="text-left whitespace-normal">
          {error}
          </span>
        </div>
      )}
      <button
      type="submit"
        className={`w-full h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center ${isFormValid
          ? "bg-[#A7E92D] text-black"
          : "bg-white/50 text-black cursor-not-allowed"
          }`}
        disabled={!isFormValid || isSubmitting}
      >
        {isSubmitting ? <StageSpinner size={40} color="#000" loading={isSubmitting} /> : 'Verify'}
      </button>
      </form>
    </div>
  );
};

export default AddAadhaar;
