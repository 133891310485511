import React, { useEffect, useRef } from 'react';
import logo from "../../assets/logo.jpeg";
import { useNavigate } from 'react-router-dom';
import useTransactionStatusStore from '../../store/txStatusStore';
import useWalletAddressStore from '../../store/WalletAddressStore';
import authFetch from '../../utils/authFetch';
const FundsOnWay = () => {
  const { setStatus } = useTransactionStatusStore()
  const { orderCreatedResp } = useWalletAddressStore()
  const navigate = useNavigate();
  const timeoutRef = useRef(null); // Persist timeout ID across renders

  useEffect(() => {
    getTxStatus()
    timeoutRef.current = setTimeout(() => {
      navigate('/transaction-complete');
    }, 3000);

    return () => clearTimeout(timeoutRef.current); // Cleanup on component unmount
  }, [navigate]);

  const handleCheckSellingHistory = () => {
    clearTimeout(timeoutRef.current); // Prevent unwanted navigation
    navigate('/selling-history');
  };
  const getTxStatus = async () => {
    try {
      let body = {
        reference_id: orderCreatedResp?.reference_id
      };

      const response = await authFetch(`/api/v1/offramp/transactionStatus`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response?.code === 200) {
        const responseData = response?.data
        setStatus(responseData?.status)
      }
    } catch (error) {
      console.error('getTxStatus:', error);
    }
  }
  return (
    <div className='flex flex-col w-80'>
      <div className="fixed inset-0 flex justify-center top-60 blur-2xl z-50">
        <div className="flex flex-col justify-between items-center">
          <img src={logo} alt="Logo" className="w-[68px] h-[68px] rounded-lg" />
        </div>
      </div>
      <div className='flex flex-col text-center justify-center gap-6 w-full'>
        <div className='flex flex-col items-center gap-2 w-full'>
          <h2 className='text-lg text-[#F4F4F4] font-medium font-general'>
            Funds Are on Their Way!
          </h2>
          <p className='font-medium font-general text-[#F4F4F466] text-sm'>
            Your funds are being processed and may take some time to reflect in your account.
          </p>
        </div>
        <button
          onClick={handleCheckSellingHistory}
          className='flex items-center justify-center gap-2 text-center py-[10px] bg-[#A9EA2E] px-2 h-[33px] text-black font-medium text-xs font-general rounded-lg shadow-[0px_20px_40px_0px_rgba(0,0,0,0.25)]'>
          Check Selling History
        </button>
      </div>
    </div>
  );
};

export default FundsOnWay;

