import React from 'react'
import ActiveSection from '../ActiveSection/ActiveSection';
import { MdAccountBalance } from 'react-icons/md';
import { FaGlobe } from 'react-icons/fa';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { PiHandCoinsFill } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import UseSellSelectorStore from "../../store/SellSelector";
import authFetch from '../../utils/authFetch';
import { StageSpinner } from "react-spinners-kit";
import useWalletAddressStore from '../../store/WalletAddressStore';
import { MdOutlineError } from "react-icons/md";
const ReviewConfirm = () => {
  const {
    globalSelectedBank,
    globalCurrency,
    globalCoin,
    globalYouSell,
    globalYouGet,
    globalQuoteData,
    globalNetwork,
    clearAll
  } = UseSellSelectorStore();
  const { setWalletAddress, setOrderCreatedResp, userWalletAddress, clearAll: clearAllWallet } = useWalletAddressStore();
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const createOfframpTransaction = async () => {
    try {
      setIsSubmitting(true);
      let body = {
        // userWalletAddress: userWalletAddress,
        fromCurrency: globalCoin?.coin.toUpperCase(),
        toCurrency: globalCurrency?.fiatSymbol,
        chain: globalNetwork.chainSymbol,
        fromAmount: globalYouSell,
        toAmount: globalYouGet,
        fiatAccountId: globalSelectedBank?.fiatAccountId, // Updated to use globalSelectedBank
        rate: globalQuoteData?.rate
      };

      const response = await authFetch(`/api/v1/offramp/createOfframpTransaction`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response?.code === 200) {
        const orderCreatedResp = response?.data;
        orderCreatedResp.amount = globalYouSell;
        setWalletAddress(orderCreatedResp?.wallet);
        setOrderCreatedResp(orderCreatedResp);
        navigate('/deposit');
      }
      else {
        setError(response?.error || "Something went wrong while creating the transaction! Please try again.");
        // setTimeout(() => {
        //   navigate('/sell');
        //   clearAll();
        //   clearAllWallet()
        // }, 3000);

      }
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error fetching handleSelectedBankProceed:', error);
    }
  };
  function maskBankAccount(accountNumber) {
    if (!accountNumber || accountNumber.length < 4) {
      return "Invalid Account";
    }
    return "********" + accountNumber.slice(-4);
  }
  const handleChangeDetails = () => {
    clearAll(); // Clear all global state
    navigate('/sell');  // Navigate to the sell page
  };
  return (
    <div className='flex flex-col gap-6 w-[350px] '>
      <ActiveSection type={"reviewconfirm"} />
      <div className="flex flex-col text-center justify-center gap-6">
        <div className='flex flex-col gap-2'>
          <h2 className="text-lg text-[#F4F4F4] font-medium font-general">Review & Confirm Your Details</h2>
          <p className="text-gray-400 text-sm ">
            Ensure all details are correct before proceeding.
          </p>
        </div>
        <div className="flex flex-col text-left gap-2 justify-between w-full">
          <div className="flex flex-col sm:flex-row justify-between gap-2">
            <div className="flex items-center gap-1 text-nowrap">
              <FaGlobe className="text-[#A9EA2E]" />
              <p className="text-[#F4F4F4CC] font-general font-medium text-sm">Blockchain Network</p>
            </div>
            <div className="font-general uppercase font-medium bg-[#F4F4F40A] w-full sm:w-[158px] px-4 py-2 rounded-lg text-[#A9EA2E] text-xs">
              {globalNetwork?.chainSymbol ? globalNetwork?.chainSymbol : 'TRC20'}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between gap-2">
            <div className="flex items-center gap-1 text-nowrap">
              <BiSolidDollarCircle className="text-[#A9EA2E]" />
              <p className="text-[#F4F4F4CC] font-general font-medium text-sm">Selling Amount (USDT)</p>
            </div>
            <div className="font-general font-medium uppercase bg-[#F4F4F40A] w-full sm:w-[158px] px-4 py-2 rounded-lg text-[#A9EA2E] text-xs">
              {globalCoin?.coin} {globalYouSell ? globalYouSell : '00'}
            </div>

          </div>
          <div className="flex flex-col sm:flex-row justify-between gap-2">
            <div className="flex items-center gap-1 text-nowrap">
              <PiHandCoinsFill className="text-[#A9EA2E]" />
              <p className="text-[#F4F4F4CC] font-general font-medium text-sm">You Will Receive (INR)</p>
            </div>
            <div className="font-general uppercase font-medium bg-[#F4F4F40A] w-full sm:w-[158px] px-4 py-2 rounded-lg text-[#A9EA2E] text-xs">
              ₹ {Number(globalYouGet)?.toFixed(0)} {globalCurrency?.fiatSymbol}
            </div>

          </div>
          <div className="flex flex-col sm:flex-row justify-between gap-2clea">
            <div className="flex items-center gap-1 text-nowrap">
              <MdAccountBalance className="text-[#A9EA2E]" />
              <p className="text-[#F4F4F4CC] font-general font-medium text-sm">Receiving Bank Account</p>
            </div>
            <div className=" text-nowrap font-general font-medium bg-[#F4F4F40A] w-full sm:w-[158px] px-4 py-2 rounded-lg text-[#A9EA2E] text-xs">
              {globalSelectedBank?.shortName} ({maskBankAccount(globalSelectedBank?.fiatAccount)})
            </div>

          </div>
        </div>
        {/* Error Message */}
        {error && (
          <div className="flex items-center gap-2 -mt-2 text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general">
            {/* Error Icon */}
            <MdOutlineError className="text-lg" />
            {/* Error Message */}
            <span className="text-left whitespace-normal">
              {error}
            </span>
          </div>
        )}


        <div className='flex flex-col gap-3 items-center '>
          <button
            disabled={isSubmitting}
            onClick={() => createOfframpTransaction()} className={`text-center  w-80 ${"bg-[#A9EA2E]"} px-2  h-[33px] text-black font-medium text-xs font-general  rounded-lg  shadow-[0px_20px_40px_0px_rgba(0,0,0,0.25)] `}>
            {isSubmitting ? (
              <div className="flex justify-center items-center">
                <StageSpinner size={30} color="#000" />
              </div>
            ) : (
              "Confirm & Proceed"
            )}
          </button>
          <button onClick={() => { handleChangeDetails(); clearAll(); clearAllWallet(); }} className="text-sm font-medium font-general text-gray-400 underline">
            Change Details
          </button>

        </div>
      </div>
    </div>
  )
}

export default ReviewConfirm;