import React, { useEffect, useState } from 'react'
import { BiSolidDollarCircle } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import useWalletAddressStore from '../../store/WalletAddressStore'
import pendingGif from "../../assets/pending.gif"
import authFetch from '../../utils/authFetch'
import useTransactionStatusStore from '../../store/txStatusStore'
const USDTRecieved = () => {
  const { txStatus, status, setTxStatus, setStatus } = useTransactionStatusStore()
  const { orderCreatedResp } = useWalletAddressStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (!orderCreatedResp) {
      navigate('/selling-history')
      return;
    }
    if (txStatus === "SUCCESS") {
      setTimeout(() => navigate('/funds-on-way'), 3000);
    } else {
      pollTransactionStatus();
    }
  }, [])
  // Poll for transaction status every 5s for up to 1 min (12 attempts)
  const pollTransactionStatus = () => {
    let attempts = 0;
    const maxAttempts = 24;

    const fetchStatus = async () => {
      try {
        let body = {
          reference_id: orderCreatedResp?.reference_id
        };
        const response = await authFetch('/api/v1/offramp/transactionStatus', {
          method: 'POST',
          body: body,
          headers: { 'Content-Type': 'application/json' },
        });

        if (response?.code === 200) {
          const responseData = response?.data
          const newStatus = responseData?.txStatus;
          setTxStatus(newStatus);
          setStatus(responseData?.status)
          if (newStatus === "SUCCESS") {
            setTimeout(() => {
              navigate('/funds-on-way');
            }, 3000); // ⏳ Wait 3 seconds before navigating
            return;
          }

          if (newStatus === "PENDING" && attempts < maxAttempts) {
            attempts++;
            setTimeout(fetchStatus, 5000);
          } else {
            navigate("/selling-history");
          }
        } else {
          navigate("/selling-history");
        }
      } catch (error) {
        console.error('Error fetching transaction status:', error);
        navigate("/selling-history");
      }
    };

    fetchStatus();
  };



  return (
    <div className="flex justify-center items-center h-screen">
      <div className="font-general w-[320px] h-[138px] flex flex-col justify-center items-center text-center -mt-10">
        <div className="flex justify-center mb-5 relative">
          <div className="absolute w-24 h-24 bg-[#A7E92D] opacity-50 rounded-full blur-2xl animate-ping"></div>
          {
            txStatus === "SUCCESS" ? (
              <BiSolidDollarCircle className="text-6xl text-[#A7E92D] relative drop-shadow-[0_0_15px_#2AE920]" />
            ) : (
              <img
                src={pendingGif}
                alt="Processing..."
                className="w-16 h-16 object-contain"
              />
            )
          }

        </div>
        <div className="flex flex-col items-center w-[320px]">
          <h2 className="text-2xl font-[500] leading-[33.6px] mb-2 text-[#fff]">
            {txStatus === "SUCCESS" ? (
              `${orderCreatedResp?.amount} USDT Received!`
            ) : (
              "USDT Deposit is in Progress"
            )}
          </h2>

          <p className="font-[500] text-[#FFFFFF66] text-sm mb-4 opacity-80">
            {txStatus === "SUCCESS" ? (
              `Our wallet has been credited with ${orderCreatedResp?.amount} USDT securely.`
            ) : (
              <>
                Your deposit of {orderCreatedResp?.amount} USDT is being processed. <br />
                This may take a few minutes.
              </>
            )}
          </p>
          {
            txStatus === "PENDING" && (
              <p
                onClick={() => navigate("/selling-history")}
                className="font-[500] text-[#A9EA2E] text-sm text-center underline cursor-pointer"
              >
                Check Selling History
              </p>
            )
          }
        </div>
      </div>
    </div>

  )
}

export default USDTRecieved