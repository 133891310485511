import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
const Confirmation = () => {
  const location = useLocation();
  const type = location.state?.type;
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      type === "selectbank" ? navigate("/fiataccount") : navigate("/sell");

    }, 3000);
  }, []);
  return (
    <div className='flex flex-col justify-center items-center font-general text-center'>
      <div className='flex justify-center mb-5 relative'>
        <div className='absolute w-24 h-24 bg-[#A7E92D] opacity-50 rounded-full blur-2xl animate-ping'></div>
        <FaCheckCircle className='text-6xl text-[#A7E92D] relative drop-shadow-[0_0_15px_#2AE920]' />
      </div>
      <h2 className='text-2xl font-[500] leading-[33.6px] mb-2 text-[#fff]'>
        Your Bank Account is Verified!
      </h2>
      <p className='font-[500] text-[#FFFFFF66] text-sm mb-6 opacity-80'>
        Your account details have been <br /> successfully verified, and you're all set <br />
        to proceed.
      </p>
    </div>
  );
};

export default Confirmation;
