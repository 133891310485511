import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineError } from "react-icons/md";
const ErrorConfirm = ({ type }) => {
  const location = useLocation();
  const responseError = location?.state?.responseError;
  const navigate = useNavigate();
  const tryAgain = () => {
    navigate(type === "signin" ? "/existinguser-login" : "/user-registration", { state: { type: "addBank" } });
  };
  return (
    <div className='flex flex-col gap-4 font-general w-full sm:w-[320px] h-auto text-center -mt-10'>
      <h2 className='text-2xl font-medium whitespace-nowrap text-[#fff]'>
        Oops! Something Went Wrong
      </h2>
      <div className="flex justify-center items-center text-[#FF5A5A] sm:text-[12px] font-medium leading-[16.8px]">
        <MdOutlineError className="text-lg mr-1" />
        <span className="text-center whitespace-normal">
          {responseError}
        </span>
      </div>
      <button
        onClick={() => tryAgain()}
        className={`w-full py-2 px-3 h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center bg-[#A7E92D] text-black`}>
        Try Again
      </button>
    </div>
  );
};

export default ErrorConfirm;
