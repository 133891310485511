import React, { useEffect, useState, useRef } from "react";
import { FaChevronLeft } from "react-icons/fa";
import useProfileStore from "../../store/profileStore";
import DeleteAccountModal from "../../Modal/ConfirmDeleteModal";
import { StageSpinner } from "react-spinners-kit";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { LuUpload } from "react-icons/lu";
import authFetch from "../../utils/authFetch";
import { showToast } from "../../utils/toast";
import ConfirmPasswordModal from "../../Modal/ConfirmPasswordModal";
import LoadingScreen from "../LoadingScreen/Loading";

const Profile = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { globalUpdateEmail, setGlobalUpdateEmail, globalUpdatePhone, setGlobalUpdatePhone } = useProfileStore()
  const emailInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const debounceTimeout = useRef(null);
  const navigate = useNavigate()
  const { setProfile, globalProfile } = useProfileStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [showUserNameInput, setShowUserNameInput] = useState(false)
  const [emailInputEnabled, setEmailInputEnabled] = useState(false)
  const [phoneInputEnabled, setPhoneInputEnabled] = useState(false)
  const [username, setUsername] = useState("")
  const [loadingUsernameCheck, setLoadingUsernameCheck] = useState(false); // Add loading state
  const [isUsernameAvailable, setIsUserNameAvailable] = useState(null)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [deleteAccountError, setDeleteAccountError] = useState(false)
  const [error, setError] = useState({
    password: "",
    email: "",
    phone: "",
    username: ""
  });

  const handleImageUpload = async (event) => {
    try {
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }
      const file = event.target.files[0];

      const reader = new FileReader();
      // reader.onloadend = () => {
      //   setUploadedImage(reader.result);
      //   setImage(reader.result);
      // };
      reader.readAsDataURL(file);
      // ✅ Prepare FormData for API request
      const formData = new FormData();
      formData.append("profilePicture", file);


      const response = await authFetch("/api/v1/user/uploadProfile", {
        method: "POST",
        body: formData,
      });

      if (response?.code === 200) {
        showToast("Profile Picture uploaded!", "white")
        setProfile()
      } else {
        showToast("Profile Picture upload failed!", "error")
      }
      event.target.value = "";
    } catch (error) {
      console.error("uploadProfilePicture:", error);
      setError((prevError) => ({
        ...prevError,
        profilePicture: "Something went wrong. Please try again.",
      }));
    }
    // ✅ FIX: Reset input so the same file can be selected again

  };

  useEffect(() => {
    if (!globalProfile) {
      setProfile()
    }
  }, [])

  useEffect(() => {
    if (emailInputEnabled && emailInputRef.current) {
      emailInputRef.current.focus(); // Auto-focus input when enabled
    }
  }, [emailInputEnabled]); // Runs when emailInputEnabled changes

  const validateEmail = (email) => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Email regex validation
    if (!isValidEmail) {
      setError({
        email: "Not Valid"
      })
    } else {
      setError({
        email: ""
      })
    }
  }
  const validateIndianPhone = (phone) => {
    const isValidPhone = /^[6789]\d{9}$/.test(phone); // Indian phone number regex
    if (!isValidPhone) {
      setError({
        phone: "Not Valid"
      });
    } else {
      setError({
        phone: ""
      });
    }
  };

  const checkUsername = async () => {
    try {
      if (!username || username.length < 4) {
        setIsUserNameAvailable(null); // Reset when input is too short
        return;
      }
      setLoadingUsernameCheck(true)
      const response = await authFetch(
        `/api/v1/user/checkUsername?username=${username}`,
        {
          method: "GET", // Assuming it's a GET request
        }
      );
      if (response?.code === 200) {
        setIsUserNameAvailable(!response?.data?.existing_user)
      } else {
        setError((prevError) => ({
          ...prevError, // Preserve existing errors
          username: response?.error
        }));
      }
      setLoadingUsernameCheck(false); // Stop loading once the request completes
    } catch (error) {
      console.log("checkUsername:", error)
    }

  };
  const updateUsername = async () => {
    try {
      if (!username || username.length < 4) {
        setIsUserNameAvailable(null); // Reset when input is too short
        return;
      }
      if (!isUsernameAvailable) {
        setError((prevError) => ({
          ...prevError, // Preserve existing errors
          username: "Already Taken"
        }));
      }
      setIsSubmitting(true)
      const response = await authFetch(
        `/api/v1/user/updateUsername?username=${username}`,
        {
          method: "GET", // Assuming it's a GET request
        }
      );
      if (response?.code === 200) {
        showToast("Successfully Updated!", "white")
        setShowUserNameInput(false)
        setProfile()
        setUsername("")
        setIsUserNameAvailable(null)
      } else {
        setError((prevError) => ({
          ...prevError, // Preserve existing errors
          username: response?.error
        }));
      }
      setIsSubmitting(false)
    } catch (error) {
      console.log("updateUsername:", error)
    }
  };
  useEffect(() => {
    if (!username || username.length < 4) {
      setIsUserNameAvailable(null); // Reset when input is too short
      setError((prevError) => ({ ...prevError, username: null })); // No error before 4 chars
      return;
    }

    const usernameRegex = /^[a-zA-Z0-9@#_.-]{4,25}$/;


    if (!usernameRegex.test(username)) {
      setIsUserNameAvailable(null);
      setError((prevError) => ({ ...prevError, username: "Invalid" }));
      return;
    }

    // If valid, clear errors
    setError((prevError) => ({ ...prevError, username: null }));

    // Clear previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set new timeout to check availability
    debounceTimeout.current = setTimeout(() => {
      checkUsername(); // Only call API if valid
    }, 1000);

    // Cleanup function
    return () => clearTimeout(debounceTimeout.current);
  }, [username]);
  useEffect(() => {
    setEmailInputEnabled(false)
    setPhoneInputEnabled(false)
    setGlobalUpdateEmail("")
    setGlobalUpdatePhone("")
  }, [])

  // Function to handle final confirm action
  const handleDeleteAccount = async (password) => {
    try {
      let body = {
        password
      }
      const response = await authFetch(`/api/v1/user/delete`, {
        method: "POST",
        body: body,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response?.code === 200) {
        localStorage.removeItem("userToken")
        showToast("Account Deleted!", "white")
        navigate("/");
        setIsDeleteModalOpen(false); // Close modal after action
      } else {
        setDeleteAccountError(response?.error)
      }

    } catch (error) {
      console.log("deleteUserAccount:", error);
    }

  };
  const changePhoneClick = () => {
    setPhoneInputEnabled(true);
    setUsername("");
    setGlobalUpdateEmail("")
    setIsUserNameAvailable(null)
    setShowUserNameInput(false);
    setEmailInputEnabled(false)
    setError({ email: "", username: "" })
  }
  const changeEmailClick = () => {
    setEmailInputEnabled(true);
    setUsername("");
    setGlobalUpdatePhone("")
    setIsUserNameAvailable(null)
    setShowUserNameInput(false);
    setPhoneInputEnabled(false)
    setError({ phone: "", username: "" })
  }
  const changeUsernameClick = () => {
    setShowUserNameInput(true);
    setPhoneInputEnabled(false);
    setEmailInputEnabled(false);
    setGlobalUpdateEmail("");
    setGlobalUpdatePhone("")
    setError({ email: "", phone: "" })

  }
  if (!globalProfile) return <LoadingScreen />;
  return (
    <div>
      <div className='flex flex-col gap-5 w-full sm:w-[352px] h-auto sm:h-[505px]'>
        <div className='flex items-center justify-center relative'>
          <FaChevronLeft onClick={() => navigate("/sell")} className='text-gray-600 text-lg cursor-pointer absolute left-0' />
          <h3 className='text-lg text-[#F4F4F4] font-medium font-general'>
            Profile
          </h3>
        </div>
        <div className='flex flex-col gap-6 items-center w-full'>
          <div
            className='w-32 h-32 flex items-center justify-center rounded-full text-[#F4F4F4B2] text-7xl font-semibold font-general relative overflow-hidden bg-cover bg-center'
            style={{ backgroundImage: globalProfile?.profile_image_url ? `url(${globalProfile.profile_image_url})` : `none` }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {/* Gradient overlay only if no profile image */}
            {!globalProfile?.profile_image_url && (
              <div className='absolute inset-0 bg-gradient-to-b from-green-400 to-[#A9EA2E] opacity-50'></div>
            )}

            {/* Show Initials if No Profile Image */}
            {!globalProfile?.profile_image_url && globalProfile?.email && (
              <span className="relative text-[#F4F4F4B2] text-7xl font-semibold">
                {globalProfile.email.charAt(0).toUpperCase()}
              </span>
            )}

            {/* File Upload Input (Hidden) */}
            <input
              type="file"
              id="fileUpload"
              accept="image/*"
              className="hidden"
              onChange={handleImageUpload}
            />



            {/* Only show the overlay when hovered */}
            {hovered && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <label htmlFor="fileUpload" className="cursor-pointer">
                  <LuUpload className="text-white text-3xl" />
                </label>
              </div>
            )}

          </div>
          {
            !showUserNameInput && (
              <div
                onClick={() => { changeUsernameClick() }}
                className="text-[#FFFFFF80] hover:text-white text-base font-medium font-general cursor-default"
              >
                {globalProfile?.username || ""}
              </div>

            )
          }


          <div className='flex flex-col gap-2 w-80'>
            <form className='flex flex-col gap-3'>
              {
                showUserNameInput && (
                  <div className='relative w-full'>
                    <input
                      type='text'
                      id='username'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-[#FFFFFF14] rounded-lg 
                 bg-transparent  text-white outline-none placeholder:text-xs placeholder-[#FFFFFF4D] 
                 focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
                 ${error.password ? "pr-48" : "pr-20"}
                text-ellipsis overflow-hidden
                `}
                      placeholder='New Username'
                      autoComplete='off'
                    />
                    <div
                      className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
                      onMouseDown={(e) => e.preventDefault()}>
                      {username && username.length > 3 && (
                        <div
                          className={`flex items-center ${isUsernameAvailable ? "text-[#43C32D]" : "text-[#FF5A5A]"
                            } text-[12px] font-medium leading-[16.8px] font-general relative z-10`}
                        >
                          {loadingUsernameCheck || isUsernameAvailable === null
                            ? "" // Show nothing while loading or before API response
                            : isUsernameAvailable
                              ? "Available"
                              : "Already taken"}
                        </div>

                      )}
                    </div>
                    <div
                      className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
                      onMouseDown={(e) => e.preventDefault()}>
                      {(error?.username) && (
                        <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                          {error?.username}
                        </div>
                      )}
                    </div>
                  </div>
                )
              }

              <div className='relative w-full'>
                <input
                  type='text'
                  id='gmail'
                  disabled={!emailInputEnabled}
                  ref={emailInputRef} // Attach ref to input
                  value={globalUpdateEmail}
                  onChange={(e) => { setGlobalUpdateEmail(e.target.value); validateEmail(e.target.value) }}
                  className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-[#FFFFFF14] rounded-lg 
               bg-transparent  text-white outline-none placeholder:text-xs placeholder-[#FFFFFF4D] 
               focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
               ${error.email ? "pr-48" : "pr-20"}
              text-ellipsis overflow-hidden
              `}
                  placeholder={emailInputEnabled ? "New Email Address" : globalProfile?.email}
                  autoComplete='off'
                />
                <div
                  className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
                  onMouseDown={(e) => e.preventDefault()}>
                  {(error.email) && (
                    <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                      {error.email}
                    </div>

                  )}
                  <div
                    className='absolute right-1 top-[50%] translate-y-[-50%] flex items-center space-x-2'
                    onMouseDown={(e) => e.preventDefault()}>
                    {
                      !emailInputEnabled && (
                        <button
                          type='button'
                          className='text-[12px] font-medium text-white underline underline-offset-2 decoration-white/60 whitespace-nowrap'
                          //onClick={() => setIsConfirmModalOpen(true)}
                          onClick={() => changeEmailClick()}
                        >
                          Change
                        </button>
                      )
                    }

                  </div>
                  {/* <div
                    className='absolute right-1 top-[50%] translate-y-[-50%] flex items-center space-x-2'
                    onMouseDown={(e) => e.preventDefault()}>
                    {
                      (emailInputEnabled && !error.email) && (
                        <button
                          type='button'
                          className='text-[12px] font-medium text-white underline underline-offset-2 decoration-white/60 whitespace-nowrap'
                          onClick={() => getUpdateProfileOtp("email")}
                        >
                          Verify Now
                        </button>
                      )
                    }

                  </div> */}
                </div>
              </div>
              <div className='relative w-full'>
                <div className="flex items-center border border-[#FFFFFF14] rounded-lg bg-transparent text-white px-3 py-2 h-11 sm:h-8 sm:text-[12px]">
                  {
                    phoneInputEnabled && (
                      <span className="text-white sm:text-[12px] font-[500] leading-[16.8px] pr-1 font-[General Sans]">+91</span>
                    )
                  }
                  <input
                    type='tel'
                    id='mobile'
                    value={globalUpdatePhone}
                    disabled={!phoneInputEnabled}
                    ref={phoneInputRef} // Attach ref to input
                    onChange={(e) => {
                      let input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      if (input.length > 10) return; // Allow only 10 digits
                      setGlobalUpdatePhone(input);
                      validateIndianPhone(input)
                    }}
                    className={`w-full py-2  h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] bg-transparent outline-none
      text-white placeholder:text-xs placeholder-[#FFFFFF4D] focus:ring-0 caret-glow font-general
      ${error?.phone ? "pr-36" : "pr-20"} text-ellipsis overflow-hidden`}
                    placeholder={(!globalProfile?.isPhoneAdded && !phoneInputEnabled) ? "Please add your phone" : phoneInputEnabled ? "Enter Phone Number" : globalProfile?.phone}
                    autoComplete='off'
                  />
                </div>

                <div className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2' onMouseDown={(e) => e.preventDefault()}>
                  {error?.phone && (
                    <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                      {error?.phone}
                    </div>
                  )}

                  {!phoneInputEnabled && (
                    <button
                      type='button'
                      className='absolute right-1 text-[12px] font-medium text-white underline underline-offset-2 decoration-white/60 whitespace-nowrap'
                      onClick={() => changePhoneClick()}
                    >
                      Change
                    </button>
                  )}
                </div>
              </div>

              <div className='relative w-full'>
                <input
                  type='text'
                  id='password'
                  value=''
                  disabled={true}
                  onClick={() => navigate("/reset-password")}
                  className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-[#FFFFFF14] rounded-lg 
               bg-transparent  text-white outline-none placeholder:text-xs placeholder-[#FFFFFF4D] 
               focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
               ${error.password ? "pr-48" : "pr-20"}
              text-ellipsis overflow-hidden
              `}
                  placeholder='✦✦✦✦✦✦✦✦✦✦✦✦✦'
                  autoComplete='off'
                />
                <div
                  className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
                  onMouseDown={(e) => e.preventDefault()}>
                  <div
                    className='absolute right-1 top-[50%] translate-y-[-50%] flex items-center space-x-2'
                    onMouseDown={(e) => e.preventDefault()}>
                    <button
                      type='button'
                      className='text-[12px] font-medium text-white underline underline-offset-2 decoration-white/60 whitespace-nowrap'
                      onClick={() => navigate("/reset-password")}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault()
                  showUserNameInput ? updateUsername() : (emailInputEnabled || phoneInputEnabled) && setIsConfirmModalOpen(true)
                }}
                disabled={
                  (showUserNameInput &&
                    (error?.username || !username || username.length <= 3 || isSubmitting || !isUsernameAvailable)) ||
                  (emailInputEnabled &&
                    (error?.email || !globalUpdateEmail || !globalUpdateEmail.includes('@') || isSubmitting)) ||
                  (
                    phoneInputEnabled &&
                    (!globalUpdatePhone || error?.phone || isSubmitting)
                  )

                }

                className={`w-full h-11 sm:h-8 mt-2 text-sm font-[500] rounded-lg transition flex justify-center items-center
                  ${(showUserNameInput &&
                    !error.username &&
                    username &&
                    username.length > 3 &&
                    !isSubmitting &&
                    isUsernameAvailable) ||
                    (emailInputEnabled &&
                      !error.email &&
                      globalUpdateEmail &&
                      globalUpdateEmail.includes('@') &&
                      !isSubmitting) ||
                    (
                      phoneInputEnabled && !error?.phone && globalUpdatePhone && !isSubmitting
                    )
                    ? "bg-[#A7E92D] text-black"
                    : "bg-white/50 text-black cursor-not-allowed"
                  }
                  ${isSubmitting ? "cursor-wait" : ""}
                `}
              >
                {isSubmitting ? (
                  <div className='flex justify-center items-center'>
                    <StageSpinner size={30} color='#000' />
                  </div>
                ) : (
                  "Save Changes"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div onClick={() => setIsDeleteModalOpen(true)} className="flex justify-end gap-1 items-center hover:text-red-500 text-[#FFFFFF4D] text-xs underline font-medium font-general cursor-pointer">
        <RiDeleteBin6Fill />
        <p>Delete Account</p>
      </div>
      <ConfirmPasswordModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        type={emailInputEnabled ? "email" : phoneInputEnabled && "phone"}
      />
      {/* Modal */}
      <DeleteAccountModal
        isOpen={isDeleteModalOpen}
        error={deleteAccountError}
        setError={setDeleteAccountError}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteAccount}
      />
    </div>
  );
};

export default Profile;
