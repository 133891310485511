import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Kyc from "./components/Kyc";
import Signup from "./components/Signup";
import Logout from "./components/Logout";
import Sell from "./Page/SellLayout/Sell"
import BeforeKYC from "./components/BeforeKYC";
import AddPhone from "./Page/AddPhone/AddPhone";
import UPICheckout from "./components/UPICheckout";
import PublicRoute from "./components/PublicRoute";
import SellHistory from "./components/SellHistory";
import BankAccounts from "./components/BankAccount";
import PrivateRoute from "./components/PrivateRoute";
import IMPSCheckout from "./components/IMPSCheckout";
import AddAadhaar from "./Page/AddAadhaar/AddAadhaar";
import HistoryDetail from "./components/HistoryDetail";
import WelcomeEmail from "./Page/Welcome/WelcomeEmail";
import OrderConfirmed from "./components/OrderConfirmed";
import WalletCheckout from "./components/WalletCheckout";
import ChangePassword from "./components/ChangePassword";
import Confirmation from "./Page/Confirmation/Confirmation";
import ErrorConfirm from "./Page/Confirmation/ErrorConfirm";
import GetStarted from "./Page/NewUserOnboarding/GetStarted";
import VerifyTransaction from "./components/VerifyTransaction";
import IdentityCheck from "./Page/IdentityCheck/IdentityCheck";
import AddBankAccount from "./Page/AddBankAccount/AddBankAccount";
import ForgetPassword from "./Page/ForgetPassword/ForgetPassword";
import OTPVerification from "./Page/OtpVerification/OTPVerification";
import ConfirmationCheck from "./Page/Confirmation/ConfirmationCheck";
import UserRegistration from "./Page/NewUserOnboarding/UserRegistration";
import SelectBankAccount from "./Page/SelectBankAccount/SelectBankAcc";
import ExistingUserAuth from "./Page/ExistingUserOnboarding/ExistingUserAuth";
import ReviewConfirm from "./Page/ReviewConfirm/ReviewConfirm";
import SelectBankAcc from "./Page/SelectBankAccount/SelectBankAcc";
import Deposit from "./Page/Deposit/Deposit";
import TransactionDetails from "./Page/TransactionDetails/TransactionDetails";
import USDTRecieved from "./Page/TransactionConfirmation/USDTRecieved";
import TransactionComplete from "./Page/TransactionConfirmation/TransactionComplete";
import SellingHistory from "./Page/SellingHistory/SellingHistory";
import FundsOnWay from "./Page/TransactionConfirmation/FundsOnWay";
import WalletAddress from "./Page/TransactionDetails/WalletAddress";
import TermAndCondition from "./Page/Term&Condition/TermAndCondition";
import Profile from "./Page/Profile/Profile";
import HelpSupport from "./Page/Help&Support/HelpSupport";
import LoadingUSDT from "./Page/Start/LoadingUSDT";
import MainLayout from "./Page/Main/MainLayout";
import LetStart from "./Page/Start/LetStart";
import ResetPassword from "./Page/Profile/ResetPassword";

const AppRoutes = ({ type }) => {
  return (
    <>
      <Routes>
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='/' element={<PublicRoute component={LoadingUSDT} redirectTo="/sell" />} />
        <Route element={<MainLayout />}>
          <Route path='/sell' element={<Sell />} />
          <Route path='/otp' element={<OTPVerification />} />
          <Route path='/identity' element={<IdentityCheck />} />
          <Route path="/help-support" element={<HelpSupport />} />
          <Route path="/reset-password" element={<PrivateRoute component={ResetPassword} redirectTo="/sell" />} />
          <Route path="/verified-error" element={<ErrorConfirm />} />
          <Route path="/review-confirm" element={<ReviewConfirm />} />
          <Route path="/verified-confirm" element={<Confirmation />} />
          <Route path='/forget-password' element={<ForgetPassword />} />
          <Route path="/selling-history" element={<SellingHistory />} />
          <Route path="/verified-check" element={<ConfirmationCheck />} />
          <Route path='/term-conditions' element={<PublicRoute component={TermAndCondition} redirectTo="/sell" />} />
          <Route path='/user-registration' element={<UserRegistration />} />
          <Route path='/existinguser-login' element={<ExistingUserAuth />} />
          <Route path='/start' element={<PublicRoute component={LetStart} redirectTo="/sell" />} />
          <Route path='/profile' element={<PrivateRoute component={Profile} redirectTo="/sell" />} />
          <Route path='/welcome' element={<PublicRoute component={WelcomeEmail} redirectTo="/sell" />} />
          <Route path='/get-started' element={<PublicRoute component={GetStarted} redirectTo='/sell' />} />
          <Route path='/add-bank' element={<PrivateRoute component={AddBankAccount} redirectTo='/login' />} />
          <Route path="/select-bank" element={<PrivateRoute component={SelectBankAcc} redirectTo='/login' />} />
        </Route>
        <Route
          path="/deposit"
          element={<Deposit />}
        />
        <Route
          path="/transaction-details"
          element={<TransactionDetails />}
        />
        <Route
          path="/usdt-recieved"
          element={<USDTRecieved />}
        />
        <Route
          path="/transaction-complete"
          element={<TransactionComplete />}
        />
        <Route
          path="/funds-on-way"
          element={<FundsOnWay />}
        />
        <Route path='/wallet-address' element={<WalletAddress />} />
        <Route
          path='/kyc'
          element={<PrivateRoute component={Kyc} redirectTo='/login' />}
        />
        <Route
          path='/logout'
          element={<PrivateRoute component={Logout} redirectTo='/login' />}
        />
        <Route
          path='/signup'
          element={<PublicRoute component={Signup} redirectTo='/profile' />}
        />
        <Route
          path='/addPhone'
          element={<PrivateRoute component={AddPhone} redirectTo='/login' />}
        />
        <Route
          path='/bank'
          element={
            <PrivateRoute component={BankAccounts} redirectTo='/login' />
          }
        />
        <Route
          path='/before-kyc'
          element={<PrivateRoute component={BeforeKYC} redirectTo='/login' />}
        />
        <Route
          path='/addAadhaar'
          element={<PrivateRoute component={AddAadhaar} redirectTo='/login' />}
        />
        <Route
          path='/confirm'
          element={
            <PrivateRoute component={OrderConfirmed} redirectTo='/login' />
          }
        />
        <Route
          path='/fiataccount'
          element={<SelectBankAccount />}
        //element={<PublicRoute component={SelectBank} redirectTo='/login' />}
        />

        <Route
          path='/upi-checkout'
          element={<PrivateRoute component={UPICheckout} redirectTo='/login' />}
        />
        <Route
          path='/sell-history'
          element={<PrivateRoute component={SellHistory} redirectTo='/login' />}
        />
        <Route
          path='/imps-checkout'
          element={
            <PrivateRoute component={IMPSCheckout} redirectTo='/login' />
          }
        />
        <Route
          path='/history-detail'
          element={
            <PrivateRoute component={HistoryDetail} redirectTo='/login' />
          }
        />
        <Route
          path='/change-password'
          element={
            <PrivateRoute component={ChangePassword} redirectTo='/login' />
          }
        />
        <Route
          path='/wallet-checkout'
          element={
            <PrivateRoute component={WalletCheckout} redirectTo='/login' />
          }
        />
        <Route
          path='/verify-transaction'
          element={
            <PrivateRoute component={VerifyTransaction} redirectTo='/login' />
          }
        />
      </Routes >
    </>
  );
};

export default AppRoutes;
