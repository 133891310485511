import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import CheckOnboard from '../../utils/checkOnBoard';
import { useLocation } from 'react-router-dom';
import useLoadingStore from '../../store/LoadingStore';
import LoadingScreen from '../LoadingScreen/Loading';
const IdentityCheck = () => {
  const location = useLocation()
  const type = location.state?.type
  const { loading, setLoading } = useLoadingStore();
  const [isCheckOnBoard, setIsCheckOnBoard] = useState(false)
  const verification = async () => {
    setIsCheckOnBoard(true)
  }
  useEffect(() => {
    if (type !== 'signin' && type !== "signup" && type !== "sell") {
      setIsCheckOnBoard(true)
    }
  }, [type])
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [])

  if (loading) return <LoadingScreen />;

  return (
  <div className='flex flex-col gap-5 font-general w-full sm:w-[320px] h-auto text-center '>
      <h2 className='text-2xl font-[500] leading-[33.6px] text-[#fff]'>Complete KYC Verification</h2>
      <p className='font-[500] text-white/40 text-sm opacity-80'>
        To ensure a seamless experience, let’s verify your identity. This helps us keep transactions secure and smooth.
      </p>
      <button
        onClick={verification}
        type='submit'
        className="w-full h-11 sm:h-8  text-sm font-[500] rounded-lg transition flex justify-center items-center bg-[#A7E92D] text-black"
      >
        Continue to Verification
      </button>
      <Link to={'/sell'}>
        <span className="font-[500] border-b-2 border-white/40 text-sm cursor-pointer text-white/40 hover:text-[#fff] hover:border-[#fff]">
          Skip for now
        </span>
      </Link>
      {isCheckOnBoard && <CheckOnboard type={type} />}
    </div>
  );
};

export default IdentityCheck;