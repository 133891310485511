import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp, FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../Page/LoadingScreen/Loading";
import useRetryTransactionStore from "../../store/retryTransactionStore";
import { convertToIST } from "../../utils/dateTimeFormat";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { FaRegHourglassHalf } from "react-icons/fa6";
import authFetch from "../../utils/authFetch";
import { useLocation } from "react-router-dom";
const SellingHistory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { transactionId, type } = location.state || {};
  const [sellHistoryTx, setSellHistoryTx] = useState([]);
  const { setRetryTransaction } = useRetryTransactionStore();
  const [openTransaction, setOpenTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const toggleDropdown = (transactionId) => {
    setOpenTransaction(
      openTransaction === transactionId ? null : transactionId
    );
  };
  useEffect(() => {
    if (transactionId && type === "transactionItem") {
      setOpenTransaction(transactionId);
    }
  }, [transactionId, type]);

  const getStatusIcon = (txStatus) => {
    if (txStatus === "SUCCESS") {
      return (
        <div className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-green-500'>
          <IoMdCheckmark className='text-white text-lg' />
        </div>
      );
    } else if (txStatus === "PENDING") {
      return (
        <div className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-yellow-500'>
          <FaRegHourglassHalf className='text-white text-sm' />
        </div>
      );
    } else if (txStatus === "FAILED") {
      return (
        <div className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-red-500'>
          <RxCross2 className='text-white text-lg' />
        </div>
      );
    }
  };
  const getSellHistory = async () => {
    try {
      setLoading(true);
      const response = await authFetch("/api/v1/offramp/getAllTransactions", {
        method: "GET",
      });

      if (response?.code === 200) {
        setSellHistoryTx(response?.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching getSellHistory:", error);
    }
  };
  const formatDateAndTime = (isoString) => {
    const date = new Date(isoString);

    // Format date (e.g., "27 Feb")
    const optionsDate = { day: "2-digit", month: "short" };
    const formattedDate = date.toLocaleDateString("en-GB", optionsDate);

    // Format time (e.g., "05:41 PM")
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedTime = date.toLocaleTimeString("en-GB", optionsTime);

    return { date: formattedDate, time: formattedTime };
  };
  useEffect(() => {
    getSellHistory();
  }, []);
  if (loading) return <LoadingScreen />;
  return (
    <div className='flex flex-col gap-6 w-full sm:w-[364px] h-auto sm:h-[613px]'>
      <div className='flex items-center justify-center relative'>
        <FaChevronLeft onClick={() => navigate(-1)} className='text-gray-600 text-lg cursor-pointer absolute left-0' />
        <h3 className='text-lg text-[#F4F4F4] font-medium font-general'>
          Selling History
        </h3>
      </div>
      <div className='flex flex-col gap-6 h-full  overflow-y-auto overscroll-y-contain no-scrollbar'>
        {sellHistoryTx.length > 0 ? (
          sellHistoryTx.map((transaction, index) => (
            <div className='flex flex-col w-full p-3  text-[#F4F4F40A] gap-6 bg-[#F4F4F40A] rounded-lg '>
              <div className=' flex flex-col gap-2'>
                <div className='flex justify-between gap-2'>
                  <div className='flex text-[#F4F4F480] gap-2'>
                    {getStatusIcon(transaction?.status)}
                    <span className='text-xs font-general font-medium'>
                      {transaction?.status
                        ? transaction.status.charAt(0).toUpperCase() +
                        transaction.status.slice(1).toLowerCase()
                        : ""}
                    </span>
                  </div>
                  <button
                    onClick={() => toggleDropdown(transaction.transaction_id)}>
                    {openTransaction === transaction.transaction_id ? (
                      <FaAngleUp className='text-[#F4F4F480]' />
                    ) : (
                      <FaAngleDown className='text-[#F4F4F480]' />
                    )}
                  </button>
                </div>
                {openTransaction !== transaction.transaction_id && (
                  <p className='text-base text-[#F4F4F4] font-general font-medium text-nowrap'>
                    {transaction?.transaction_id.length > 9 &&
                      transaction?.transaction_id}
                  </p>
                )}
              </div>
              {openTransaction !== transaction.transaction_id && (
                <div className='flex justify-between '>
                  <div className='flex flex-col gap-2'>
                    <span className='text-[#F4F4F480] font-general font-medium text-xs'>
                      {convertToIST(transaction?.date).date}{" "}
                      {convertToIST(transaction?.date).time}
                    </span>
                  </div>
                  {transaction?.status === "FAILED" && (
                    <button
                      className='text-[12px] font-[500] leading-[140%] tracking-[0%] underline underline-offset-0 decoration-solid decoration-[0%] font-general text-[#F4F4F4]'
                      onClick={(e) => {
                        e.preventDefault();
                        setRetryTransaction(transaction);
                      }}>
                      Retry
                    </button>
                  )}
                </div>
              )}
              {openTransaction === transaction.transaction_id && (
                <div className=' flex flex-col gap-2'>
                  <div className='flex flex-col gap-2 rounded-md p-[10px] bg-[#F4F4F40A]'>
                    <span className='text-[#FFAA00] text-base font-medium font-general'>
                      You Sent: {transaction?.fromAmount}{" "}
                      {transaction?.fromCurrency} → {transaction?.toAmount}{" "}
                      {transaction?.toCurrency}
                    </span>
                    <div className='flex flex-col'>
                      <div className='flex gap-1 rounded-md'>
                        <span className='text-[#F4F4F480] font-general font-medium text-sm'>
                          Status:
                        </span>
                        <span className='text-[#F4F4F4] font-general font-medium text-sm'>
                          {transaction?.status
                            ? transaction.status.charAt(0).toUpperCase() +
                            transaction.status.slice(1).toLowerCase()
                            : ""}
                        </span>
                      </div>
                      <div className='flex gap-1 rounded-md'>
                        <span className='text-[#F4F4F480] font-general font-medium text-sm'>
                          Chain:
                        </span>
                        <span className='text-[#F4F4F4] uppercase font-general font-medium text-sm'>
                          {transaction?.chain}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col gap-2 rounded-md p-[10px] bg-[#F4F4F40A]'>
                    <div>
                      <span className='text-[#F4F4F4] text-base font-medium font-general'>
                        Fiat Transfer Status :{" "}
                        {transaction?.Status
                          ? transaction.Status.charAt(0).toUpperCase() +
                          transaction.Status.slice(1).toLowerCase()
                          : ""}
                      </span>

                      <span className='flex text-[#F4F4F480] font-general font-medium text-xs nowrap'>
                        {transaction?.FiatMoneyTransferStatus || ""}
                      </span>
                    </div>
                    {/* need to be replaced with actual receiving account */}
                    <span className='flex text-[#F4F4F4] font-general font-medium text-sm nowrap'>
                      Receiving Account : {transaction?.fiatAccount}
                    </span>
                  </div>
                  <div className='flex flex-col gap-2 rounded-md p-[10px] bg-[#F4F4F40A]'>
                    <div>
                      <span className='text-[#F4F4F4] text-base font-medium font-general'>
                        Crypto Transfer Status :{" "}
                        {transaction?.txStatus
                          ? transaction.txStatus.charAt(0).toUpperCase() +
                          transaction.txStatus.slice(1).toLowerCase()
                          : ""}
                      </span>
                      <span className='flex text-[#F4F4F480] font-general font-medium text-xs nowrap'>
                        {transaction?.cryptoTransferStatus || ""}
                      </span>
                    </div>
                    <span className='flex text-[#F4F4F4] font-general font-medium text-sm nowrap'>
                      Wallet Address :{" "}
                      {transaction?.depositAddress
                        ? `${transaction.depositAddress.slice(
                          0,
                          10
                        )}...${transaction.depositAddress.slice(-10)}`
                        : ""}
                    </span>
                  </div>
                  <div className='flex flex-col gap-3 rounded-md p-[10px] bg-[#F4F4F40A]'>
                    <div>
                      <span className='text-[#F4F4F4] text-base font-medium font-general'>
                        Transaction Details
                      </span>
                    </div>
                    <div>
                      <span className='flex text-[#F4F4F4] font-general font-medium text-sm nowrap'>
                        Reference ID : {transaction?.reference_id}
                      </span>
                      <span className='flex text-[#F4F4F4] font-general font-medium text-sm nowrap'>
                        Transaction ID : <br />
                        {transaction?.transaction_id}
                      </span>
                      <span className='flex text-[#F4F4F4] font-general font-medium text-sm nowrap'>
                        BlockChain Hash : {transaction?.txHash}
                      </span>
                    </div>
                  </div>
                  <div className='flex flex-col gap-3 rounded-md p-[10px] bg-[#F4F4F40A]'>
                    <div>
                      <span className='text-[#F4F4F4] text-base font-medium font-general'>
                        Transaction Info
                      </span>
                    </div>
                    <div>
                      <span className='flex text-[#F4F4F4] font-general font-medium text-sm nowrap'>
                        Date : {formatDateAndTime(transaction?.date).date}
                      </span>
                      <span className='flex text-[#F4F4F4] font-general font-medium text-sm nowrap'>
                        Time : {formatDateAndTime(transaction?.date).time}
                      </span>
                      <span className='flex text-[#F4F4F4] font-general font-medium text-sm nowrap'>
                        Exchange Rate : {transaction?.rate}{" "}
                        {transaction?.toCurrency}/{transaction?.fromCurrency}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flex flex-col gap-10">
            <p className='flex text-center  text-[#FFFFFF66]'>
              Your selling history is currently empty. Start selling to see your
              transactions here.
            </p>
            <button
              className={`w-full py-2 px-3 h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center bg-[#A7E92D] text-black cursor-wait`}>
              Start Selling
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SellingHistory;
