import "../../App.css"
import logo from "../../assets/logo.jpeg";
import authFetch from "../../utils/authFetch";
import { useNavigate } from "react-router-dom";
import { StageSpinner } from "react-spinners-kit";
import React, { useEffect, useState } from "react";
import useLoginStore from "../../store/LoginStore";
import useSignUpStore from "../../store/SignupStore";
import useLoadingStore from "../../store/LoadingStore";
const WelcomeEmail = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoadingStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setGlobalEmail: setSignupEmail } = useSignUpStore();
  const { globalEmail, setGlobalEmail, clearGlobalEmail } = useLoginStore();
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(globalEmail); // Email regex validation

  const onCheckUser = async (userExist) => {
    try {
      setLoading(true);
      if (userExist) {
        // if Existing User navigate to login flow
        navigate("/existinguser-login", { state: { type: "signin" } })
      } else {
        setSignupEmail(globalEmail)
        // if Existing User navigate to user registration flow
        navigate("/get-started", { state: { type: "signup" } })
      }

      // navigate("/otp", { state: { type: "login" } }); // Navigate to OTP page
    } catch (err) {
      console.log("onLogin", err);
    }
  };


  const checkUser = async (e) => {
    e.preventDefault();
    if (!isValidEmail) return;
    setIsSubmitting(true); // Show loader
    try {
      const response = await authFetch(`/api/v1/user/checkUser?email=${globalEmail}`, {
        method: 'GET',
      });
      if (response.code === 200) {
        onCheckUser(response?.data?.existingUser)
      }
    } catch (error) {
      console.error("checkUser", error);
    }
    finally {
      setIsSubmitting(false); // Show loader
    }
  };

  useEffect(() => {
    clearGlobalEmail();
  }, [])

  return (
    <div className='flex flex-col justify-between font-general w-full  sm:w-[320px] h-[266px] sm:h-auto gap-6 text-center sm:mt-0 -mt-10'>
      <img
        src={logo}
        alt='Logo'
        className='w-[68px] h-[68px] rounded-lg mx-auto'
      />
      <span className="flex flex-col gap-1">
        <h2 className='text-2xl font-[500] leading-[33.6px] text-[#fff]'>Welcome</h2>
        <p className='font-[500] text-white/40 text-sm  opacity-80'>
          Enter your email to Login/Sign Up
        </p>
      </span>
      <form onSubmit={checkUser} className="flex flex-col gap-4 ">
        <input
          type='email'
          id="emailInput"
          value={globalEmail}
          onChange={(e) => setGlobalEmail(e.target.value)}
          className='w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm border border-transparent rounded-lg bg-white/10 text-white  placeholder:text-xs placeholder-white/60 focus:border font-medium font-general 
             outline-none focus:border-[#FFFFFF33] focus:ring-0 caret-glow'
          placeholder="Enter your email"
          aria-label="Enter your email"
          required
          autoComplete="off" // ✅ Disables autocomplete
          autoCorrect="off" // ✅ Helps disable autofill
          spellCheck="false" // ✅ Ensures no spell suggestions
        />
        <button
          disabled={!isValidEmail || isSubmitting}
          className={`w-full py-2 px-3 h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center ${isValidEmail
            ? "bg-[#A7E92D] text-black"
            : "bg-white/50 text-black cursor-not-allowed"
            } ${isSubmitting ? "cursor-wait" : ""}`}
        >
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <StageSpinner size={30} color="#000" />
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </form>

    </div>
  );
};

export default WelcomeEmail;
