import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";

const LoadingUSDT = () => {
  const navigate = useNavigate();
  const [start, setStart] = useState(false);
  
  useEffect(() => {
    const navigateTimeout = setTimeout(() => {
      navigate("/start");
    }, 4000);

    return () => clearTimeout(navigateTimeout); 
  }, [navigate]);
  
  useEffect(() => {
    const timer = setTimeout(() => setStart(true), 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className='fixed inset-0 flex justify-center items-center z-50'>
      <div className='relative flex justify-center items-center group overflow-hidden w-full ml-10'>
        <svg
          width='129'
          height='132'
          viewBox='0 0 80 132'
          fill='none'
          className={`transition-transform duration-700 delay-200 ${
            start ? "-translate-x-40" : "translate-x-0 opacity-0"
          }`}
          xmlns='http://www.w3.org/2000/svg'>
          <g filter='url(#filter0_f_2478_9390)'>
            <path
              d='M57.3001 49.2L50.1001 56.4C60.9504 64.774 67.6674 65.0716 78.9001 56.4L71.7001 49.2L64.5001 56.4L57.3001 49.2Z'
              fill='url(#paint0_linear_2478_9390)'
            />
            <path
              d='M59.2278 82.6956L69.4097 82.7923C67.7878 69.1827 63.2959 64.1797 49.2392 62.2352L49.1426 72.4171L59.3245 72.5138L59.2278 82.6956Z'
              fill='url(#paint1_linear_2478_9390)'
            />
            <path
              d='M79.5001 72.5999H69.9001C71.2488 66.4838 73.3817 64.3619 79.5001 63V72.5999Z'
              fill='url(#paint2_linear_2478_9390)'
            />
            <path
              d='M57.3001 49.2L50.1001 56.4C60.9504 64.774 67.6674 65.0716 78.9001 56.4L71.7001 49.2L64.5001 56.4L57.3001 49.2Z'
              stroke='url(#paint3_linear_2478_9390)'
              stroke-width='1.2'
            />
            <path
              d='M59.2278 82.6956L69.4097 82.7923C67.7878 69.1827 63.2959 64.1797 49.2392 62.2352L49.1426 72.4171L59.3245 72.5138L59.2278 82.6956Z'
              stroke='url(#paint4_linear_2478_9390)'
              stroke-width='1.2'
            />
            <path
              d='M79.5001 72.5999H69.9001C71.2488 66.4838 73.3817 64.3619 79.5001 63V72.5999Z'
              stroke='url(#paint5_linear_2478_9390)'
              stroke-width='1.2'
            />
          </g>
          <path
            d='M57.3001 49.2L50.1001 56.4C60.9504 64.774 67.6674 65.0716 78.9001 56.4L71.7001 49.2L64.5001 56.4L57.3001 49.2Z'
            fill='url(#paint6_linear_2478_9390)'
          />
          <path
            d='M59.2278 82.6956L69.4097 82.7923C67.7878 69.1827 63.2959 64.1797 49.2392 62.2352L49.1426 72.4171L59.3245 72.5138L59.2278 82.6956Z'
            fill='url(#paint7_linear_2478_9390)'
          />
          <path
            d='M79.5001 72.5999H69.9001C71.2488 66.4838 73.3817 64.3619 79.5001 63V72.5999Z'
            fill='url(#paint8_linear_2478_9390)'
          />
          <path
            d='M57.3001 49.2L50.1001 56.4C60.9504 64.774 67.6674 65.0716 78.9001 56.4L71.7001 49.2L64.5001 56.4L57.3001 49.2Z'
            stroke='url(#paint9_linear_2478_9390)'
            stroke-width='1.2'
          />
          <path
            d='M59.2278 82.6956L69.4097 82.7923C67.7878 69.1827 63.2959 64.1797 49.2392 62.2352L49.1426 72.4171L59.3245 72.5138L59.2278 82.6956Z'
            stroke='url(#paint10_linear_2478_9390)'
            stroke-width='1.2'
          />
          <path
            d='M79.5001 72.5999H69.9001C71.2488 66.4838 73.3817 64.3619 79.5001 63V72.5999Z'
            stroke='url(#paint11_linear_2478_9390)'
            stroke-width='1.2'
          />
          <defs>
            <filter
              id='filter0_f_2478_9390'
              x='0.536865'
              y='0.35144'
              width='127.563'
              height='131.047'
              filterUnits='userSpaceOnUse'
              color-interpolation-filters='sRGB'>
              <feFlood flood-opacity='0' result='BackgroundImageFix' />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='BackgroundImageFix'
                result='shape'
              />
              <feGaussianBlur
                stdDeviation='24'
                result='effect1_foregroundBlur_2478_9390'
              />
            </filter>
            <linearGradient
              id='paint0_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint1_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint2_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint3_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint4_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint5_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint6_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint7_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint8_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint9_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint10_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
            <linearGradient
              id='paint11_linear_2478_9390'
              x1='48.9'
              y1='49.2'
              x2='80.1'
              y2='82.7999'
              gradientUnits='userSpaceOnUse'>
              <stop stop-color='#A9EA2E' />
              <stop offset='1' stop-color='#5CD61B' />
            </linearGradient>
          </defs>
        </svg>
        <h2
          className={`whitespace-nowrap text-2xl font-[500] opacity-0 leading-[33.6px] text-white absolute transition-all duration-700 delay-500 ${
            start ? "translate-x-5 opacity-100" : "translate-x-full "
          }`}>
          USDT Marketplace
        </h2>
      </div>
    </div>
  );
};

export default LoadingUSDT;
