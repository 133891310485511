import React, { useState, useRef, useEffect } from "react";
import { StageSpinner } from "react-spinners-kit";
const DeleteAccountModal = ({ isOpen, error, setError, onClose, onConfirm }) => {
    const [password, setPassword] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const modalRef = useRef(null);
    const isValidPassword = password.length >= 6;
    // Close modal on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
                setPassword("");
                setError("");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [onClose]);

    if (!isOpen) return null;

    const handleConfirm = () => {
        setIsSubmitting(true)
        if (password.trim()) {
            onConfirm(password);
            setIsSubmitting(false)
            setPassword("");
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black/60 z-50 backdrop-blur-sm">
            <div
                ref={modalRef}
                className="relative inline-flex flex-col items-start gap-6 p-6 rounded-xl w-[380px] 
                   bg-[rgba(17,17,17,0.80)] shadow-[0px_36px_80px_rgba(0,0,0,0.80)] 
                   backdrop-blur-[40px]"
            >
                {/* ❌ Close Button */}
                <button
                    className="absolute top-4 right-4 text-white/60 hover:text-white text-xl font-bold"
                    onClick={() => {
                        onClose();
                        setPassword("");
                        setError("");
                    }}
                >
                    ✕
                </button>

                {/* Title */}
                <h2 className="w-full text-[#F4F4F4] text-center font-general text-[18px] font-medium leading-[25.2px]">
                    Are you sure?
                </h2>

                {/* Description */}
                <p className="w-full text-center text-[14px] font-general font-medium leading-[19.6px] text-white/40">
                    This action cannot be undone. <br />
                    You will permanently lose access to your account, transaction history, wallet details, and any saved preferences.
                    <br />
                    <br />
                    Please make sure you are certain about this action.
                </p>
                <div className='relative w-full'>
                    {/* Input */}
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            if (error) setError("");
                        }}
                        placeholder="Enter password to continue"
                        className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-[#FFFFFF14] rounded-lg 
                        bg-transparent  text-white outline-none placeholder:text-xs placeholder-[#FFFFFF4D] placeholder:py-4
                        focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general
                        ${error ? "pr-36" : "pr-20"}
                       text-ellipsis overflow-hidden
                       `}
                        autoComplete="off"
                    />
                    <div
                        className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
                        onMouseDown={(e) => e.preventDefault()}>
                        {(error) && (
                            <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                                {error}
                            </div>

                        )}
                    </div>
                </div>
                {/* Button */}
                <button
                    onClick={handleConfirm}
                    disabled={!password || error || isSubmitting || !isValidPassword}
                    className={`w-full h-8 px-3 py-2 text-sm font-medium font-general 
                     rounded-lg backdrop-blur-[40px] transition
                     ${!error && password && isValidPassword
                            ? "bg-[#E23131] text-white"
                            : "bg-white/50 cursor-not-allowed text-black"
                        } ${isSubmitting ? "cursor-wait" : ""}`}
                >
                    {isSubmitting ? (
                        <div className='flex justify-center items-center'>
                            <StageSpinner size={30} color='#000' />
                        </div>
                    ) : (
                        "Yes, Delete Account"
                    )}

                </button>
            </div>
        </div>
    );
};

export default DeleteAccountModal;
