import React, { useState } from "react";
import ActiveSection from "../ActiveSection/ActiveSection";
import { GoInfo } from "react-icons/go";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { StageSpinner } from "react-spinners-kit";
import authFetch from '../../utils/authFetch';
import { FaPaste } from "react-icons/fa6";
import useWalletAddressStore from "../../store/WalletAddressStore";
import { showToast } from "../../utils/toast";
const TransactionDetails = () => {
  const { orderCreatedResp: paymentData } = useWalletAddressStore();
  const navigate = useNavigate();
  const [pasted, setPasted] = useState(false);
  const [txHash, setTxHash] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState("");
  const handleVerifyHash = async () => {
    setIsSubmitting(true); // Disable the button during the request
    try {
      if (!txHash) {
        setError("Please provide a transaction hash");
        return;
      }
      const response = await authFetch('/api/v1/offramp/verifyTransaction', {
        method: 'POST',
        body: {
          txHash,
          reference_id: paymentData?.reference_id,
          fromAmount: paymentData?.fromAmount,
          chain: paymentData?.chain,
          fromCurrency: paymentData?.fromCurrency,
          toCurrency: paymentData?.toCurrency
        },
      });

      if (response?.code === 200) {
        navigate('/usdt-recieved');
      } else {
        showToast(response?.error || "Something went wrong while verifying the transaction! Please try again.", "error");
      }
    } catch (error) {
      console.error('Error verifying:', error);
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  }

  const handlePasteClick = () => {
    navigator.clipboard.readText().then((text) => {
      setTxHash(text);
      if (text) setError("");
      setPasted(true);
      setTimeout(() => setPasted(false), 2000); // Reset after 2 seconds
    })
      .catch(err => console.error("Failed to Paste: ", err));
  };
  return (
    <div className='flex flex-col gap-6 w-full sm:w-[320px] h-auto'>
      <ActiveSection type={"transaction"} />
      <div className='flex flex-col text-center justify-center gap-6 w-full'>
        <div className='flex flex-col items-center gap-2 w-full'>
          <h2 className='text-lg text-[#F4F4F4] font-medium font-general'>
            Provide Transaction Details
          </h2>
          <p className='font-medium font-general text-[#F4F4F466] text-sm '>
            Paste your transaction hash from the blockchain explorer to verify
            your transfer.
          </p>
          {showInstructions ? (
            <>
              <div className='bg-[#F4F4F40A] p-4  flex flex-col rounded-xl gap-4'>
                <div className='flex justify-between'>
                  <h3 className='px-[3px] text-[#A9EA2E] font-semibold text-sm font-general'>
                    Where to Find Your Transaction Hash
                  </h3>
                  <IoCloseSharp
                    onClick={() => setShowInstructions(false)}
                    className='text-lg text-white cursor-pointer'
                  />
                </div>
                <ol className='flex flex-col gap-2 font-medium leading-snug'>
                  {[
                    {
                      num: "1",
                      text: "Go to your crypto wallet (or blockchain explorer, like Tronscan for TRC20)",
                    },
                    {
                      num: "2",
                      text: "Locate your recent transaction (where you sent USDT).",
                    },
                    {
                      num: "3",
                      text: `Find the "Transaction Hash" (TxID) – a long alphanumeric code.`,
                    },
                    {
                      num: "4",
                      text: "Copy the TxID and paste it here.",
                    },
                    {
                      num: "5",
                      text: 'Tap "Verify Hash" to proceed.',
                    },
                  ].map((item, index) => (
                    <li
                      key={index}
                      className='flex text-start items-start gap-2'>
                      <span className='bg-[#A9EA2E] font-figtree font-semibold text-black rounded-full flex items-center justify-center w-5 h-5 text-[10px] flex-shrink-0'>
                        {item.num}
                      </span>
                      <span
                        className='font-general font-medium text-xs text-[#F4F4F4B3]'
                        dangerouslySetInnerHTML={{
                          __html: item.text.replace(
                            /(Go to your crypto wallet|Locate your recent transaction|Find the "Transaction Hash" \(TxID\)|Copy the TxID|Tap "Verify Hash")/g,
                            (match) =>
                              `<strong class='font-semibold text-[#FFFFFFCC]'>${match}</strong>`
                          ),
                        }}></span>
                    </li>
                  ))}
                </ol>
              </div>
            </>
          ) : (
            <>
              <GoInfo
                onClick={() => setShowInstructions(true)}
                className='text-[#F4F4F4] hover:text-[#A9EA2E] text-lg cursor-pointer'
              />
            </>
          )}
        </div>
        <div className='relative w-full'>
          <input
            type='text'
            value={txHash}
            onChange={(e) => { setError(""); setTxHash(e.target.value) }}
            className='w-full text-[#A9EA2E] h-11 sm:h-8  text-xs font-medium font-general bg-[#F4F4F40A]  rounded-md px-3 py-1 pr-8 outline-none placeholder:text-[#F4F4F44D]'
            placeholder='Transaction Hash'
          />
          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {error && (
              <div className="flex items-center text-[#FF5A5A] text-[12px] pr-4 font-medium leading-[16.8px] font-general relative z-10">
                {error}
              </div>
            )}
          </div>
          <FaPaste
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={handlePasteClick}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#F4F4F44D] cursor-pointer"
          />
          {showTooltip && (
            <span className="absolute -right-12 top-1/2 transform -translate-y-1/2 text-xs bg-gray-800 text-white px-2 py-1 rounded">
              Paste
            </span>
          )}
          {pasted && <span className="absolute right-10 top-1/2 transform -translate-y-1/2 text-sm text-[#A9EA2E]">Pasted!</span>}
        </div>

        <button
          disabled={error || isSubmitting}
          onClick={() => handleVerifyHash()} className={`flex items-center justify-center gap-2 text-center ${error ? "bg-[#F4F4F480]" : "bg-[#A9EA2E]"}  px-2 h-11 sm:h-8  text-black font-medium text-xs font-general  rounded-lg  shadow-[0px_20px_40px_0px_rgba(0,0,0,0.25)]  `}>
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <StageSpinner size={30} color="#000" />
            </div>
          ) : (
            "Verify Hash"
          )}
        </button>
      </div>
    </div>
  );
};

export default TransactionDetails;
