import React, { useState } from "react";
import { StageSpinner } from "react-spinners-kit";
import { useNavigate } from "react-router-dom";

const LetStart = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
    navigate("/sell");
  };

  return (
    <div className='flex flex-col font-general w-full sm:w-[320px] h-auto text-center'>
      <h2 className='text-2xl font-[500] leading-[33.6px] mb-2 text-[#fff]'>
        Let’s Get Started!
      </h2>
      <p className='font-[500] text-white/40 text-sm mb-6 opacity-80'>
        Buy, sell USDT securely & seamlessly.
      </p>
      <button
        className={`w-full h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center ${"bg-[#A7E92D] text-black"}`}
        onClick={handleSubmit}>
        {isSubmitting ? (
          <StageSpinner size={40} color='#000' loading={isSubmitting} />
        ) : (
          "Check Rates"
        )}
      </button>
      <button onClick={() => navigate("/welcome")} className='font-[500]  border-white/40 text-sm cursor-pointer text-[#A7E92D] mt-3'>
        Login/SignIn
      </button>
    </div>
  );
};

export default LetStart;
