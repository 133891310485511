import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import LogoSvg from "../LogoSvg/LogoSvg";

const MainLayout = () => {
  const location = useLocation();
  const hiddenNavbarRoutes = [
    "/verified-confirm",
    "/verified-check",
    "/verified-error",
    "/get-started",
    "/otp",
    "/identity",
    "/existinguser-login",
    "/user-registration",
    "/start",
    "/term-conditions",
    "/check-user",
    "/forget-password",
    "/add-bank",
    "/welcome"
  ];
  const hideFooter = [""];
  const showheader = ["/term-conditions", "/start"]
  return (
    <>
      {!hiddenNavbarRoutes.includes(location.pathname) && <Navbar />}
      {showheader.includes(location.pathname) && (
        <div className='absolute flex justify-center items-center  w-full top-0'>
          <LogoSvg />
          <h3 className=' text-[#F4F4F4] font-figtree font-semibold text-base mr-10'>
            USDT Marketplace
          </h3>
        </div>
      )}
      <Outlet />
      {!hideFooter.includes(location.pathname) && (
        <footer className='absolute bottom-8 font-[500] text-[#FFFFFF4D] text-base '>
          Powered by GSX Solutions Private Limited
        </footer>
      )}
    </>
  );
};

export default MainLayout;
