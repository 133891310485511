import { create } from 'zustand';
const useWalletAddressStore = create((set) => ({
    walletAddress: null,
    orderCreatedResp: null,
    userWalletAddress: null,
    setWalletAddress: (value) => set({ walletAddress: value }),
    setOrderCreatedResp: (value) => set({ orderCreatedResp: value }),
    setUserWalletAddress: (value) => set({ userWalletAddress: value }),
    clearWalletAddress: (value) => set({ walletAddress: null }),
    clearOrderCreatedResp: (value) => set({ orderCreatedResp: null }),
    clearUserWalletAddress: (value) => set({ userWalletAddress: null }),
    clearAll: () => set({ walletAddress: null, orderCreatedResp: null, userWalletAddress: null }),
}));

export default useWalletAddressStore;