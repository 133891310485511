import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import usePhoneStore from "../../store/addPhoneStore";
import authFetch from "../../utils/authFetch";
import { MdOutlineError } from "react-icons/md";
import LoadingScreen from "../LoadingScreen/Loading";
import useLoadingStore from "../../store/LoadingStore";
const AddPhone = () => {
  const navigate = useNavigate();
  const {
    globalPhone,
    setGlobalPhone,
    globalSelectedCountry,
    setGlobalSelectedCountry,
  } = usePhoneStore();
  const { loading, setLoading } = useLoadingStore(); 
  const [countries, setCountries] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState("");
  const validatePhoneNumber = (phone) => {
    setError("");
    if (!phone) {
      setError("Phone number cannot be empty");
      setIsFormValid(false);
      return;
    }
    const regex = /^[6-9]\d{9}$/; // Starts with 6-9, followed by 9 more digits (total 10)
    setIsFormValid(regex.test(phone));
  };
  const onSuccess = async (e) => {
    try {
      setLoading(true); 
      navigate("/otp", { state: { type: "addPhone" } }); // Navigate to OTP page
    } catch (err) {
      console.error("onSuccess", err);
    }
  };
  const getCountries = async () => {
    try {
      const response = await authFetch("/api/v1/offramp/getCountries", {
        method: "GET",
      });
      if (response.message) {
        setGlobalSelectedCountry(response.data[0]);
        setCountries(response.data);
      }
    } catch (error) {
      console.error("getCountries", error);
    }
  };

  const handleAddPhoneOTP = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    let body = {
      phone: globalSelectedCountry?.dialCode + globalPhone,
    };
    try {
      const response = await authFetch(`/api/v1/user/sendAddPhoneOtp`, {
        method: "POST",
        body: body,
      });
      if (response.code === 200) {
        onSuccess(); // Handle what happens on success, e.g., redirect or update UI
      } else {
        setError(response?.error);
      }
    } catch (error) {
      console.error("handleAddPhoneOTP", error);
    }
  };
  useEffect(() => {
    getCountries();
  }, []);
  
  useEffect(()=>{
    setTimeout(() => {
      setLoading(false); 
    }, 500);
  },[])
  
  if (loading) return <LoadingScreen />; 

  return (
    <div className='flex flex-col justify-between font-general w-full sm:w-[320px] h-auto text-center gap-6 '>
      <span className='flex flex-col gap-1'>
        <h2 className='text-2xl font-[500] leading-[33.6px] text-[#fff]'>
          Enter your Phone Number
        </h2>
        <p className='font-[500] text-white/40 text-sm opacity-80'>
          Add your phone number to make sure you never lose access to your
          account
        </p>
      </span>
      {countries.length > 0 && (
        <form onSubmit={handleAddPhoneOTP} className='flex flex-col gap-3'>
          <div className='w-full relative group'>
            <div className='absolute inset-y-0 left-0 flex items-center sm:text-[12px] text-sm border border-transparent rounded-lg  text-white outline-none  placeholder:text-xs placeholder-white/60 focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow'>
            <span className="ml-2 text-white/60 group-focus-within:text-white">
                {globalSelectedCountry?.dialCode}
              </span>
            </div>
            <input
              type='number'
              value={globalPhone}
              onChange={(e) => {
                let value = e.target.value;
                // Allow only digits (0-9), prevent invalid characters
                value = value.replace(/\D/g, ""); // Remove non-numeric characters
                // Ensure max length of 10 digits
                if (value.length > 10) {
                  return; // Stop input if length exceeds 10
                }
                setGlobalPhone(value);
                validatePhoneNumber(value);
              }}
              onKeyDown={(e) => {
                if (["e", "E", ".", "+", "-"].includes(e.key)) {
                  e.preventDefault(); // Block invalid characters
                }
              }}
              className='w-full pl-10 py-2 h-11 sm:h-8 sm:text-[12px] text-sm border border-transparent rounded-lg bg-white/10 text-white outline-none  placeholder:text-xs placeholder-white/60 focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow'
              placeholder='Enter phone number'
              required
            />
          </div>
          {/* Error Message */}
          {error && (
            <div className='flex items-center gap-2   text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general'>
              {/* Error Icon */}
              <MdOutlineError className="text-base" />
              {/* Error Message */}
              <span className='text-left whitespace-normal'>{error}</span>
            </div>
          )}
          <button
            type='submit'
            disabled={!isFormValid || error}
            className={`w-full h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center ${isFormValid
              ? "bg-[#A7E92D] text-black"
              : "bg-white/50 text-black cursor-not-allowed"
              }`}>
            Continue
          </button>
        </form>
      )}
    </div>
  );
};

export default AddPhone;
