import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSignUpStore from "../../store/SignupStore";
const TermAndCondition = () => {
  const navigate = useNavigate();
  const { globalEmail } = useSignUpStore();
  const [isChecked, setIsChecked] = useState(false);
  const onContinue = () => {
    navigate("/user-registration", { state: { type: "signup" } })
  }
  useEffect(() => {
    if (!globalEmail) {
      navigate('/'); // Change '/' to your desired base route
    }
  }, [globalEmail, navigate]);

  return (
    <div className='w-[393px] max-w-full flex flex-col  items-center text-center px-4'>
      <div className="flex flex-col gap-6">
        <p className='text-[#F4F4F4] font-medium font-general text-lg'>
          Terms and Conditions of Use
        </p>
        <div className='w-full max-h-[300px] md:max-h-[300px] lg:max-h-[360px] p-2 overflow-y-auto text-[#F4F4F4] text-xs leading-relaxed text-left font-general font-medium space-y-3 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 bg-[#F4F4F40A] rounded-xl'>
          <p>
            Please read these Terms and Conditions of Use ("Terms") carefully
            before using our services. By accessing or using our website and
            application, you agree to comply with and be bound by these Terms.
          </p>

          <p>
            <p>1. Introduction</p>
            These Terms form a legally binding agreement between [Your Company
            Name] ("we," "us," or "our") and the user ("you"). They govern your
            use of our website, mobile application, and any associated services
            (collectively, the "Services").
          </p>

          <p>
            <p>2. Subscription & Payments</p>
            Our services may include subscription-based plans that renew
            automatically unless canceled before the renewal date. Please refer to
            our Subscription Policy for more details on billing, cancellations,
            and refunds.
          </p>

          <ul className='list-disc list-inside'>
            <p>3. User Responsibilities</p>
            <li>
              You must be at least 18 years old or have parental consent to use
              our services.
            </li>
            <li>
              You agree not to misuse or exploit the platform for unlawful
              activities.
            </li>
            <li>
              Any violation of these Terms may result in suspension or termination
              of your account.
            </li>
          </ul>

          <p>
            <p>4. Privacy & Data Usage</p>
            We respect your privacy. Your personal data will be collected, stored,
            and used in accordance with our Privacy Policy.
          </p>

          <p>
            <p>5. Modifications to Terms</p>
            We may update these Terms from time to time. Continued use of our
            services after modifications implies acceptance of the revised Terms.
          </p>

          <p className='font-medium'>
            For any questions or concerns, please contact us at
            <span className='text-blue-400'> support@usdtmarketplace.com</span>.
          </p>
        </div>

        <div className='w-full px-4 mt-4'>
          <div className='flex items-center space-x-2'>
            <label className="relative w-5 h-5 border border-gray-400 rounded-sm cursor-pointer">
              <input
                type='checkbox'
                className="appearance-none peer"
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <span className="absolute inset-0 flex items-center justify-center 
            bg-transparent peer-checked:bg-[#C5FF4B] peer-checked:border-transparent 
            peer-checked:after:content-['✓'] peer-checked:after:text-black peer-checked:after:text-xs 
            peer-checked:after:font-bold rounded-sm"></span>
            </label>
            <span className='text-left text-[#F4F4F499] font-medium font-general text-xs'>

              I have read and agree to the Terms and Conditions.
            </span>
          </div>

          {/* Continue Button */}
          <button
            disabled={!isChecked}
            onClick={() => onContinue()} className={`w-full mt-4 py-2 text-black rounded-lg font-medium transition-colors ${isChecked ? "bg-[#C5FF4B]" : "bg-[#F4F4F480]"
              }`}>
            Continue
          </button>

          {/* Disclaimer */}
          <p className='mt-2 text-left text-xs text-[#A0A0A0]'>
            By continuing, you agree to our Terms and Conditions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermAndCondition;
