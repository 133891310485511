import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const HelpSupport = () => {
  const navigate = useNavigate();
  const [concern, setConcern] = useState('');
  const [message, setMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isValidData = message.length > 0 && concern.length;
  return (
    <div className="flex flex-col gap-6 w-full sm:w-[364px] h-auto ">
      <div className='flex items-center justify-center relative'>

        <FaChevronLeft onClick={() => navigate(-1)} className='text-gray-600 text-lg cursor-pointer absolute left-0' />
        <h2 className="text-lg font-medium leading-[33.6px] text-white">
          Help & Support
        </h2>
      </div>
      <div className="flex flex-col items-center w-full">

        <p className="font-medium text-white/40 text-sm opacity-80">
          How can we assist you today?
        </p>
        <form className="flex flex-col gap-4 w-full">
          <div className="w-full  mt-6 flex flex-col gap-4">
            <input
              type="text"
              id="password"
              value={concern}
              onFocus={() => setIsFocused(true)}
              onBlur={(e) => {
                // Check if blur happens inside the wrapper, prevent closing
                if (!e.currentTarget.parentNode.contains(e.relatedTarget)) {
                  setIsFocused(false);
                }
              }}
              onChange={(e) => setConcern(e.target.value)}
              className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-transparent rounded-lg 
               bg-[#FFFFFF14] text-white outline-none  placeholder:text-xs placeholder-[#FFFFFF4D] 
               focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
                text-ellipsis overflow-hidden `}
              placeholder="Enter your concern..."
              autoComplete="off"
            />
            <textarea
              placeholder="Tell us more so we can assist you better..."
              onChange={(e) => setMessage(e.target.value)}
              className="w-full h-24 py-2 px-3 bg-[#FFFFFF14] rounded-md text-white outline-none placeholder:text-sm placeholder-[#FFFFFF4D]  focus:border focus:border-[#FFFFFF33] focus:ring-0 "
            ></textarea>
            <button className={`w-full h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center 
        ${isValidData
                ? "bg-[#A7E92D] text-black"
                : "bg-white/50 text-black cursor-not-allowed"
              } ${isSubmitting ? "cursor-wait" : ""}`}>
              Submit Report
            </button>
          </div>

        </form>
        {/* Footer Section */}
        <p className="text-center text-[#FFFFFF66] text-xs mt-4">
          We respect your privacy. By continuing, you agree to our Terms and Policy.
        </p>
      </div>
    </div>
  );
};

export default HelpSupport;
