import React, { useState } from "react";
import ActiveSection from "../ActiveSection/ActiveSection";
import { GoInfo } from "react-icons/go";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { StageSpinner } from "react-spinners-kit";
import { FaPaste } from "react-icons/fa6";
import useWalletAddressStore from "../../store/WalletAddressStore";
const WalletAddress = () => {
  const { userWalletAddress, setUserWalletAddress } = useWalletAddressStore();
  const navigate = useNavigate();
  const [pasted, setPasted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState("");

  const handleAddAddress = async () => {
    if (!userWalletAddress) return setError("Invalid address");
    setIsSubmitting(true)
    setTimeout(() => {
      navigate('/review-confirm');
      setIsSubmitting(false)
    }, 1500);
  }

  const handlePasteClick = () => {
    navigator.clipboard.readText().then((text) => {
      setUserWalletAddress(text);
      if (text) setError("");
      setPasted(true);
      setTimeout(() => setPasted(false), 2000); // Reset after 2 seconds
    })
      .catch(err => console.error("Failed to Paste: ", err));
  };
  return (
    <div className='flex flex-col gap-6 w-full sm:w-[320px] h-auto'>
      <ActiveSection type={"address"} />
      <div className='flex flex-col text-center justify-center gap-6 w-full'>
        <div className='flex flex-col items-center gap-2 w-full'>
          <h2 className='text-lg text-[#F4F4F4] font-medium font-general'>
            Enter Sending Wallet Address
          </h2>
          <p className='font-medium font-general text-[#A9EA2E] text-sm '>
            Make sure to use only this wallet for this transaction.
          </p>
          {showInstructions ? (
            <>
              <div className='bg-[#F4F4F40A] p-4  flex flex-col rounded-xl gap-4'>
                <div className='flex justify-between'>
                  <h3 className='px-[3px] text-[#A9EA2E] font-semibold text-sm font-general'>
                    Where to Find Your Wallet Address
                  </h3>
                  <IoCloseSharp
                    onClick={() => setShowInstructions(false)}
                    className='text-lg text-white cursor-pointer'
                  />
                </div>
                <ol className='flex flex-col gap-2 font-medium leading-snug'>
                  {[
                    {
                      num: "1",
                      text: "Go to your crypto wallet (or exchange account, like MetaMask or Trust Wallet).",
                    },
                    {
                      num: "2",
                      text: `Locate the "Receive" section for USDT.`,
                    },
                    {
                      num: "3",
                      text: `Find your wallet address – a long alphanumeric code.`,
                    },
                    {
                      num: "4",
                      text: "Copy the address and paste it here.",
                    },
                    {
                      num: "5",
                      text: 'Tap "Confirm Wallet Address" to proceed.',
                    },
                  ].map((item, index) => (
                    <li
                      key={index}
                      className='flex text-start items-start gap-2'>
                      <span className='bg-[#A9EA2E] font-figtree font-semibold text-black rounded-full flex items-center justify-center w-5 h-5 text-[10px] flex-shrink-0'>
                        {item.num}
                      </span>
                      <span
                        className='font-general font-medium text-xs text-[#F4F4F4B3]'
                        dangerouslySetInnerHTML={{
                          __html: item.text.replace(
                            /(Go to your crypto wallet|Locate the "Receive" section|USDT|Find your wallet address|Copy the address|Tap "Confirm Wallet Address")/g,
                            (match) =>
                              `<strong class='font-semibold text-[#FFFFFFCC]'>${match}</strong>`
                          ),
                        }}></span>
                    </li>
                  ))}
                </ol>
              </div>
            </>
          ) : (
            <>
              <GoInfo
                onClick={() => setShowInstructions(true)}
                className='text-[#F4F4F4] hover:text-[#A9EA2E] text-lg cursor-pointer'
              />
            </>
          )}
        </div>
        <div className='relative w-full'>
          <input
            type='text'
            value={userWalletAddress}
            onChange={(e) => { setError(""); setUserWalletAddress(e.target.value) }}
            className='w-full text-[#F4F4F4] h-11 sm:h-8  text-xs font-medium font-general bg-[#F4F4F40A]  rounded-md px-3 py-1 pr-8 outline-none placeholder:text-[#F4F4F44D]'
            placeholder='Wallet Address'
          />
          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {error && (
              <div className="flex items-center text-[#FF5A5A] text-[12px] pr-4 font-medium leading-[16.8px] font-general relative z-10">
                {error}
              </div>
            )}
          </div>
          <FaPaste
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={handlePasteClick}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#F4F4F44D] cursor-pointer hover:text-white"
          />
          {showTooltip && (
            <span className={`absolute ${pasted ? "-right-14" : "-right-12"}  top-1/2 transform -translate-y-1/2 text-xs bg-gray-800 text-white px-2 py-1 rounded`}>
              {
                pasted ? "Pasted!" : "Paste"
              }
            </span>
          )}
          {/* {pasted && <span className="absolute right-8 top-1/2 transform -translate-y-1/2 text-sm text-[#A9EA2E]">Pasted!</span>} */}
        </div>

        <button
          disabled={error || isSubmitting}
          onClick={() => handleAddAddress()} className={`flex items-center justify-center gap-2 text-center ${error ? "bg-[#F4F4F480]" : "bg-[#A9EA2E]"}  px-2 h-11 sm:h-8  text-black font-medium text-xs font-general  rounded-lg  shadow-[0px_20px_40px_0px_rgba(0,0,0,0.25)]  `}>
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <StageSpinner size={30} color="#000" />
            </div>
          ) : (
            "Confirm Wallet Address"
          )}
        </button>
      </div>
    </div>
  );
};

export default WalletAddress;
