import { create } from 'zustand';

const UseSellSelectorStore = create((set) => ({
    globalCurrency: null,
    globalCoin: null,
    globalNetwork: null,
    globalYouSell: '',
    globalYouGet: '',
    globalQuoteData: null,
    globalSelectedBank: null,
    setGlobalCurrency: (data) => set({ globalCurrency: data }), // Function to set currency data
    setGlobalCoin: (data) => set({ globalCoin: data }), // Function to set coin data
    setGlobalNetwork: (data) => set({ globalNetwork: data }), // Function to set network data
    setGlobalYouSell: (data) => set({ globalYouSell: data }), // Function to set you send data
    setGlobalYouGet: (data) => set({ globalYouGet: data }), // Function to set you get data
    setGlobalQuoteData: (data) => set({ globalQuoteData: data }), // Function to set quote data 
    setGlobalSelectedBank: (data) => set({ globalSelectedBank: data }), // Function to set selectedBank 
    clearGlobalCurrency: () => set({ globalCurrency: null }),  // Function to clear currency 
    clearGlobalCoin: () => set({ globalCoin: null }),  // Function to clear coin 
    clearGlobalNetwork: () => set({ globalNetwork: null }),  // Function to clear network 
    clearGlobalYouSell: () => set({ globalYouSell: '' }),  // Function to clear you sell
    clearGlobalYouGet: () => set({ globalYouGet: '' }),  // Function to clear you get
    clearGlobalQuoteData: () => set({ globalQuoteData: null }),  // Function to clear quote data
    clearGlobalSelectedBank: () => set({ globalSelectedBank: null }),  // Function to clear selected bank
    clearAll: () => set({ globalCurrency: null, globalCoin: null, globalNetwork: null, globalYouSell: '', globalYouGet: '', globalQuoteData: null, globalSelectedBank: null }),  // Function to clear all
}));

export default UseSellSelectorStore;
