import React, { useEffect, useState } from "react";
import useLoadingStore from "../../store/LoadingStore";
import { useNavigate } from "react-router-dom";
import { StageSpinner } from "react-spinners-kit";
import { MdOutlineError } from "react-icons/md";
import useForgetPasswordStore from "../../store/ForgetPasswordStore";
import useProfileStore from "../../store/profileStore";
import { FaChevronLeft } from "react-icons/fa";
import LoadingScreen from "../LoadingScreen/Loading";
import authFetch from "../../utils/authFetch";
import { showToast } from "../../utils/toast";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const { loading, setLoading } = useLoadingStore();
  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
    confirm: "",
  });
  const [view, setView] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [focus, setFocus] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [hover, setHover] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseError, setResponseError] = useState("");
  const { setGlobalEmail, setGlobalNewPassword } = useForgetPasswordStore();
  const { globalProfile, setProfile } = useProfileStore();
  const isValidPassword = passwords.new.length >= 6 && passwords.new === passwords.confirm;

  const handleSubmitResetPassword = async (e) => {
    e.preventDefault();
    if (!isValidPassword && !error.password && !error.confirmPassword) return;
    setIsSubmitting(true); // Disable button when submitting
    try {
      const body = {
        oldPassword: passwords?.current,
        newPassword: passwords?.new
      };

      const response = await authFetch(`/api/v1/user/changePassword`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response?.code === 200) {
        showToast("Password has been Updated", "white");
        navigate('/profile'); // After changing password, navigate to login or wherever necessary
      } else {
        setResponseError(response?.error)
      }
      setIsSubmitting(false); // Re-enable button after submission
    } catch (error) {
      console.log("handleSubmitResetPassword", error)
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
    if (error[name]) {
      setError((prev) => ({ ...prev, [name]: "" }));
    }
    responseError && setResponseError("")
  };

  const handleToggleView = (field) => {
    const input = document.querySelector(`input[name="${field}"]`);
    if (input) {
      const cursorPosition = input.selectionStart;
      setView((prevView) => ({
        ...prevView,
        [field]: !prevView[field],
      }));
      setTimeout(() => {
        input.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  };
  const validatePassword = (type, value) => {
    let errorMessage = "";
    if (type === "confirm" && value !== passwords?.new) {
      errorMessage = "Passwords don't match";
      setError((prev) => ({ ...prev, [type]: errorMessage }));
      return;
    }
    else if (value.length < 8) errorMessage = "8 characters minimum";
    else if (type === "new" && value === passwords?.current) errorMessage = "Avoid old password";
    else if (!/[A-Z]/.test(value)) errorMessage = "Include uppercase letter";
    else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) errorMessage = "Include special character";
    else if (!/[0-9]/.test(value)) errorMessage = "Include at least one number";
    setError((prev) => ({ ...prev, [type]: errorMessage }));
    if (type === "new") setGlobalNewPassword(value);
  };

  const onForgotPassword = async () => {
    try {
      globalProfile ? setGlobalEmail(globalProfile?.email) : setProfile()
      navigate("/forget-password");
    } catch (err) {
      console.log("onForgotPassword", err);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [])

  if (loading) return <LoadingScreen />;
  return (
    <div className='flex flex-col gap-6 font-general w-full sm:w-[320px] text-center'>
      <div className='flex items-center justify-center relative mb-2'>
        <FaChevronLeft
          onClick={() => navigate(-1)}
          className='text-gray-600 text-lg cursor-pointer absolute left-0'
        />
        <h3 className='text-lg text-[#F4F4F4] font-medium font-general'>
          Reset Password
        </h3>
      </div>
      <form onSubmit={handleSubmitResetPassword} className="flex flex-col gap-3">
        <div className='relative w-full'>
          <input
            type={view.current ? "text" : "password"}
            name="current"
            value={passwords.current}
            onFocus={() => setFocus((prev) => ({ ...prev, current: true }))}
            onBlur={() => {
              validatePassword("current", passwords.current)
              setFocus((prev) => ({ ...prev, current: false }))
            }}
            onChange={handleChange}
            disabled={isSubmitting}
            className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-transparent rounded-lg 
               bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
               focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
                ${error.current ? "pr-48" : "pr-24"} text-ellipsis overflow-hidden `}
            placeholder='Current Password'
            autoComplete='off'
          />
          <div
            className='absolute right-4 top-1/2 -translate-y-1/2 flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {error?.current && passwords?.current?.length > 0 && (
              <div className='flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general'>
                {error?.current}
              </div>
            )}
            <button
              type='button'
              className='text-[12px] font-medium text-white underline underline-offset-2 decoration-white/60 whitespace-nowrap'
              onClick={() => onForgotPassword()}>
              Forgot?
            </button>
            {focus.current && passwords.current.length > 0 && (
              <button
                type='button'
                onClick={() => handleToggleView("current")}
                onMouseEnter={() => setHover({ ...hover, current: true })}
                onMouseLeave={() => setHover({ ...hover, current: false })}
              >
                {hover.current ? (
                  view.current ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M1.62373 2.12397C1.90035 1.84735 2.34884 1.84735 2.62546 2.12397L5.2017 4.70021L5.20637 4.70483L12.795 12.2934L12.7995 12.298L15.3755 14.874C15.6521 15.1506 15.6521 15.5991 15.3755 15.8757C15.0988 16.1523 14.6503 16.1523 14.3737 15.8757L12.1851 13.6871C10.354 14.708 8.30377 14.9356 6.3645 14.3479C4.25251 13.7079 2.33215 12.1208 0.970778 9.66785C0.741323 9.25441 0.73932 8.74936 0.970118 8.3334C1.70783 7.00385 2.60923 5.92866 3.61808 5.12006L1.62373 3.1257C1.3471 2.84908 1.3471 2.40059 1.62373 2.12397ZM5.66645 9C5.66645 8.47486 5.80974 7.98258 6.05883 7.56081L7.13098 8.63296C7.09974 8.74997 7.08312 8.87297 7.08312 9C7.08312 9.7824 7.71738 10.4167 8.49979 10.4167C8.62682 10.4167 8.74982 10.4 8.86682 10.3688L9.93897 11.441C9.51721 11.69 9.02493 11.8333 8.49979 11.8333C6.93498 11.8333 5.66645 10.5648 5.66645 9Z'
                        fill='white'
                      />
                      <path
                        d='M16.0275 9.6691C15.5533 10.5232 15.0111 11.2721 14.4171 11.9124L6.20581 3.70114C6.9523 3.45702 7.7226 3.33318 8.49929 3.33317C11.4004 3.33315 14.2123 5.06093 16.0275 8.3305C16.2577 8.74524 16.2577 9.25436 16.0275 9.6691Z'
                        fill='white'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.4997 3.3335C11.4008 3.33348 14.2128 5.06126 16.0279 8.33083C16.2581 8.74557 16.2581 9.25468 16.0279 9.66943C14.2128 12.939 11.4008 14.6668 8.49971 14.6668C5.59862 14.6669 2.78665 12.9391 0.971514 9.6695C0.741266 9.25476 0.741266 8.74564 0.971514 8.3309C2.78665 5.06134 5.59861 3.33352 8.4997 3.3335ZM6.02054 9.00016C6.02054 7.63096 7.1305 6.521 8.4997 6.521C9.86891 6.521 10.9789 7.63096 10.9789 9.00016C10.9789 10.3694 9.86891 11.4793 8.4997 11.4793C7.1305 11.4793 6.02054 10.3694 6.02054 9.00016Z'
                        fill='white'
                      />
                    </svg>
                  )
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='18'
                    viewBox='0 0 17 18'
                    fill='none'>
                    <g opacity='0.3'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.50019 3.33331C11.4013 3.33329 14.2132 5.06108 16.0284 8.33064C16.2586 8.74538 16.2586 9.2545 16.0284 9.66924C14.2133 12.9388 11.4013 14.6666 8.5002 14.6666C5.59911 14.6667 2.78714 12.9389 0.972002 9.66932C0.741754 9.25458 0.741755 8.74546 0.972002 8.33072C2.78714 5.06115 5.5991 3.33333 8.50019 3.33331ZM6.02103 8.99998C6.02103 7.63077 7.13099 6.52081 8.50019 6.52081C9.8694 6.52081 10.9794 7.63077 10.9794 8.99998C10.9794 10.3692 9.8694 11.4791 8.50019 11.4791C7.13099 11.4791 6.02103 10.3692 6.02103 8.99998Z'
                        fill='white'
                      />
                    </g>
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        <div className='relative w-full'>
          <input
            type={view.new ? "text" : "password"}
            name="new"
            value={passwords.new}
            onFocus={() => setFocus((prev) => ({ ...prev, new: true }))}
            onBlur={() => {
              validatePassword("new", passwords.new)
              setFocus((prev) => ({ ...prev, new: false }))
            }}
            onChange={handleChange}
            className={`w-full py-2 px-3 h-11 sm:h-8 text-sm sm:text-[12px]  font-medium leading-[16.8px] border border-transparent rounded-lg 
             bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
             focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
           ${error.new ? "pr-48" : "pr-24"} text-ellipsis overflow-hidden`}
            placeholder='New Password'
            autoComplete='off'
          />

          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {error?.new && passwords?.new?.length > 0 && (
              <div className='flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general'>
                {error?.new}
              </div>
            )}
            {focus.new && passwords.new.length > 0 && (
              <button
                type='button'
                onClick={() => handleToggleView("new")}
                onMouseEnter={() => setHover({ ...hover, new: true })}
                onMouseLeave={() => setHover({ ...hover, new: false })}
              >
                {hover.new ? (
                  view.new ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M1.62373 2.12397C1.90035 1.84735 2.34884 1.84735 2.62546 2.12397L5.2017 4.70021L5.20637 4.70483L12.795 12.2934L12.7995 12.298L15.3755 14.874C15.6521 15.1506 15.6521 15.5991 15.3755 15.8757C15.0988 16.1523 14.6503 16.1523 14.3737 15.8757L12.1851 13.6871C10.354 14.708 8.30377 14.9356 6.3645 14.3479C4.25251 13.7079 2.33215 12.1208 0.970778 9.66785C0.741323 9.25441 0.73932 8.74936 0.970118 8.3334C1.70783 7.00385 2.60923 5.92866 3.61808 5.12006L1.62373 3.1257C1.3471 2.84908 1.3471 2.40059 1.62373 2.12397ZM5.66645 9C5.66645 8.47486 5.80974 7.98258 6.05883 7.56081L7.13098 8.63296C7.09974 8.74997 7.08312 8.87297 7.08312 9C7.08312 9.7824 7.71738 10.4167 8.49979 10.4167C8.62682 10.4167 8.74982 10.4 8.86682 10.3688L9.93897 11.441C9.51721 11.69 9.02493 11.8333 8.49979 11.8333C6.93498 11.8333 5.66645 10.5648 5.66645 9Z'
                        fill='white'
                      />
                      <path
                        d='M16.0275 9.6691C15.5533 10.5232 15.0111 11.2721 14.4171 11.9124L6.20581 3.70114C6.9523 3.45702 7.7226 3.33318 8.49929 3.33317C11.4004 3.33315 14.2123 5.06093 16.0275 8.3305C16.2577 8.74524 16.2577 9.25436 16.0275 9.6691Z'
                        fill='white'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.4997 3.3335C11.4008 3.33348 14.2128 5.06126 16.0279 8.33083C16.2581 8.74557 16.2581 9.25468 16.0279 9.66943C14.2128 12.939 11.4008 14.6668 8.49971 14.6668C5.59862 14.6669 2.78665 12.9391 0.971514 9.6695C0.741266 9.25476 0.741266 8.74564 0.971514 8.3309C2.78665 5.06134 5.59861 3.33352 8.4997 3.3335ZM6.02054 9.00016C6.02054 7.63096 7.1305 6.521 8.4997 6.521C9.86891 6.521 10.9789 7.63096 10.9789 9.00016C10.9789 10.3694 9.86891 11.4793 8.4997 11.4793C7.1305 11.4793 6.02054 10.3694 6.02054 9.00016Z'
                        fill='white'
                      />
                    </svg>
                  )
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='18'
                    viewBox='0 0 17 18'
                    fill='none'>
                    <g opacity='0.3'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.50019 3.33331C11.4013 3.33329 14.2132 5.06108 16.0284 8.33064C16.2586 8.74538 16.2586 9.2545 16.0284 9.66924C14.2133 12.9388 11.4013 14.6666 8.5002 14.6666C5.59911 14.6667 2.78714 12.9389 0.972002 9.66932C0.741754 9.25458 0.741755 8.74546 0.972002 8.33072C2.78714 5.06115 5.5991 3.33333 8.50019 3.33331ZM6.02103 8.99998C6.02103 7.63077 7.13099 6.52081 8.50019 6.52081C9.8694 6.52081 10.9794 7.63077 10.9794 8.99998C10.9794 10.3692 9.8694 11.4791 8.50019 11.4791C7.13099 11.4791 6.02103 10.3692 6.02103 8.99998Z'
                        fill='white'
                      />
                    </g>
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        <div className='relative w-full'>
          <input
            type={view.confirm ? "text" : "password"}
            name="confirm"
            value={passwords.confirm}
            onFocus={() => setFocus((prev) => ({ ...prev, confirm: true }))}
            onBlur={() => {
              validatePassword("confirm", passwords.confirm)
              setFocus((prev) => ({ ...prev, confirm: false }))
            }}
            onChange={handleChange}
            disabled={isSubmitting}
            className={`w-full py-2 px-3 h-11 sm:h-8 text-sm sm:text-[12px] font-medium leading-[16.8px] border border-transparent rounded-lg 
             bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
             focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general ${error.confirm ? "pr-48" : "pr-24"} text-ellipsis overflow-hidden `}
            placeholder='Confirm Password'
            autoComplete='off'
          />


          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {error.confirm && passwords?.confirm?.length > 0 && (
              <div className='flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general'>
                {error.confirm}
              </div>
            )}
            {focus.confirm && passwords.confirm.length > 0 && (
              <button
                type='button'
                onClick={() => handleToggleView("confirm")}
                onMouseEnter={() => setHover({ ...hover, confirm: true })}
                onMouseLeave={() => setHover({ ...hover, confirm: false })}
              >
                {hover.confirm ? (
                  view.confirm ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M1.62373 2.12397C1.90035 1.84735 2.34884 1.84735 2.62546 2.12397L5.2017 4.70021L5.20637 4.70483L12.795 12.2934L12.7995 12.298L15.3755 14.874C15.6521 15.1506 15.6521 15.5991 15.3755 15.8757C15.0988 16.1523 14.6503 16.1523 14.3737 15.8757L12.1851 13.6871C10.354 14.708 8.30377 14.9356 6.3645 14.3479C4.25251 13.7079 2.33215 12.1208 0.970778 9.66785C0.741323 9.25441 0.73932 8.74936 0.970118 8.3334C1.70783 7.00385 2.60923 5.92866 3.61808 5.12006L1.62373 3.1257C1.3471 2.84908 1.3471 2.40059 1.62373 2.12397ZM5.66645 9C5.66645 8.47486 5.80974 7.98258 6.05883 7.56081L7.13098 8.63296C7.09974 8.74997 7.08312 8.87297 7.08312 9C7.08312 9.7824 7.71738 10.4167 8.49979 10.4167C8.62682 10.4167 8.74982 10.4 8.86682 10.3688L9.93897 11.441C9.51721 11.69 9.02493 11.8333 8.49979 11.8333C6.93498 11.8333 5.66645 10.5648 5.66645 9Z'
                        fill='white'
                      />
                      <path
                        d='M16.0275 9.6691C15.5533 10.5232 15.0111 11.2721 14.4171 11.9124L6.20581 3.70114C6.9523 3.45702 7.7226 3.33318 8.49929 3.33317C11.4004 3.33315 14.2123 5.06093 16.0275 8.3305C16.2577 8.74524 16.2577 9.25436 16.0275 9.6691Z'
                        fill='white'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.4997 3.3335C11.4008 3.33348 14.2128 5.06126 16.0279 8.33083C16.2581 8.74557 16.2581 9.25468 16.0279 9.66943C14.2128 12.939 11.4008 14.6668 8.49971 14.6668C5.59862 14.6669 2.78665 12.9391 0.971514 9.6695C0.741266 9.25476 0.741266 8.74564 0.971514 8.3309C2.78665 5.06134 5.59861 3.33352 8.4997 3.3335ZM6.02054 9.00016C6.02054 7.63096 7.1305 6.521 8.4997 6.521C9.86891 6.521 10.9789 7.63096 10.9789 9.00016C10.9789 10.3694 9.86891 11.4793 8.4997 11.4793C7.1305 11.4793 6.02054 10.3694 6.02054 9.00016Z'
                        fill='white'
                      />
                    </svg>
                  )
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='18'
                    viewBox='0 0 17 18'
                    fill='none'>
                    <g opacity='0.3'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.50019 3.33331C11.4013 3.33329 14.2132 5.06108 16.0284 8.33064C16.2586 8.74538 16.2586 9.2545 16.0284 9.66924C14.2133 12.9388 11.4013 14.6666 8.5002 14.6666C5.59911 14.6667 2.78714 12.9389 0.972002 9.66932C0.741754 9.25458 0.741755 8.74546 0.972002 8.33072C2.78714 5.06115 5.5991 3.33333 8.50019 3.33331ZM6.02103 8.99998C6.02103 7.63077 7.13099 6.52081 8.50019 6.52081C9.8694 6.52081 10.9794 7.63077 10.9794 8.99998C10.9794 10.3692 9.8694 11.4791 8.50019 11.4791C7.13099 11.4791 6.02103 10.3692 6.02103 8.99998Z'
                        fill='white'
                      />
                    </g>
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        {responseError && (
          <div className="flex items-center gap-2 -mt-2 text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general">
            {/* Error Icon */}
            <MdOutlineError className="text-lg" />
            {/* Error Message */}
            <span className="text-left whitespace-normal">
              {responseError}
            </span>
          </div>
        )}
        <button
          //onClick={() => handleResetSubmit()}
          disabled={
            !isValidPassword ||
            isSubmitting ||
            error.password ||
            error.confirmPassword ||
            responseError
          }
          className={`w-full h-11 sm:h-8 text-sm sm:text-[12px] font-[500]  rounded-lg transition flex justify-center items-center ${isValidPassword &&
            !error.password &&
            !error.confirmPassword &&
            !responseError
            ? "bg-[#A7E92D] text-black"
            : "bg-white/50 text-black cursor-not-allowed"
            } ${isSubmitting ? "cursor-wait" : ""}`}>
          {isSubmitting ? (
            <div className='flex justify-center items-center'>
              <StageSpinner size={30} color='#000' />
            </div>
          ) : (
            "Update Password"
          )}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
