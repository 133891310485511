import React from "react";
import { useState } from "react";
import { StageSpinner } from "react-spinners-kit";
import { IoClose } from "react-icons/io5";
import useProfileStore from "../store/profileStore";
import authFetch from "../utils/authFetch";
import { useNavigate } from "react-router-dom";

const ConfirmPasswordModal = ({ isOpen, onClose, type }) => {
    const { globalUpdateEmail, globalUpdatePhone, globalConfirmPassword, setGlobalConfirmPassword } = useProfileStore()
    const navigate = useNavigate()
    const [view, setView] = useState(false);
    const [isFocused, setIsFocused] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [error, setError] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isValidPassword = globalConfirmPassword.length >= 6;
    if (!isOpen) return null;

    // Toggle Password Visibility
    const togglePasswordVisibility = () => {
        setView((prev) => !prev);
    };

    const handleClose = () => {
        setGlobalConfirmPassword(""); // Reset password
        setError(null)
        setView(false); // Reset view state
        onClose(); // Call parent onClose function
    };

    // Close modal when clicking outside
    const handleOutsideClick = (e) => {
        if (e.target.id === "modal-overlay") {
            handleClose()
        }
    };
    const sendUpdateEmailOtp = async () => {
        try {
            if (!isValidPassword) return
            setIsSubmitting(true)
            let body = {
                new_email: globalUpdateEmail,
                password: globalConfirmPassword
            }
            const response = await authFetch(`/api/v1/user/sendUpdateEmailOtp`, {
                method: "POST",
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                navigate("/otp", { state: { field: type, type: "updateProfile" } });
                setError(null)
                setView(false); // Reset view state
                onClose(); // Call parent onClose function
            } else {
                setError(response?.error)
            }
            setIsSubmitting(false)
        } catch (error) {
            console.log("sendUpdateEmailOtp:", error);
        }
    }
    const sendUpdatePhoneOtp = async () => {
        try {
            if (!isValidPassword) return
            setIsSubmitting(true)
            let body = {
                new_phone: `+91${globalUpdatePhone}`,
                password: globalConfirmPassword
            };
            const response = await authFetch(`/api/v1/user/sendUpdatePhoneOtp`, {
                method: "POST",
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                navigate("/otp", { state: { field: type, type: "updateProfile" } });
                setError(null)
                setView(false); // Reset view state
                onClose(); // Call parent onClose function
            } else {
                setError(response?.error)
            }
            setIsSubmitting(false)
        } catch (error) {
            console.log("sendUpdatePhoneOtp:", error);
        }
    }
    return (
        <div
            id="modal-overlay"
            className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-md"
            onClick={handleOutsideClick}
        >
            {/* Modal Box */}
            <div className="relative w-[360px] p-4 flex flex-col items-start gap-6 bg-[#1A1A1A] rounded-lg shadow-lg">
                {/* Close Button */}
                <button
                    onClick={handleClose}
                    className="absolute top-4 right-4 text-white/60 hover:text-white transition"
                >
                    <IoClose size={20} />
                </button>
                {/* Title */}
                <h2 className="w-full text-[#F4F4F4] text-center font-general text-[18px] font-medium leading-[25.2px]">
                    Confirm Your Password
                </h2>

                {/* Description */}
                <p className="w-full text-center text-[14px] font-general font-medium text-white/40 leading-[19.6px]">
                    Please enter your password to change your email address.
                </p>
                <div className="relative w-full">
                    <input
                        type={view ? "text" : "password"}
                        id="password"
                        value={globalConfirmPassword}
                        onFocus={() => setIsFocused(true)}
                        onBlur={(e) => {
                            // Check if blur happens inside the wrapper, prevent closing
                            if (!e.currentTarget.parentNode.contains(e.relatedTarget)) {
                                setIsFocused(false);
                            }
                        }}
                        onChange={(e) => {
                            error && setError(null)
                            setGlobalConfirmPassword(e.target.value)
                        }}
                        placeholder="Enter Password"
                        className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-transparent rounded-lg 
                        bg-white/10 text-white outline-none  placeholder:text-xs placeholder-white/60 
                        focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general 
                        pr-24 text-ellipsis overflow-hidden `}
                        required
                        autoComplete="off" // ✅ Disables autocomplete
                        autoCorrect="off" // ✅ Helps disable autofill
                        spellCheck="false" // ✅ Ensures no spell suggestions
                    />

                    {/* Wrapper for Forgot Password & Eye Icon */}
                    <div className="absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2" onMouseDown={(e) => e.preventDefault()}>
                        {(error) && (
                            <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                                {error}
                            </div>
                        )}
                        {/* Eye Icon for Show/Hide Password */}
                        {
                            isFocused && (
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    onMouseEnter={() => setIsHovered(true)} // Show highlighted eye
                                    onMouseLeave={() => setIsHovered(false)} // Return to normal eye
                                >
                                    {
                                        isHovered ? (
                                            view ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.62373 2.12397C1.90035 1.84735 2.34884 1.84735 2.62546 2.12397L5.2017 4.70021L5.20637 4.70483L12.795 12.2934L12.7995 12.298L15.3755 14.874C15.6521 15.1506 15.6521 15.5991 15.3755 15.8757C15.0988 16.1523 14.6503 16.1523 14.3737 15.8757L12.1851 13.6871C10.354 14.708 8.30377 14.9356 6.3645 14.3479C4.25251 13.7079 2.33215 12.1208 0.970778 9.66785C0.741323 9.25441 0.73932 8.74936 0.970118 8.3334C1.70783 7.00385 2.60923 5.92866 3.61808 5.12006L1.62373 3.1257C1.3471 2.84908 1.3471 2.40059 1.62373 2.12397ZM5.66645 9C5.66645 8.47486 5.80974 7.98258 6.05883 7.56081L7.13098 8.63296C7.09974 8.74997 7.08312 8.87297 7.08312 9C7.08312 9.7824 7.71738 10.4167 8.49979 10.4167C8.62682 10.4167 8.74982 10.4 8.86682 10.3688L9.93897 11.441C9.51721 11.69 9.02493 11.8333 8.49979 11.8333C6.93498 11.8333 5.66645 10.5648 5.66645 9Z" fill="white" />
                                                    <path d="M16.0275 9.6691C15.5533 10.5232 15.0111 11.2721 14.4171 11.9124L6.20581 3.70114C6.9523 3.45702 7.7226 3.33318 8.49929 3.33317C11.4004 3.33315 14.2123 5.06093 16.0275 8.3305C16.2577 8.74524 16.2577 9.25436 16.0275 9.6691Z" fill="white" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4997 3.3335C11.4008 3.33348 14.2128 5.06126 16.0279 8.33083C16.2581 8.74557 16.2581 9.25468 16.0279 9.66943C14.2128 12.939 11.4008 14.6668 8.49971 14.6668C5.59862 14.6669 2.78665 12.9391 0.971514 9.6695C0.741266 9.25476 0.741266 8.74564 0.971514 8.3309C2.78665 5.06134 5.59861 3.33352 8.4997 3.3335ZM6.02054 9.00016C6.02054 7.63096 7.1305 6.521 8.4997 6.521C9.86891 6.521 10.9789 7.63096 10.9789 9.00016C10.9789 10.3694 9.86891 11.4793 8.4997 11.4793C7.1305 11.4793 6.02054 10.3694 6.02054 9.00016Z" fill="white" />
                                                </svg>
                                            )

                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                                <g opacity="0.3">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M8.50019 3.33331C11.4013 3.33329 14.2132 5.06108 16.0284 8.33064C16.2586 8.74538 16.2586 9.2545 16.0284 9.66924C14.2133 12.9388 11.4013 14.6666 8.5002 14.6666C5.59911 14.6667 2.78714 12.9389 0.972002 9.66932C0.741754 9.25458 0.741755 8.74546 0.972002 8.33072C2.78714 5.06115 5.5991 3.33333 8.50019 3.33331ZM6.02103 8.99998C6.02103 7.63077 7.13099 6.52081 8.50019 6.52081C9.8694 6.52081 10.9794 7.63077 10.9794 8.99998C10.9794 10.3692 9.8694 11.4791 8.50019 11.4791C7.13099 11.4791 6.02103 10.3692 6.02103 8.99998Z"
                                                        fill="white" />
                                                </g>
                                            </svg>
                                        )
                                    }

                                </button>
                            )
                        }
                    </div>
                </div>
                {/* Password Input Field */}
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        type === "email" ? sendUpdateEmailOtp() : type === "phone" && sendUpdatePhoneOtp()
                    }}
                    disabled={!isValidPassword || isSubmitting || error}
                    className={`w-full h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center ${isValidPassword && !error
                        ? "bg-[#A7E92D] text-black"
                        : "bg-white/50 text-black cursor-not-allowed"
                        } ${isSubmitting ? "cursor-wait" : ""}`}
                >
                    {isSubmitting ? (
                        <div className="flex justify-center items-center">
                            <StageSpinner size={30} color="#000" />
                        </div>
                    ) : (
                        "Confirm"
                    )}
                </button>
            </div>

        </div>


    );
};

export default ConfirmPasswordModal;
