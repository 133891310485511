import React, { useEffect, useState } from 'react'
import SignInPassword from './SignInPassword';
import { useLocation } from 'react-router-dom';
import AddAadhaar from '../AddAadhaar/AddAadhaar';
import useSignUpStore from '../../store/SignupStore';
import { useNavigate } from 'react-router-dom';
import AddPhone from '../AddPhone/AddPhone';
import AddBankAccount from '../AddBankAccount/AddBankAccount';
const steps = ["Signup", "Add Phone", "Add Aadhaar", "Bank Details"];

const UserRegistration = () => {
  const { globalEmail } = useSignUpStore()
  const navigate = useNavigate()
  const location = useLocation()
  let type = location.state?.type;
  const initialStep = type === "signup" ? 0 : type === "addPhone" ? 1 : type === "addAadhaar" ? 2 : 3;
  const [currentStep, setCurrentStep] = useState(initialStep); // Redirect to base route if globalEmail is empty
  useEffect(() => {
    if (type === "signup" && !globalEmail) {
      navigate('/'); // Change '/' to your desired base route
    }
  }, [globalEmail, type, navigate]);

  useEffect(() => {
    setCurrentStep(initialStep); // Update step when type changes
  }, [type]);

  return (
    <div  className={`flex flex-col items-center justify-center text-center font-general w-full`}>
      <div className="flex space-x-4 h-5 ">
        {steps.map((step, index) => (
          <div key={index} className="flex flex-col items-center">
            <div
              className={`h-2 rounded-full transition-all duration-300 ${index === currentStep
                ? "w-10 bg-white"
                : "w-4 bg-gray-600"
                }`}
            ></div>
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-center items-center flex-1 h-full w-full">
        {type === "signup" ? <SignInPassword /> : type === "addPhone" ? <AddPhone /> : type === "addAadhaar" ? <AddAadhaar /> : <AddBankAccount type={type} />}
      </div>
    </div>
  )
}

export default UserRegistration;