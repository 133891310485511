import React, { useEffect, useState, useRef } from "react";
import ActiveSection from "../ActiveSection/ActiveSection";
import { GoArrowRight, GoInfo } from "react-icons/go";
import { IoCloseSharp } from "react-icons/io5";
import { FaCopy, FaWallet } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { StageSpinner } from "react-spinners-kit";
import UseSellSelectorStore from "../../store/SellSelector";
import authFetch from "../../utils/authFetch";
import useWalletAddressStore from "../../store/WalletAddressStore";
import useTransactionStatusStore from "../../store/txStatusStore";
// import useSessionStore from "../../store/sessionStore";
const Deposit = () => {
  const navigate = useNavigate();
  // const hasNavigatedForward = useRef(false);
  const { globalYouSell, globalCoin } = UseSellSelectorStore()
  const { setTxStatus, setStatus } = useTransactionStatusStore()
  const [copied, setCopied] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { walletAddress, orderCreatedResp } = useWalletAddressStore();
  const [showTooltip, setShowTooltip] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch(err => console.error("Failed to copy: ", err));
  };

  const getTxStatus = async () => {
    setIsSubmitting(true)
    try {
      let body = {
        reference_id: orderCreatedResp?.reference_id
      };

      const response = await authFetch(`/api/v1/offramp/transactionStatus`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response?.code === 200) {
        const responseData = response?.data
        setTimeout(() => {
          setTxStatus(responseData?.txStatus)
          setStatus(responseData?.status)
          // ✅ Mark session complete before navigating
          // useSessionStore.getState().markSessionCompleted(orderCreatedResp?.reference_id);
          // hasNavigatedForward.current = true;
          navigate('/usdt-recieved');
          setIsSubmitting(false)
        }, 1500);
      } else {
        navigate("/selling-history")
      }
    } catch (error) {
      console.error('getTxStatus:', error);
    }
  }
  useEffect(() => {
    if (!walletAddress) {
      navigate('/sell')
    }
  }, []);

  // useEffect(() => {
  //   const handleUnload = () => {
  //     const refId = orderCreatedResp?.reference_id;
  //     const isCompleted = useSessionStore.getState().isSessionCompleted(refId);

  //     if (!hasNavigatedForward.current && !isCompleted) {
  //       quitSession();
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleUnload);

  //     const refId = orderCreatedResp?.reference_id;
  //     const isCompleted = useSessionStore.getState().isSessionCompleted(refId);

  //     if (!hasNavigatedForward.current && !isCompleted) {
  //       quitSession(); // normal quit via authFetch
  //     }
  //   };
  // }, []);
  // const quitSession = async () => {
  //   try {
  //     let body = {
  //       reference_id: orderCreatedResp?.reference_id,
  //       depositAddress: walletAddress
  //     };

  //     const response = await authFetch(`/api/v1/offramp/quitSession`, {
  //       method: 'POST',
  //       body: body,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     });
  //   } catch (error) {
  //     console.error('quitSession:', error);
  //   }
  // }
  return (
    <div className='flex flex-col gap-6 w-full sm:w-[320px] h-auto'>
      <ActiveSection type={"deposite"} />
      <div className='flex flex-col text-center justify-center gap-6'>
        <div className='flex flex-col items-center gap-2'>
          <h2 className='text-lg text-[#F4F4F4] font-medium font-general'>
            Deposit USDT to Wallet
          </h2>
          <p className='font-medium  text-[#F4F4F466] text-sm '>
            To complete the transaction, send {globalYouSell} {globalCoin?.coin?.toUpperCase()} to the wallet address
            below.
          </p>
          <p className='font-medium  text-[#F4F4F466] text-sm '>
            Make sure to complete the transaction within 10 minutes.
          </p>
          {showInstructions ? (
            <>
              <div className=' w-full sm:w-[320px] bg-[#F4F4F40A] p-4  flex flex-col rounded-xl gap-4'>
                <div className='flex justify-between'>
                  <h3 className='px-[3px] text-[#A9EA2E] font-semibold text-sm font-general'>
                    How To Deposit USDT?
                  </h3>
                  <IoCloseSharp
                    onClick={() => setShowInstructions(false)}
                    className='text-lg text-white cursor-pointer'
                  />
                </div>
                <ol className='flex flex-col gap-2 font-medium leading-snug'>
                  {[
                    { num: "1", text: "Copy the wallet address." },
                    {
                      num: "2",
                      text: 'Open your crypto wallet and paste it in the "Recipient" field.',
                    },
                    {
                      num: "3",
                      text: "Enter the amount & select TRC20 network.",
                    },
                    {
                      num: "4",
                      text: "Confirm the transaction and send USDT.",
                    },
                    {
                      num: "5",
                      text: 'Tap "I Have Transferred" after completing the transaction.',
                    },
                    {
                      num: "6",
                      text: 'Make sure to complete the transaction within 10 minutes.',
                    },
                  ].map((item, index) => (
                    <li
                      key={index}
                      className='flex text-start items-start gap-2'>
                      <span className='bg-[#A9EA2E] font-figtree font-semibold text-black rounded-full flex items-center justify-center w-5 h-5 text-[10px] flex-shrink-0'>
                        {item.num}
                      </span>
                      <span className='font-general font-medium text-xs text-[#F4F4F4B3] '>
                        {item.text.split(" ").map((word, i) =>
                          [
                            "Copy",
                            "Open",
                            "TRC20",
                            "Confirm",
                            'Tap "I Have Transferred"',
                            "Note"
                          ].includes(word) ? (
                            <strong
                              key={i}
                              className='font-semibold text-[#FFFFFFCC]'>
                              {word}{" "}
                            </strong>
                          ) : (
                            word + " "
                          )
                        )}
                      </span>
                    </li>
                  ))}
                </ol>
              </div>
            </>
          ) : (
            <>
              <GoInfo
                onClick={() => setShowInstructions(true)}
                className='text-[#F4F4F4]  hover:text-[#A9EA2E] text-lg cursor-pointer'
              />
            </>
          )}
        </div>

        <div className='flex items-center gap-2'>
          <FaWallet className='text-[#A9EA2E]' />
          <span className='font-medium font-general text-[#F4F4F4CC]'>
            {" "}
            Wallet Address
          </span>
        </div>

        <div className='relative w-full'>
          <input
            type='text'
            value={walletAddress}
            className='w-full text-[#A9EA2E] h-11 sm:h-8 text-xs font-medium font-general bg-[#F4F4F40A]  rounded-md px-3 py-1 pr-8 outline-none'
            placeholder='Enter text'
          />
          <FaCopy
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={() => handleCopy()} className='absolute right-2 top-1/2 transform -translate-y-1/2 text-[#F4F4F44D] hover:text-white cursor-pointer' />
          {showTooltip && (
            <span className={`absolute ${copied ? "-right-16" : "-right-12"} top-1/2 transform -translate-y-1/2 text-xs bg-gray-800 text-white px-2 py-1 rounded`}>
              {
                copied ? "Copied!" : "Copy"
              }

            </span>
          )}
          {/* {copied && <span className="absolute right-10 top-1/2 transform -translate-y-1/2 text-sm text-[#A9EA2E]">Copied!</span>} */}
        </div>

        <button onClick={() => getTxStatus()} className='flex items-center justify-center gap-2 text-center  bg-[#A9EA2E] px-2  h-11 sm:h-8 text-black font-medium text-xs font-general  rounded-lg  shadow-[0px_20px_40px_0px_rgba(0,0,0,0.25)]  '>
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <StageSpinner size={30} color="#000" />
            </div>
          ) : (
            <span className="flex items-center gap-2">
              I Have Transferred <GoArrowRight className="text-lg" />
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default Deposit;
