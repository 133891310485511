import React from 'react'

const LogoSvg = ({onClick}) => {
  return (
    <svg
    className='cursor-pointer'
    onClick={onClick}
    width='90'
    height='110'
    viewBox='0 0 110 110'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_f_1106_15049)'>
      <path
        d='M48.0001 41L42.0001 47C51.042 53.9783 56.6395 54.2264 66.0001 47L60.0001 41L54.0001 47L48.0001 41Z'
        fill='url(#paint0_linear_1106_15049)'
      />
      <path
        d='M49.6065 68.9131L58.0914 68.9936C56.7399 57.6523 52.9966 53.4831 41.2827 51.8627L41.2021 60.3476L49.687 60.4282L49.6065 68.9131Z'
        fill='url(#paint1_linear_1106_15049)'
      />
      <path
        d='M66.5001 60.5H58.5001C59.624 55.4032 61.4014 53.635 66.5001 52.5V60.5Z'
        fill='url(#paint2_linear_1106_15049)'
      />
      <path
        d='M48.0001 41L42.0001 47C51.042 53.9783 56.6395 54.2264 66.0001 47L60.0001 41L54.0001 47L48.0001 41Z'
        stroke='url(#paint3_linear_1106_15049)'
      />
      <path
        d='M49.6065 68.9131L58.0914 68.9936C56.7399 57.6523 52.9966 53.4831 41.2827 51.8627L41.2021 60.3476L49.687 60.4282L49.6065 68.9131Z'
        stroke='url(#paint4_linear_1106_15049)'
      />
      <path
        d='M66.5001 60.5H58.5001C59.624 55.4032 61.4014 53.635 66.5001 52.5V60.5Z'
        stroke='url(#paint5_linear_1106_15049)'
      />
    </g>
    <path
      d='M48.0001 41L42.0001 47C51.042 53.9783 56.6395 54.2264 66.0001 47L60.0001 41L54.0001 47L48.0001 41Z'
      fill='url(#paint6_linear_1106_15049)'
    />
    <path
      d='M49.6065 68.9131L58.0914 68.9936C56.7399 57.6523 52.9966 53.4831 41.2827 51.8627L41.2021 60.3476L49.687 60.4282L49.6065 68.9131Z'
      fill='url(#paint7_linear_1106_15049)'
    />
    <path
      d='M66.5001 60.5H58.5001C59.624 55.4032 61.4014 53.635 66.5001 52.5V60.5Z'
      fill='url(#paint8_linear_1106_15049)'
    />
    <defs>
      <filter
        id='filter0_f_1106_15049'
        x='0.69751'
        y='0.292908'
        width='106.302'
        height='109.206'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'>
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='BackgroundImageFix'
          result='shape'
        />
        <feGaussianBlur
          stdDeviation='20'
          result='effect1_foregroundBlur_1106_15049'
        />
      </filter>
      <linearGradient
        id='paint0_linear_1106_15049'
        x1='41'
        y1='41'
        x2='67'
        y2='69'
        gradientUnits='userSpaceOnUse'>
        <stop stop-color='#A9EA2E' />
        <stop offset='1' stop-color='#5CD61B' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1106_15049'
        x1='41'
        y1='41'
        x2='67'
        y2='69'
        gradientUnits='userSpaceOnUse'>
        <stop stop-color='#A9EA2E' />
        <stop offset='1' stop-color='#5CD61B' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_1106_15049'
        x1='41'
        y1='41'
        x2='67'
        y2='69'
        gradientUnits='userSpaceOnUse'>
        <stop stop-color='#A9EA2E' />
        <stop offset='1' stop-color='#5CD61B' />
      </linearGradient>
      <linearGradient
        id='paint7_linear_1106_15049'
        x1='41'
        y1='41'
        x2='67'
        y2='69'
        gradientUnits='userSpaceOnUse'>
        <stop stop-color='#A9EA2E' />
        <stop offset='1' stop-color='#5CD61B' />
      </linearGradient>
      <linearGradient
        id='paint8_linear_1106_15049'
        x1='41'
        y1='41'
        x2='67'
        y2='69'
        gradientUnits='userSpaceOnUse'>
        <stop stop-color='#A9EA2E' />
        <stop offset='1' stop-color='#5CD61B' />
      </linearGradient>
    </defs>
  </svg>
  )
}

export default LogoSvg