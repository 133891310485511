import React, { useState, useRef, useEffect } from "react";
import {
  IoPersonOutline,
  IoLogOutOutline,
  IoChevronDown,
  IoChevronForward,
  IoLogInOutline,
} from "react-icons/io5";
import { FaRegClock } from "react-icons/fa6";
import { RiBankLine, RiCustomerService2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { MdEmail, MdPhone, MdModeEdit, MdKeyboardDoubleArrowRight } from "react-icons/md";
import authFetch from "../../utils/authFetch";
import useProfileStore from "../../store/profileStore";
import {
  FaAngleDown,
  FaAngleUp,
  FaClock,
  FaSignInAlt,
  FaSignOutAlt,
  FaUniversity,
  FaUser,
} from "react-icons/fa";
import { convertToIST } from "../../utils/dateTimeFormat";
import useRetryTransactionStore from "../../store/retryTransactionStore";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { FaRegHourglassHalf } from "react-icons/fa6";
import { MdAdd } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import LogoSvg from "../LogoSvg/LogoSvg";
import CheckOnboard from "../../utils/checkOnBoardNew";
const Navbar = () => {
  const [sellHistoryTx, setSellHistoryTx] = useState([]);
  const [openTransaction, setOpenTransaction] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);
  const [isProfileHovered, setIsProfileHovered] = useState(false);
  const [isHistoryHovered, setIsHistoryHovered] = useState(false);
  const [isBankHovered, setIsBankHovered] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
  const [isBankExpanded, setIsBankExpanded] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [isKycCompleted, setIsKycCompleted] = useState(false);
  const { setRetryTransaction } = useRetryTransactionStore();
  const [showCheckOnboard, setShowCheckOnboard] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { setProfile, globalProfile } = useProfileStore();
  const userToken = localStorage.getItem("userToken");
  const [smallScreen, setSmallScreen] = useState(false);
  // ✅ Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsHistoryExpanded(false);
        setIsProfileExpanded(false);
        setIsBankExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const fetchBankAccounts = async () => {
    try {
      const response = await authFetch("/api/v1/offramp/getAllFiatAccount", {
        method: "GET",
      });
      if (response?.code === 200) {
        setBankAccounts(response?.data); // Update state with fetched bank accounts
      }
    } catch (error) {
      console.error("Error fetching bank accounts:", error);
    }
  };

  useEffect(() => {
    setProfile();
    checkKyc();
    getSellHistory();
    fetchBankAccounts();
  }, []);
  const checkKyc = async () => {
    try {
      const response = await authFetch(`/api/v1/user/checkPhoneBank`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.code === 200) {
        const isPhoneAdded = response.data?.isPhoneAdded;
        const isBankAdded = response.data?.isBankAdded;
        const isKycCompleted = response.data?.userKycData?.isKycCompleted;

        if (isPhoneAdded && isBankAdded && isKycCompleted) {
          setIsKycCompleted(true);
        }
      }
    } catch (error) {
      console.error("Error fetching checkKyc:", error);
    }
  };
  const toggleDropdown = (transactionId) => {
    navigate("/selling-history", {
      state: { transactionId, type: "transactionItem" },
    });
  };
  const getStatusIcon = (txStatus) => {
    if (txStatus === "SUCCESS") {
      return (
        <div className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-green-500'>
          <IoMdCheckmark className='text-white text-lg' />
        </div>
      );
    } else if (txStatus === "PENDING") {
      return (
        <div className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-yellow-500'>
          <FaRegHourglassHalf className='text-white text-sm' />
        </div>
      );
    } else if (txStatus === "FAILED") {
      return (
        <div className='w-[22px] h-[22px] flex items-center justify-center rounded-full bg-red-500'>
          <RxCross2 className='text-white text-lg' />
        </div>
      );
    }
  };
  const getSellHistory = async () => {
    try {
      const response = await authFetch("/api/v1/offramp/getAllTransactions", {
        method: "GET",
      });

      if (response?.code === 200) {
        setSellHistoryTx(response?.data);
      }
    } catch (error) {
      console.error("Error fetching getSellHistory:", error);
    }
  };
  const toggleExpand = (element) => {
    if (element === "profile") {
      setIsBankExpanded(false);
      setIsHistoryExpanded(false);
      setIsProfileExpanded((prev) => !prev);
    } else if (element === "bank") {
      setIsProfileExpanded(false);
      setIsHistoryExpanded(false);
      setIsBankExpanded((prev) => !prev);
    } else if (element === "history") {
      setIsProfileExpanded(false);
      setIsBankExpanded(false);
      setIsHistoryExpanded((prev) => !prev);
    }
  };
  const VerifyNow = () => {
    setShowCheckOnboard(true)
  }
  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth < 768) {
        setSmallScreen(true); // For small screens
      } else {
        setSmallScreen(false); // For desktop
      }
    };

    updateOffset(); // Initial check
    window.addEventListener("resize", updateOffset);

    return () => window.removeEventListener("resize", updateOffset);
  }, []);

  return (
    <>
      <div className='flex items-center fixed left-0 justify-between w-screen pr-10 sm:top-0 sm:left-0 sm:ml-0 sm:mr-0  sm:bg-transparent sm:rounded-none sm:h-auto  bg-[#F4F4F414] rounded-[50px] h-16 top-[20px] z-50'>
        <LogoSvg onClick={() => navigate("/sell")} />
        <div
          className='cursor-pointer transition-all'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsOpen(!isOpen)}>
          {smallScreen && isOpen ? (
            <IoCloseSharp
              size={32}
              className='drop-shadow-[0px_0px_20px_rgba(255,255,255,0.8)] text-white'
              onClick={() => setIsOpen(false)}
            />
          ) : isHovered ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
              className='drop-shadow-[0px_0px_20px_rgba(255,255,255,0.8)]'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M2.6665 8.00002C2.6665 7.26364 3.26346 6.66669 3.99984 6.66669H27.9998C28.7362 6.66669 29.3332 7.26364 29.3332 8.00002C29.3332 8.7364 28.7362 9.33335 27.9998 9.33335H3.99984C3.26346 9.33335 2.6665 8.7364 2.6665 8.00002ZM2.6665 16C2.6665 15.2636 3.26346 14.6667 3.99984 14.6667H27.9998C28.7362 14.6667 29.3332 15.2636 29.3332 16C29.3332 16.7364 28.7362 17.3334 27.9998 17.3334H3.99984C3.26346 17.3334 2.6665 16.7364 2.6665 16ZM2.6665 24C2.6665 23.2636 3.26346 22.6667 3.99984 22.6667H14.6665C15.4029 22.6667 15.9998 23.2636 15.9998 24C15.9998 24.7364 15.4029 25.3334 14.6665 25.3334H3.99984C3.26346 25.3334 2.6665 24.7364 2.6665 24Z'
                fill='#F4F4F4'
              />
            </svg>
          ) : (
            <svg
              onClick={() => setIsOpen(!isOpen)}
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'>
              <g opacity='0.5'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M2.66675 8.00002C2.66675 7.26364 3.2637 6.66669 4.00008 6.66669H28.0001C28.7365 6.66669 29.3334 7.26364 29.3334 8.00002C29.3334 8.7364 28.7365 9.33335 28.0001 9.33335H4.00008C3.2637 9.33335 2.66675 8.7364 2.66675 8.00002ZM2.66675 16C2.66675 15.2636 3.2637 14.6667 4.00008 14.6667H28.0001C28.7365 14.6667 29.3334 15.2636 29.3334 16C29.3334 16.7364 28.7365 17.3334 28.0001 17.3334H4.00008C3.2637 17.3334 2.66675 16.7364 2.66675 16ZM2.66675 24C2.66675 23.2636 3.2637 22.6667 4.00008 22.6667H14.6667C15.4031 22.6667 16.0001 23.2636 16.0001 24C16.0001 24.7364 15.4031 25.3334 14.6667 25.3334H4.00008C3.2637 25.3334 2.66675 24.7364 2.66675 24Z'
                  fill='#F4F4F4'
                />
              </g>
            </svg>
          )}
        </div>
        {/* ✅ Sidebar Menu */}
        {!smallScreen && isOpen && (
          <div
            ref={menuRef}
            className='absolute top-10 right-10 w-[300px] p-1.5 flex flex-col items-start gap-3
          rounded-2xl bg-[#1A1A1A] 
          bg-gradient-to-br from-black/0 via-black/20 to-[#1A1A1A]
          shadow-[0px_4px_16px_rgba(46,46,46,0.10),_0px_8px_32px_rgba(32,32,32,0.05)]'>
            {/* ✅ Verification Section */}
            {!isKycCompleted && !userToken && (
              <div className='flex items-start h-[99px] gap-2 p-3  rounded-[10px] bg-[rgba(255,255,255,0.04)] w-full'>
                {/* SVG Verified Icon */}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M8.66477 2.27538C9.36993 1.46379 10.6301 1.46379 11.3352 2.27538L12.0877 3.14139C12.2994 3.38502 12.6217 3.50233 12.9404 3.45176L14.0735 3.27202C15.1354 3.10358 16.1007 3.91358 16.1192 4.98857L16.1389 6.13563C16.1445 6.45832 16.316 6.75536 16.5927 6.92152L17.5762 7.51215C18.4979 8.06567 18.7167 9.30666 18.0399 10.142L17.3177 11.0334C17.1145 11.2842 17.055 11.622 17.1601 11.9271L17.5339 13.0118C17.8842 14.0282 17.2541 15.1196 16.1986 15.3245L15.0724 15.5431C14.7556 15.6046 14.4929 15.8251 14.3773 16.1264L13.9664 17.1975C13.5814 18.2014 12.3972 18.6323 11.457 18.1109L10.4537 17.5544C10.1715 17.3979 9.8285 17.3979 9.54626 17.5544L8.54301 18.1109C7.60279 18.6323 6.41865 18.2014 6.03359 17.1975L5.62272 16.1264C5.50713 15.8251 5.24438 15.6046 4.92756 15.5431L3.80135 15.3245C2.74591 15.1196 2.11584 14.0282 2.46612 13.0118L2.83988 11.9271C2.94503 11.622 2.88547 11.2842 2.6823 11.0334L1.96011 10.142C1.28329 9.30666 1.50212 8.06567 2.42383 7.51215L3.40734 6.92152C3.68403 6.75536 3.85552 6.45832 3.86108 6.13563L3.88082 4.98856C3.89933 3.91358 4.86465 3.10358 5.92652 3.27202L7.05958 3.45176C7.37834 3.50233 7.70064 3.38502 7.91232 3.14139L8.66477 2.27538ZM12.3779 9.25298C12.5407 9.09026 12.5407 8.82645 12.3779 8.66373C12.2152 8.50101 11.9514 8.50101 11.7887 8.66373L9.16665 11.2858L8.21127 10.3304C8.04856 10.1677 7.78474 10.1677 7.62202 10.3304C7.4593 10.4931 7.4593 10.7569 7.62202 10.9196L8.87202 12.1697C8.95016 12.2478 9.05614 12.2917 9.16665 12.2917C9.27716 12.2917 9.38314 12.2478 9.46128 12.1697L12.3779 9.25298Z'
                    fill='#A9EA2E'
                  />
                </svg>
                <div>
                  <p className='text-[#A7EA2E] font-semibold font-general text-sm'>
                    You're almost there!
                  </p>
                  <p className='text-[#F4F4F480] font-general font-medium text-xs'>
                    Verify your identity to start your crypto journey with us...
                  </p>
                  <button onClick={() => VerifyNow()} className='text-[#A7EA2E] font-medium text-sm underline mt-2'>
                    Verify Now
                  </button>
                </div>
              </div>
            )}

            <div className='gap-1 w-full'>
              {userToken && (
                <>
                  {/* ✅ Profile Section (With Dummy Profile Picture) */}
                  <div
                    onMouseEnter={() => setIsProfileHovered(true)}
                    onMouseLeave={() => setIsProfileHovered(false)}>
                    {!isProfileExpanded && (
                      <div
                        className='group flex justify-between items-center p-3 gap-3 w-full cursor-pointer border-2 border-transparent hover:bg-[#FFFFFF05] rounded-xl hover:border-gray-700'
                        onClick={() => {
                          toggleExpand("profile");
                        }}>
                        <div className='flex items-center w-full text-sm gap-2 text-[#F4F4F480] font-medium font-general group-hover:text-[#F4F4F4]'>
                          <IoPersonOutline className='group-hover:text-[#F4F4F4]' />
                          Profile
                        </div>
                        {/* ✅ Show the Chevron only when hovered */}
                        {isProfileHovered &&
                          (isProfileExpanded ? (
                            <IoChevronDown className='text-[#F4F4F480] group-hover:text-[#F4F4F4]' />
                          ) : (
                            <IoChevronForward className='text-[#F4F4F480] group-hover:text-[#F4F4F4]' />
                          ))}
                      </div>
                    )}

                    {isProfileExpanded && (
                      <div className='text-white px-4 py-3 bg-[rgba(255,255,255,0.04)] rounded-lg flex flex-col items-center relative w-full gap-4'>
                        {/* ✅ Close Button (Inside Profile Section) */}
                        <div className='w-full flex justify-between'>

                          <div className='w-16 h-16 flex items-center justify-center rounded-lg text-white text-4xl font-semibold border border-gray-600 font-general overflow-hidden bg-gradient-to-b from-green-300 to-[#A9EA2E]'>
                            {globalProfile?.profile_image_url ? (
                              <img
                                src={globalProfile.profile_image_url}
                                alt="Profile"
                                className="w-full h-full object-cover rounded-lg"
                              />
                            ) : (
                              globalProfile?.email?.charAt(0).toUpperCase()
                            )}
                          </div>

                          <button
                            className='flex text-gray-400 hover:text-[#F4F4F4]'
                            onClick={() => setIsProfileExpanded(false)}>
                            ✕
                          </button>
                        </div>

                        <div className='flex self-start flex-col gap-2'>
                          {globalProfile && (
                            <p className='flex items-center gap-2 w-full text-sm text-white font-medium font-general'>
                              <MdEmail className='text-[#A9EA2E] text-lg' />
                              <span>{globalProfile?.email}</span>
                            </p>
                          )}

                          {globalProfile && (
                            <p className='flex items-center gap-2 w-full text-sm text-white font-medium font-general'>
                              <MdPhone className='text-[#A9EA2E] text-lg' />
                              {globalProfile?.phone ?
                              <span>{globalProfile?.phone}</span>:
                              <span className="text-[#F4F4F480]">{"Please add your phone"}</span>
                            }
                            </p>
                          )}
                        </div>

                        <button onClick={() => navigate("/profile")} className='w-full flex items-center justify-center gap-1 p-2 py-1 text-white/80 bg-[rgba(255,255,255,0.04)] rounded-md mt-2 font-general text-sm font-medium'>
                          <MdModeEdit /> Edit
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className='w-full'>
                {userToken && (
                  <>
                    <div
                      onMouseEnter={() => setIsBankHovered(true)}
                      onMouseLeave={() => setIsBankHovered(false)}>
                      {/* Bank Accounts Header */}
                      <div
                        className='group flex justify-between items-center p-3 gap-3 w-full cursor-pointer 
               border-2 border-transparent hover:bg-[#FFFFFF05] rounded-xl hover:border-gray-700'
                        onClick={() => {
                          toggleExpand("bank");
                        }}>
                        <div
                          className={`flex items-center w-full text-sm gap-2 ${isBankExpanded
                            ? "text-[#A9EA2E]"
                            : "text-[#F4F4F480]"
                            }  font-medium font-general ${!isBankExpanded && "group-hover:text-[#F4F4F4]"
                            }`}>
                          <RiBankLine />
                          Bank Accounts
                        </div>
                        {isBankExpanded && (
                          <button
                            className='flex text-[#F4F4F4]'
                            onClick={() => setIsBankExpanded((prev) => !prev)}>
                            <IoCloseSharp
                              size={20}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents the event from bubbling to the button
                                setIsBankExpanded(false);
                              }}
                            />
                          </button>
                        )}
                        {isBankHovered && !isBankExpanded && (
                          <IoChevronForward className='text-[#F4F4F480] group-hover:text-[#F4F4F4]' />
                        )}
                      </div>

                      {/* Expanded Section */}
                      {isBankExpanded && (
                        <div className='text-white px-2 py-1 gap-5 rounded-lg flex flex-col w-full relative'>
                          {/* Bank List */}
                          <div className='flex flex-col gap-4 mt-1 '>
                            {bankAccounts && bankAccounts.length > 0 ? (
                              bankAccounts?.slice(0, 2)?.map((bank, index) => (
                                <div
                                  key={index}
                                  className={`w-full  h-auto  p-4 rounded-lg flex flex-col justify-between bg-[#F4F4F40A]`}>
                                  {/* Header Section */}
                                  <div className='flex justify-between items-center mb-2'>
                                    <p className='uppercase text-white font-medium'>
                                      {bank?.shortName}
                                    </p>
                                    <div className='w-6 h-6 flex items-center justify-center bg-[#1A1A1A] rounded-full'>
                                      <img
                                        src={bank?.imageUrl}
                                        alt=''
                                        className='w-[22px] h-[22px] rounded-full object-cover'
                                      />
                                    </div>
                                  </div>
                                  <div className='space-y-1 text-[#F4F4F480] text-sm'>
                                    <div className='flex justify-between'>
                                      <p>Account Number</p>
                                      <p className='text-[#F4F4F480]'>
                                        {bank?.fiatAccount}
                                      </p>
                                    </div>
                                    <div className='flex justify-between'>
                                      <p>Account Name</p>
                                      <p className='text-[#F4F4F480]'>
                                        {bank?.account_name}
                                      </p>
                                    </div>
                                    <div className='flex justify-between'>
                                      <p>IFSC Code</p>
                                      <p className='text-[#F4F4F480]'>
                                        {bank?.ifsc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p className='text-base font-medium font-general text-center text-[#F4F4F480]'>
                                No bank accounts found.
                              </p>
                            )}
                          </div>

                          {/* ✅ "See More" Button */}
                          <button
                            className='w-full text-center p-2 text-[#A9EA2E] font-medium text-sm rounded-lg bg-[#FFFFFF0A] hover:bg-[#FFFFFF14] flex items-center justify-center gap-2'
                            onClick={() => {
                              navigate("/add-bank");
                              setIsOpen(false);
                            }}>
                            <MdAdd /> Add New Bank Account
                          </button>
                        </div>
                      )}
                    </div>

                    {/* ✅ History (Expandable) */}
                    <div
                      onMouseEnter={() => setIsHistoryHovered(true)}
                      onMouseLeave={() => setIsHistoryHovered(false)}>
                      {/* History Toggle Button */}
                      <div
                        className='group flex justify-between items-center p-3 gap-3 w-full cursor-pointer border-2 border-transparent hover:bg-[#FFFFFF05] rounded-xl hover:border-gray-700'
                        onClick={() => {
                          toggleExpand("history");
                        }}>
                        <div
                          className={`flex items-center w-full text-sm gap-2  ${isHistoryExpanded
                            ? "text-[#A9EA2E]"
                            : "text-[#F4F4F480]"
                            } font-medium font-general ${!isHistoryExpanded && "group-hover:text-[#F4F4F4]"
                            }`}>
                          <FaRegClock />
                          History
                        </div>
                        {isHistoryExpanded && (
                          <button
                            className='flex text-[#F4F4F4]'
                            onClick={() => setIsHistoryExpanded(false)}>
                            <IoCloseSharp
                              size={20}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsHistoryExpanded(false);
                              }}
                            />
                          </button>
                        )}
                        {isHistoryHovered && !isHistoryExpanded && (
                          <IoChevronForward className='text-[#F4F4F480] group-hover:text-[#F4F4F4]' />
                        )}
                      </div>

                      {/* Expanded History Section */}
                      {isHistoryExpanded && (
                        <div className='flex flex-col gap-3 h-full overflow-y-auto overscroll-y-contain no-scrollbar'>
                          {sellHistoryTx &&
                            sellHistoryTx
                              .slice(0, 3)
                              .map((transaction, index) => (
                                <div
                                  key={index}
                                  className='flex flex-col w-full p-3 bg-[#1A1A1A] rounded-lg'>
                                  {/* ✅ Status and Dropdown */}
                                  <div className='flex justify-between items-center '>
                                    <div className='flex text-[#F4F4F480] gap-2 items-center'>
                                      {getStatusIcon(transaction?.status)}
                                      <span className='text-xs font-general font-medium'>
                                        {transaction?.status
                                          ? transaction.status
                                            .charAt(0)
                                            .toUpperCase() +
                                          transaction.status
                                            .slice(1)
                                            .toLowerCase()
                                          : ""}
                                      </span>
                                    </div>
                                    <button
                                      onClick={() =>
                                        toggleDropdown(
                                          transaction.transaction_id
                                        )
                                      }>
                                      {openTransaction ===
                                        transaction.transaction_id ? (
                                        <FaAngleUp className='text-[#F4F4F480]' />
                                      ) : (
                                        <FaAngleDown className='text-[#F4F4F480]' />
                                      )}
                                    </button>
                                  </div>
                                  {/* ✅ Transaction ID (Always Visible) */}
                                  <p className='text-base text-[#F4F4F4] mt-1 font-general font-medium text-ellipsis overflow-hidden whitespace-nowrap'>
                                    {transaction?.transaction_id.length > 24
                                      ? `${transaction.transaction_id.slice(
                                        0,
                                        24
                                      )}...`
                                      : transaction.transaction_id}
                                  </p>

                                  {/* ✅ Date and Retry Button */}
                                  <div className='flex justify-between items-center mt-1'>
                                    <span className='text-[#F4F4F480] font-general font-medium text-xs'>
                                      {convertToIST(transaction?.date).date}{" "}
                                      {convertToIST(transaction?.date).time}
                                    </span>
                                    {transaction?.status === "FAILED" && (
                                      <button
                                        className='text-[12px] font-[500] leading-[140%] tracking-[0%] underline font-general text-[#F4F4F4]'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setRetryTransaction(transaction);
                                        }}>
                                        Retry
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}

                          {/* ✅ "See More" Button */}
                          <button
                            className='w-full text-center p-2 text-[#A9EA2E] font-medium text-sm rounded-lg bg-[#FFFFFF0A] hover:bg-[#FFFFFF14] flex items-center justify-center gap-2'
                            onClick={() => {
                              navigate("/selling-history");
                            }}>
                            See More{" "}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='14'
                              height='15'
                              viewBox='0 0 14 15'
                              fill='none'>
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M3.77365 4.85666C3.9445 4.6858 4.22151 4.6858 4.39237 4.85666L6.10698 6.57127C6.61954 7.08383 6.61955 7.91486 6.10698 8.42743L4.39237 10.142C4.22151 10.3129 3.9445 10.3129 3.77365 10.142C3.60279 9.97119 3.60279 9.69418 3.77365 9.52332L5.48826 7.80871C5.65912 7.63785 5.65912 7.36084 5.48826 7.18999L3.77365 5.47537C3.60279 5.30452 3.60279 5.02751 3.77365 4.85666ZM7.85698 4.85666C8.02784 4.6858 8.30485 4.6858 8.4757 4.85666L10.1903 6.57127C10.7029 7.08383 10.7029 7.91486 10.1903 8.42743L8.4757 10.142C8.30485 10.3129 8.02784 10.3129 7.85698 10.142C7.68613 9.97119 7.68613 9.69418 7.85698 9.52332L9.5716 7.80871C9.74245 7.63785 9.74245 7.36084 9.5716 7.18999L7.85698 5.47537C7.68613 5.30452 7.68613 5.02751 7.85698 4.85666Z'
                                fill='#A9EA2E'
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div
                  className='flex items-center gap-3 p-3 w-full cursor-pointer border-2 border-transparent
               hover:bg-[#FFFFFF05] hover:border-gray-700 rounded-xl hover:text-white
              text-white/60 font-medium text-sm leading-[19.6px] font-general'
                  onClick={() => navigate("/help-support")}>
                  <RiCustomerService2Line /> Help & Support
                </div>
                {userToken ? (
                  <div
                    className='flex items-center gap-3 p-3 w-full cursor-pointer border-2 border-transparent
               hover:bg-[#FFFFFF05] rounded-xl hover:text-white hover:border-gray-700
              text-white/60 font-medium text-sm leading-[19.6px] font-general'
                    onClick={() => navigate("/logout")}>
                    <IoLogOutOutline /> Logout
                  </div>
                ) : (
                  <div
                    className='flex items-center gap-3 p-3 w-full cursor-pointer border-2 border-transparent
               hover:bg-[#FFFFFF05] hover:border-gray-700 rounded-xl hover:text-white
              text-white/60 font-medium text-sm leading-[19.6px] font-general'
                    onClick={() => navigate("/welcome")}>
                    <IoLogInOutline /> Login
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {smallScreen && isOpen && (
        <div className='fixed top-20 left-0 w-full h-[90vh] overflow-hidden bg-black text-white p-4 shadow-lg z-40'>
          {/* Navigation Items */}
          {!isKycCompleted && !userToken && (
            <div className='flex items-start h-[99px] gap-2 p-3  rounded-[10px] bg-[rgba(255,255,255,0.04)] w-full'>
              {/* SVG Verified Icon */}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M8.66477 2.27538C9.36993 1.46379 10.6301 1.46379 11.3352 2.27538L12.0877 3.14139C12.2994 3.38502 12.6217 3.50233 12.9404 3.45176L14.0735 3.27202C15.1354 3.10358 16.1007 3.91358 16.1192 4.98857L16.1389 6.13563C16.1445 6.45832 16.316 6.75536 16.5927 6.92152L17.5762 7.51215C18.4979 8.06567 18.7167 9.30666 18.0399 10.142L17.3177 11.0334C17.1145 11.2842 17.055 11.622 17.1601 11.9271L17.5339 13.0118C17.8842 14.0282 17.2541 15.1196 16.1986 15.3245L15.0724 15.5431C14.7556 15.6046 14.4929 15.8251 14.3773 16.1264L13.9664 17.1975C13.5814 18.2014 12.3972 18.6323 11.457 18.1109L10.4537 17.5544C10.1715 17.3979 9.8285 17.3979 9.54626 17.5544L8.54301 18.1109C7.60279 18.6323 6.41865 18.2014 6.03359 17.1975L5.62272 16.1264C5.50713 15.8251 5.24438 15.6046 4.92756 15.5431L3.80135 15.3245C2.74591 15.1196 2.11584 14.0282 2.46612 13.0118L2.83988 11.9271C2.94503 11.622 2.88547 11.2842 2.6823 11.0334L1.96011 10.142C1.28329 9.30666 1.50212 8.06567 2.42383 7.51215L3.40734 6.92152C3.68403 6.75536 3.85552 6.45832 3.86108 6.13563L3.88082 4.98856C3.89933 3.91358 4.86465 3.10358 5.92652 3.27202L7.05958 3.45176C7.37834 3.50233 7.70064 3.38502 7.91232 3.14139L8.66477 2.27538ZM12.3779 9.25298C12.5407 9.09026 12.5407 8.82645 12.3779 8.66373C12.2152 8.50101 11.9514 8.50101 11.7887 8.66373L9.16665 11.2858L8.21127 10.3304C8.04856 10.1677 7.78474 10.1677 7.62202 10.3304C7.4593 10.4931 7.4593 10.7569 7.62202 10.9196L8.87202 12.1697C8.95016 12.2478 9.05614 12.2917 9.16665 12.2917C9.27716 12.2917 9.38314 12.2478 9.46128 12.1697L12.3779 9.25298Z'
                  fill='#A9EA2E'
                />
              </svg>
              <div>
                <p className='text-[#A7EA2E] font-semibold font-general text-sm'>
                  You're almost there!
                </p>
                <p className='text-[#F4F4F480] font-general font-medium text-xs'>
                  Verify your identity to start your crypto journey with us...
                </p>
                <button onClick={() => VerifyNow()} className='text-[#A7EA2E] font-medium text-sm underline mt-2'>
                  Verify Now
                </button>
              </div>
            </div>
          )}
          <ul className='flex flex-col gap-3'>
            {userToken && (
              <>
                {/* ✅ Profile Section (With Dummy Profile Picture) */}
                <div
                  onMouseEnter={() => setIsProfileHovered(true)}
                  onMouseLeave={() => setIsProfileHovered(false)}>
                  {!isProfileExpanded && (
                    <div
                      className='group flex justify-between items-center gap-3 w-full cursor-pointer border-2 border-transparent hover:bg-[#FFFFFF05] rounded-xl hover:border-gray-700'
                      onClick={() => {
                        toggleExpand("profile");
                      }}>
                      <div className='flex py-2 px-6 items-center w-full text-base gap-4 text-white font-medium font-general group-hover:text-[#F4F4F4]'>
                        <FaUser className='text-[#A9EA2E]' />
                        Profile
                      </div>
                      {/* ✅ Show the Chevron only when hovered */}
                      {isProfileHovered &&
                        (isProfileExpanded ? (
                          <IoChevronDown className='text-[#F4F4F480] group-hover:text-[#F4F4F4]' />
                        ) : (
                          <IoChevronForward className='text-[#F4F4F480] group-hover:text-[#F4F4F4]' />
                        ))}
                    </div>
                  )}

                  {isProfileExpanded && (
                    <div className='text-white px-4 py-3 bg-[rgba(255,255,255,0.04)] rounded-lg flex flex-col items-center relative w-full gap-4'>
                      {/* ✅ Close Button (Inside Profile Section) */}
                      <div className='w-full flex justify-between'>
                        <div className='w-16 h-16 flex items-center justify-center bg-gradient-to-b from-green-300 to-[#A9EA2E] rounded-lg text-white text-4xl font-semibold border border-gray-600 font-general'>
                          {globalProfile?.email?.charAt(0).toUpperCase()}
                        </div>
                        <button
                          className='flex text-gray-400 hover:text-[#F4F4F4]'
                          onClick={() => setIsProfileExpanded(false)}>
                          ✕
                        </button>
                      </div>

                      <div className='flex flex-col gap-3'>
                        {globalProfile && (
                          <p className='flex items-center gap-2 w-full text-sm text-white font-medium font-general'>
                            <MdEmail className='text-[#A9EA2E] text-lg' />
                            <span>{globalProfile?.email}</span>
                          </p>
                        )}

                        {globalProfile && (
                          <p className='flex items-center gap-2 w-full text-sm text-white font-medium font-general'>
                            <MdPhone className='text-[#A9EA2E] text-lg' />
                            {globalProfile?.phone ?
                              <span>{globalProfile?.phone}</span>:
                              <span className="text-[#F4F4F480]">{"Please add your phone"}</span>
                            }
                          </p>
                        )}
                      </div>

                      <button onClick={() => {
                          navigate("/profile")
                         setIsOpen(false);
                      }} className='w-full flex items-center justify-center gap-1 p-2 py-1 text-white/80 bg-[rgba(255,255,255,0.04)] rounded-md mt-2 font-general text-sm font-medium'>
                        <MdModeEdit /> Edit
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
            {userToken && (
              <>
                <div
                  onMouseEnter={() => setIsBankHovered(true)}
                  onMouseLeave={() => setIsBankHovered(false)}>
                  {/* Bank Accounts Header */}
                  <div
                    className='group flex justify-between items-center gap-3 w-full cursor-pointer 
               border-2 border-transparent hover:bg-[#FFFFFF05] rounded-xl hover:border-gray-700'
                    onClick={() => {
                      toggleExpand("bank");
                    }}>
                    <div
                      className={`flex py-2 px-6 items-center w-full text-base text-white gap-4  group-hover:text-[#F4F4F4]  font-medium font-general ${!isBankExpanded && "group-hover:text-[#F4F4F4]"
                        }`}>
                      <FaUniversity className='text-[#A9EA2E]' />
                      Bank Accounts
                    </div>
                    {isBankExpanded && (
                      <button
                        className='flex text-[#F4F4F4] pr-2'
                        onClick={() => setIsBankExpanded((prev) => !prev)}>
                        <IoCloseSharp
                          size={20}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents the event from bubbling to the button
                            setIsBankExpanded(false);
                          }}
                        />
                      </button>
                    )}
                    {isBankHovered && !isBankExpanded && (
                      <IoChevronForward className='text-[#F4F4F480] group-hover:text-[#F4F4F4]' />
                    )}
                  </div>

                  {/* Expanded Section */}
                  {isBankExpanded && (
                    <div className='text-white px-2 py-1 gap-5 rounded-lg flex flex-col w-full relative'>
                      {/* Bank List */}
                      <div className='flex flex-col gap-4 mt-2 '>
                        {bankAccounts && bankAccounts.length > 0 ? (
                          bankAccounts?.slice(0, 2)?.map((bank, index) => (
                            <div
                              key={index}
                              className={`w-full  h-auto  p-4 rounded-lg flex flex-col justify-between bg-[#F4F4F40A]`}>
                              {/* Header Section */}
                              <div className='flex justify-between items-center mb-2'>
                                <p className='uppercase text-white font-medium'>
                                  {bank?.shortName}
                                </p>
                                <div className='w-6 h-6 flex items-center justify-center bg-[#1A1A1A] rounded-full'>
                                  <img
                                    src={bank?.imageUrl}
                                    alt=''
                                    className='w-[22px] h-[22px] rounded-full object-cover'
                                  />
                                </div>
                              </div>
                              <div className='space-y-1 text-[#F4F4F480] text-sm'>
                                <div className='flex justify-between'>
                                  <p>Account Number</p>
                                  <p className='text-[#F4F4F480]'>
                                    {bank?.fiatAccount}
                                  </p>
                                </div>
                                <div className='flex justify-between'>
                                  <p>Account Name</p>
                                  <p className='text-[#F4F4F480]'>
                                    {bank?.account_name}
                                  </p>
                                </div>
                                <div className='flex justify-between'>
                                  <p>IFSC Code</p>
                                  <p className='text-[#F4F4F480]'>
                                    {bank?.ifsc}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className='text-base font-medium font-general text-center text-[#F4F4F480]'>
                            No bank accounts found.
                          </p>
                        )}
                      </div>

                      {/* ✅ "See More" Button */}
                      <button
                        className='w-full text-center p-2 text-[#A9EA2E] font-medium text-sm rounded-lg bg-[#FFFFFF0A] hover:bg-[#FFFFFF14] flex items-center justify-center gap-2'
                        onClick={() => {
                          navigate("/add-bank");
                          setIsOpen(false);
                        }}>
                        <MdAdd /> Add New Bank Account
                      </button>
                    </div>
                  )}
                </div>

                {/* ✅ History (Expandable) */}
                <div
                  onMouseEnter={() => setIsHistoryHovered(true)}
                  onMouseLeave={() => setIsHistoryHovered(false)}>
                  {/* History Toggle Button */}
                  <div
                    className='group flex justify-between items-center gap-3 w-full cursor-pointer border-2 border-transparent hover:bg-[#FFFFFF05] rounded-xl hover:border-gray-700'
                    onClick={() => {
                      toggleExpand("history");
                    }}>
                    <div
                      className={`flex py-2 px-6 items-center w-full text-base gap-4 font-medium font-general ${!isHistoryExpanded && "group-hover:text-[#F4F4F4]"
                        }`}>
                      <FaClock className='text-[#A9EA2E]' />
                      History
                    </div>
                    {isHistoryExpanded && (
                      <button
                        className='flex text-[#F4F4F4] pr-2'
                        onClick={() => setIsHistoryExpanded(false)}>
                        <IoCloseSharp
                          size={20}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents the event from bubbling to the button
                            setIsHistoryExpanded(false);
                          }}
                        />
                      </button>
                    )}
                    {isHistoryHovered && !isHistoryExpanded && (
                      <IoChevronForward className='text-[#F4F4F480] group-hover:text-[#F4F4F4]' />
                    )}
                  </div>

                  {/* Expanded History Section */}
                  {isHistoryExpanded && (
                    <div className='flex flex-col gap-3 h-full overflow-y-auto overscroll-y-contain no-scrollbar'>
                      {sellHistoryTx &&
                        sellHistoryTx.slice(0, 3).map((transaction, index) => (
                          <div
                            key={index}
                            className='flex flex-col w-full p-3 bg-[#1A1A1A] rounded-lg'>
                            {/* ✅ Status and Dropdown */}
                            <div className='flex justify-between items-center '>
                              <div className='flex text-[#F4F4F480] gap-2 items-center'>
                                {getStatusIcon(transaction?.status)}
                                <span className='text-xs font-general font-medium'>
                                  {transaction?.status
                                    ? transaction.status
                                      .charAt(0)
                                      .toUpperCase() +
                                    transaction.status.slice(1).toLowerCase()
                                    : ""}
                                </span>
                              </div>
                              <button
                                onClick={() =>
                                  toggleDropdown(transaction.transaction_id)
                                }>
                                {openTransaction ===
                                  transaction.transaction_id ? (
                                  <FaAngleUp className='text-[#F4F4F480]' />
                                ) : (
                                  <FaAngleDown className='text-[#F4F4F480]' />
                                )}
                              </button>
                            </div>
                            {/* ✅ Transaction ID (Always Visible) */}
                            <p className='text-base text-[#F4F4F4] mt-1 font-general font-medium text-ellipsis overflow-hidden whitespace-nowrap'>
                              {transaction?.transaction_id.length > 24
                                ? `${transaction.transaction_id.slice(
                                  0,
                                  24
                                )}...`
                                : transaction.transaction_id}
                            </p>

                            {/* ✅ Date and Retry Button */}
                            <div className='flex justify-between items-center mt-1'>
                              <span className='text-[#F4F4F480] font-general font-medium text-xs'>
                                {convertToIST(transaction?.date).date}{" "}
                                {convertToIST(transaction?.date).time}
                              </span>
                              {transaction?.status === "FAILED" && (
                                <button
                                  className='text-[12px] font-[500] leading-[140%] tracking-[0%] underline font-general text-[#F4F4F4]'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setRetryTransaction(transaction);
                                  }}>
                                  Retry
                                </button>
                              )}
                            </div>
                          </div>
                        ))}

                      {/* ✅ "See More" Button */}
                      {/*className='w-full flex items-center justify-center gap-1 p-2 py-1 text-white/80 bg-[rgba(255,255,255,0.04)] rounded-md mt-2 font-general text-sm font-medium'*/}
                      <button
                        className='w-full flex items-center justify-center gap-2 p-2 text-center  text-[#A9EA2E] font-medium text-base rounded-lg bg-[rgba(255,255,255,0.06)] hover:bg-[#FFFFFF14] font-general mt-1 '
                        onClick={() => {
                          navigate("/selling-history");
                          setIsOpen(false);
                        }}>
                        See More{" "}
                        <MdKeyboardDoubleArrowRight className="text-2xl" />
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
            <div
              className='flex items-center  py-2 px-6 gap-4 w-full cursor-pointer border-2 border-transparent
               hover:bg-[#FFFFFF05] hover:border-gray-700 rounded-xl text-white
               font-medium leading-[19.6px] text-base'
              onClick={() => {
                navigate("/help-support");
                setIsOpen(false);
              }}>
              <RiCustomerService2Line className='text-[#A9EA2E] text-lg' /> Help & Support
            </div>
            {userToken ? (
              <div
                className='flex items-center gap-4 py-2 px-6 w-full cursor-pointer border-2 border-transparent
               hover:bg-[#FFFFFF05] rounded-xl  hover:border-gray-700
              text-white font-medium text-base leading-[19.6px] font-general'
                onClick={() => {
                  setIsOpen(false);
                  navigate("/logout")
                }}>
                <FaSignOutAlt className='text-[#A9EA2E]' /> Logout
              </div>
            ) : (
              <div
                className='flex items-center gap-4 py-2 px-6 w-full cursor-pointer border-2 border-transparent
               hover:bg-[#FFFFFF05] hover:border-gray-700 rounded-xl text-white
               font-medium text-base leading-[19.6px] font-general'
                onClick={() => {
                  setIsOpen(false);
                  navigate("/welcome")
                }}>
                <FaSignInAlt className='text-[#A9EA2E]' />  Login
              </div>
            )}
          </ul>
        </div>
      )}
      {showCheckOnboard && <CheckOnboard type={"navbar"} />}
    </>
  );
};

export default Navbar;
