import { create } from 'zustand';
const useTransactionStatusStore = create((set) => ({
    txStatus: "", // crypto deposit status
    status: "", // payout processed status

    setTxStatus: (value) => set({ txStatus: value }),
    setStatus: (value) => set({ status: value }),
    clearTxStatus: () => set({ txStatus: "" }),
    clearStatus: () => set({ status: "" }),
    clearAll: () => set({ txStatus: "", status: "" }),
}));

export default useTransactionStatusStore;