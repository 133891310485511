import { create } from 'zustand';
import authFetch from '../utils/authFetch';
const useProfileStore = create((set) => ({
    globalProfile: null,      // Initial profile state
    globalUpdateEmail: "",
    globalUpdatePhone: "",
    globalConfirmPassword: "",
    setGlobalProfile: (data) => set({ globalProfile: data }), // Function to set profile data
    setGlobalUpdateEmail: (data) => set({ globalUpdateEmail: data }), // Function to set profile data
    setGlobalUpdatePhone: (data) => set({ globalUpdatePhone: data }), // Function to set profile data
    setGlobalConfirmPassword: (data) => set({ globalConfirmPassword: data }), // Function to set profile data

    clearGlobalProfile: () => set({ globalProfile: null }),  // Function to clear profile data (e.g., on logout)
    clearGlobalUpdateEmail: () => set({ globalUpdateEmail: "" }),  // Function to clear profile data (e.g., on logout)
    clearGlobalUpdatePhone: () => set({ globalUpdatePhone: "" }),  // Function to clear profile data (e.g., on logout)
    clearGlobalConfirmPassword: () => set({ globalConfirmPassword: "" }),  // Function to clear profile data (e.g., on logout)

    setProfile: async () => {
        try {
            const response = await authFetch('/api/v1/user/profile', {
                method: 'GET',
            });
            if (response) {
                set({ globalProfile: response.data })
            } else {
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    }
}));

export default useProfileStore;
