import React, { useEffect, useState } from "react";
import useSignUpStore from "../../store/SignupStore";
import { StageSpinner } from "react-spinners-kit";
import { useLocation, useNavigate } from "react-router-dom";
const ConfirmationCheck = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { response, type } = location.state;
  useEffect(() => {
    setTimeout(() => {
      if (response?.code === 200) {
        navigate("/verified-confirm", { state: { type } });
      } else {
        navigate("/verified-error", { state: { responseError: response?.error, type } });
      }
    }, 2000);
  }, [response]);
  // Redirect to base route if globalEmail is empty
  return (
    <div className='font-general w-[320px] h-[138px] text-center -mt-10'>
      <h2 className='text-2xl font-[500] leading-[33.6px] mb-2 text-[#fff]'>
        We're Verifying Your <br /> Bank Details...
      </h2>
      <p className='font-[500] text-[#FFFFFF66] text-sm opacity-80'>
        Sit back and relax while we verify your bank <br /> details. This may take a
        few moments.
      </p>
      <div className="flex justify-center items-center">
        <StageSpinner size={35} color="#fff" />
      </div>
    </div>
  );
};

export default ConfirmationCheck;
