import React, { useState, useEffect } from "react";
import authFetch from "../../utils/authFetch";
import CheckOnboard from "../../utils/checkOnBoard";
import { FaAngleDown } from "react-icons/fa6";
import { StageSpinner } from "react-spinners-kit";
import { useNavigate, useLocation } from "react-router-dom";
import useLoadingStore from "../../store/LoadingStore";
import LoadingScreen from "../LoadingScreen/Loading";
import {FaChevronLeft} from "react-icons/fa";
// Function to validate the account number
const validateBankAccount = (accountNumber) => {
  const regex = /^\d{9,18}$/;
  return regex.test(accountNumber);
};
const validateIfsc = (ifsc) => {
  const regex = /^[A-Z0-9]{11}$/;
  return regex.test(ifsc);
};
const validateAccountName = (name) => {
  const regex = /^[A-Za-z\s]{4,30}$/; // Only letters & spaces, 4 to 30 characters long
  return regex.test(name);
};

const AddBankAccount = ({ type }) => {
  const navigate = useNavigate()
  const location = useLocation()
  type = location?.state?.type
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCheckOnboard, setShowCheckOnboard] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { loading, setLoading } = useLoadingStore();
  const [error, setError] = useState({
    accountNumber: "",
    confirmAccountNumber: "",
    accountName: "",
    ifsc: "",
  });
  // Handle form validation and state
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    // Restrict accountNumber and confirmAccountNumber to numeric values only and max 18 digits
    if (name === "accountNumber" || name === "confirmAccountNumber") {
      value = value.replace(/\D/g, ""); // Remove non-numeric characters

      if (value.length > 18) {
        return; // Prevent entering more than 18 digits
      }
    }
    // Restrict IFSC Code to exactly 11 characters and allow only alphanumeric input
    if (name === "ifscCode") {
      value = value.toUpperCase(); // Convert to uppercase for consistency
      value = value.replace(/[^A-Z0-9]/g, ""); // Allow only alphanumeric characters

      if (value.length > 11) {
        return; // Prevent entering more than 11 characters
      }
    }

    // Restrict Account Name: Only letters (A-Z, a-z) and spaces, min 3, max 30 characters
    if (name === "accountName") {
      value = value.replace(/[^A-Za-z\s]/g, ""); // Remove digits & special characters

      // Prevent setting the state if the length is below 3
      if (value.length > 30) {
        return; // Prevent entering more than 30 characters
      }
    }

    // Set the respective state values
    switch (name) {
      case "accountNumber":
        setAccountNumber(value);
        break;
      case "confirmAccountNumber":
        setConfirmAccountNumber(value);
        break;
      case "ifscCode":
        setIfscCode(value);
        break;
      case "bankName":
        setBankName(value);
        break;
      case "accountName":
        setAccountName(value);
        break;
      default:
        break;
    }
  };
  const getBankNames = async () => {
    try {
      const response = await authFetch("/api/v1/user/bankList", {
        method: "GET",
      });
      if (response.code === 200) {
        setBankList(response?.data);
      }
    } catch (error) {
      console.error("getBanks", error);
    }
  };
  // Check form validation whenever inputs change
  const checkFormValidation = () => {
    if (
      validateBankAccount(accountNumber) && // Ensure account number is valid
      validateBankAccount(confirmAccountNumber) && // Ensure confirm account number is valid
      accountNumber === confirmAccountNumber && // Ensure both account numbers match
      validateIfsc(ifscCode) &&
      bankName &&
      validateAccountName(accountName)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };
  useEffect(() => {
    getBankNames();
  }, []);
  // Call validation function whenever account numbers, IFSC, or bank name change
  useEffect(() => {
    checkFormValidation();
  }, [accountNumber, confirmAccountNumber, ifscCode, bankName, accountName]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // ✅ Show Loader

    try {
      const response = await authFetch("/api/v1/offramp/addFiatAccountOfframp", {
        method: "POST",
        body: {
          bankName: bankName,
          fiatAccount: accountNumber,
          ifsc: ifscCode,
          accountName,
        },
      });

      // ✅ Ensure loader stays for at least 2 seconds before navigating
      setTimeout(() => {
        if (response) {
          setLoading(true);
          navigate("/verified-check", { state: { response: response, type } });
        }
        setIsSubmitting(false); // ✅ Hide Loader after timeout
      }, 2000);
    } catch (error) {
      console.error("Error adding bank account:", error);
      setIsSubmitting(false); // ✅ Ensure loader stops on error
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [])

  if (loading) return <LoadingScreen />;

  return (
    <div className={`flex flex-col justify-between font-general w-full  sm:w-[320px] h-auto text-center gap-6 ${type === "selectbank" && ""}`}>
      <span className="flex items-center justify-center relative gap-2">
      <FaChevronLeft onClick={() => navigate(-1)} className='text-gray-600 text-lg cursor-pointer absolute left-0' />
        <h2 className='text-2xl font-[500] leading-[33.6px] text-[#fff]'>
          Add Your Bank Account
        </h2>
      </span>
        <p className='font-[500] text-white/40 text-sm opacity-80'>
          Ensure you provide only your own account information to avoid
          transaction issues.
        </p>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className='relative w-full'>
          <input
            type="text"
            name="accountNumber"
            placeholder="Bank Account Number"
            value={accountNumber}
            onChange={handleInputChange}
            className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-transparent rounded-lg 
              bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
              focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general ${error.accountNumber ? "pr-48" : "pr-24"}
             text-ellipsis overflow-hidden
             `}
            inputMode="numeric" // Mobile keyboards show numeric layout
            autoComplete="off" // ✅ Disables autocomplete
            autoCorrect="off" // ✅ Helps disable autofill
            spellCheck="false" // ✅ Ensures no spell suggestions
          />

          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {!validateBankAccount(accountNumber) && accountNumber && (
              <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                Invalid Account Number
              </div>
            )}
          </div>
        </div>
        <div className="relative w-full">
          <input
            type='text'
            name='confirmAccountNumber'
            value={confirmAccountNumber}
            onChange={handleInputChange}
            placeholder="Confirm Bank Account Number"
            className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-transparent rounded-lg 
               bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
               focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general ${error.confirmAccountNumber ? "pr-48" : "pr-24"}
              text-ellipsis overflow-hidden
              `}
            inputMode='numeric' // Mobile keyboards show numeric layout
            autoComplete="off" // ✅ Disables autocomplete
            autoCorrect="off" // ✅ Helps disable autofill
            spellCheck="false" // ✅ Ensures no spell suggestions
          />
          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {confirmAccountNumber !== accountNumber && confirmAccountNumber && (
              <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                Account numbers do not match
              </div>
            )}
          </div>
        </div>
        <div className="relative w-full">
          <input
            type='text'
            name='accountName'
            value={accountName}
            onChange={handleInputChange}
            placeholder="Account Name"
            className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-transparent rounded-lg 
              bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
              focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general ${error.accountName ? "pr-48" : "pr-24"}
             text-ellipsis overflow-hidden
             `}
            autoComplete="off" // ✅ Disables autocomplete
            autoCorrect="off" // ✅ Helps disable autofill
            spellCheck="false" // ✅ Ensures no spell suggestions
          />
          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {!validateAccountName(accountName) && accountName && (
              <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                Invalid Account Name
              </div>
            )}
          </div>
        </div>
        <div className="relative w-full">
          <input
            type='text'
            name='ifscCode'
            value={ifscCode}
            onChange={handleInputChange}
            placeholder="IFSC Code"
            className={`w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm font-medium leading-[16.8px] border border-transparent rounded-lg 
              bg-white/10 text-white outline-none placeholder:text-xs placeholder-white/60 
              focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow font-general ${error.ifsc ? "pr-48" : "pr-24"}
             text-ellipsis overflow-hidden
             `}
            autoComplete="off" // ✅ Disables autocomplete
            autoCorrect="off" // ✅ Helps disable autofill
            spellCheck="false" // ✅ Ensures no spell suggestions
          />
          <div
            className='absolute right-4 top-[50%] translate-y-[-50%] flex items-center space-x-2'
            onMouseDown={(e) => e.preventDefault()}>
            {!validateIfsc(ifscCode) && ifscCode && (
              <div className="flex items-center text-[#FF5A5A] text-[12px] font-medium leading-[16.8px] font-general relative z-10">
                Invalid IFSC Code
              </div>
            )}
          </div>
        </div>

        <div className="relative w-full">
          <div className='relative w-full cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
            <input
              name='bankName'
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              placeholder='Bank Name'
              autoComplete="off" // ✅ Disables autocomplete
              autoCorrect="off" // ✅ Helps disable autofill
              spellCheck="false" // ✅ Ensures no spell suggestions
              className="w-full py-2 px-3 h-11 sm:h-8 sm:text-[12px] text-sm border border-transparent rounded-lg bg-white/10 text-white outline-none mb-5 placeholder:text-xs placeholder-white/60 focus:border focus:border-[#FFFFFF33] focus:ring-0 caret-glow cursor-pointer appearance-none"
              style={{
                MozAppearance: "none",
                appearance: "none",
              }}>

            </input>
            <div className="absolute right-3 top-6 sm:top-4 transform -translate-y-1/2 flex items-center pointer-events-none ">
    <FaAngleDown className="text-white/40" />
  </div>
            {isOpen && (<>
              <div className="absolute left-0 w-full top-12 sm:left-80 sm:top-0 text-white h-48 overflow-y-scroll rounded-lg ">
                <div className="absolute inset-0 pointer-events-none bg-gradient-to-b from-transparent via-white/5 to-transparent"></div>
                <div className="relative bg-zinc-900 sm:bg-[#D9D9D91A] rounded-lg">
                  {bankList.map((bank, index) => (
                    <option className="py-1 px-3 hover:bg-white/15 rounded-lg cursor-pointer" key={index} value={bank?.name} onClick={() => {
                      setBankName(bank?.name);
                      setIsOpen(false);
                    }}>
                      {bank?.name}
                    </option>
                  ))}
                </div>
              </div>
            </>)}
          </div>
        </div>
        <button
          type="submit"
          className={`w-full h-11 sm:h-8 text-sm font-[500] rounded-lg transition flex justify-center items-center  ${isSubmitting
            ? 'bg-white/50 text-black cursor-not-allowed' // Button turns gray when submitting
            : isFormValid
              ? 'bg-[#A7E92D] text-black'
              : 'bg-white/50 text-black cursor-not-allowed'
            }`}
          disabled={!isFormValid || isSubmitting}
        >
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <StageSpinner size={30} color="#000" loading={isSubmitting} />
            </div>) :
            'Verify Bank Details'
          }
        </button>
      </form>
      {showCheckOnboard && <CheckOnboard type={type} />}
    </div>
  );
};

export default AddBankAccount;
