import React, { useEffect, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import useTransactionStatusStore from '../../store/txStatusStore';
import { useNavigate } from 'react-router-dom';
import UseSellSelectorStore from '../../store/SellSelector';
import pendingGif from "../../assets/pending.gif"
import authFetch from '../../utils/authFetch';
import useWalletAddressStore from '../../store/WalletAddressStore';
const TransactionComplete = () => {
  const { clearAll, globalYouGet, globalCurrency } = UseSellSelectorStore();
  const { orderCreatedResp } = useWalletAddressStore()
  const { status, setStatus } = useTransactionStatusStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!orderCreatedResp) {
      navigate('/selling-history')
      return;
    }
    if (status === "SUCCESS") {
      setTimeout(() => { clearAll(); navigate('/selling-history') }, 3000);
    } else {
      pollTransactionStatus();
    }
  }, [])

  const pollTransactionStatus = () => {
    let attempts = 0;
    const maxAttempts = 24;

    const fetchStatus = async () => {
      try {
        let body = {
          reference_id: orderCreatedResp?.reference_id
        };
        const response = await authFetch('/api/v1/offramp/transactionStatus', {
          method: 'POST',
          body: body,
          headers: { 'Content-Type': 'application/json' },
        });

        if (response?.code === 200) {
          const responseData = response?.data
          const newStatus = responseData?.txStatus;
          setStatus(responseData?.status)
          if (newStatus === "SUCCESS") {
            setTimeout(() => {
              navigate('/selling-history');
            }, 3000); // ⏳ Wait 3 seconds before navigating
            return;
          }

          if (newStatus === "PENDING" && attempts < maxAttempts) {
            attempts++;
            setTimeout(fetchStatus, 5000);
          } else {
            navigate("/selling-history");
          }
        } else {
          navigate("/selling-history");
        }
      } catch (error) {
        console.error('Error fetching transaction status:', error);
        navigate("/selling-history");
      }
    };

    fetchStatus();
  };


  return (
    <div className='font-general w-[320px] h-[138px] text-center -mt-10'>
      <div className='flex justify-center mb-5 relative'>
        <div className='absolute w-24 h-24 bg-[#A7E92D] opacity-50 rounded-full blur-2xl animate-ping'></div>
        {
          status === "SUCCESS" ? (
            <FaCheckCircle className='text-6xl text-[#A7E92D] relative drop-shadow-[0_0_15px_#2AE920]' />
          ) : (
            <img
              src={pendingGif}
              alt="Processing..."
              className="w-16 h-16 object-contain"
            />
          )
        }

      </div>

      <h2 className="text-2xl font-[500] leading-[33.6px] mb-2 text-[#fff]">
        {status === "SUCCESS" ? "Transaction Completed!" : "Payout is in Progress"}
      </h2>

      <p className="font-[500] text-[#FFFFFF66] text-sm mb-4 opacity-80">
        {status === "SUCCESS"
          ? "Your funds have been successfully processed."
          : `Your payout of ${globalYouGet} ${globalCurrency?.fiatSymbol} is being processed. This may take a few minutes.`}
      </p>
      {
        status === "PENDING" && (
          <p
            onClick={() => navigate("/selling-history")}
            className="font-[500] text-[#A9EA2E] text-sm text-center underline cursor-pointer"
          >
            Check Selling History
          </p>
        )
      }

    </div>
  )
}

export default TransactionComplete