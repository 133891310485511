import React from 'react';
import { Toaster, toast } from 'react-hot-toast';

const Toast = () => {
    return <Toaster position="top-right" reverseOrder={false} />;
};

export const showToast = (message, type = 'success') => {
    switch (type) {
        case 'success':
            toast.success(message, {
                style: {
                    borderRadius: '8px',
                    background: '#4CAF50',
                    color: '#fff',
                    fontWeight: 'bold',
                },
            });
            break;
        case 'error':
            toast.error(message, {
                style: {
                    borderRadius: '8px',
                    background: '#f44336',
                    color: '#fff',
                    fontWeight: 'bold',
                },
            });
            break;
        case 'info':
            toast(message, {
                style: {
                    borderRadius: '8px',
                    background: '#2196F3',
                    color: '#fff',
                    fontWeight: 'bold',
                },
                icon: 'ℹ️',
            });
            break;
        case 'warning':
            toast(message, {
                style: {
                    borderRadius: '8px',
                    background: '#FF9800',
                    color: '#fff',
                    fontWeight: 'bold',
                },
                icon: '⚠️',
            });
            break;
        default:
            toast(message, {
                style: {
                    borderRadius: '8px',
                    background: '#fff', // White background
                    color: '#000', // Black text for contrast (since white on white is not visible)
                    fontWeight: 'normal', // Ensure text is not bold
                },
            });
    }
};

export default Toast;
