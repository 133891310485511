import React, { useState, useEffect } from 'react'
import SignIn from './SignIn';
import useLoginStore from "../../store/LoginStore";
import { useLocation, useNavigate } from 'react-router-dom';
import AddAadhaar from '../AddAadhaar/AddAadhaar';
import AddPhone from '../AddPhone/AddPhone';
import AddBankAccount from '../AddBankAccount/AddBankAccount';
import useLoadingStore from '../../store/LoadingStore';
import LoadingScreen from '../LoadingScreen/Loading';
const steps = ["Signin", "Add Phone", "Add Aadhaar", "Bank Details"];
const ExistingUserAuth = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const type = location.state?.type;
  const initialStep = type === "signin" ? 0 : type === "addPhone" ? 1 : type === "addAadhaar" ? 2 : 3;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const { globalEmail } = useLoginStore();
  const { loading, setLoading } = useLoadingStore();
  // Redirect to base route if globalEmail is empty
  useEffect(() => {
    if (!globalEmail && type === "signin") {
      navigate('/'); // Change '/' to your desired base route
    }else{
      setTimeout(() => {
        setLoading(false); 
      }, 500);
    }
  }, [globalEmail, navigate, type,setLoading]);
  useEffect(() => {
    setCurrentStep(initialStep); // Update step when type changes
  }, [type]);
  if (loading) return <LoadingScreen />; 

  return (
    <div className={`flex flex-col items-center justify-center text-center font-general w-full`}>
      <div className="flex space-x-4 h-5">
        {steps.map((step, index) => (
          <div key={index} className="flex flex-col items-center">
            <div
              className={`h-2 rounded-full transition-all duration-300 ${index === currentStep
                ? "w-10 bg-white"
                : "w-4 bg-gray-600"
                }`}
            ></div>
          </div>
        ))}
      </div>
        {type === 'signin' ? <SignIn /> : type === "addPhone" ? <AddPhone /> : type === "addAadhaar" ? <AddAadhaar /> : <AddBankAccount type={type} />}
    </div>
  )
}

export default ExistingUserAuth;