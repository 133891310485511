import { useState, useEffect } from "react";
import "./index.css";
import bgImage from "./assets/blurbg.jpeg";
import AppRoutes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import Toast from "./utils/newtoast";
export default function App() {
  const [country, setCountry] = useState("");
  const [type, setType] = useState("offramp");

  const userToken = localStorage.getItem("userToken");
  useEffect(() => {
    if (userToken) {
    }
  }, [userToken]);
  useEffect(() => {
    const fetchIpAndCountry = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const typeParam = params.get("type");
        setType(typeParam || "offramp");

        if (!typeParam) {
          window.history.replaceState(null, "", "/?type=offramp");
        }
      } catch (error) {
        console.error("Error fetching IP or country:", error);
      }
    };

    // fetchIpAndCountry();
  }, []);

  return (
    <Router>
      <div
        className='flex flex-col justify-center items-center bg-cover bg-center w-screen'
        style={{ backgroundImage: `url(${bgImage})` }}>
        <Toast />
        <div className='h-screen flex flex-col justify-center items-center min-h-screen w-screen p-5 bg-black/80 backdrop-blur-md text-white'>
          <AppRoutes />
        </div>
      </div>
    </Router>
  );
}
