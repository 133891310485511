import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import UseSellSelectorStore from "../../store/SellSelector";
const steps = [
  "Select Bank Account",
  "Review And Confirm",
  "Deposite",
  "Transaction Details",
];

const ActiveSection = ({ type }) => {
  const {
    globalCurrency,
    globalCoin,
    globalYouSell,
    globalYouGet,
    globalQuoteData,
    globalNetwork,
    clearAll
  } = UseSellSelectorStore();
  const navigate = useNavigate();
  const initialStep =
    type === "selectbank"
      ? 0
      : type === "reviewconfirm"
        ? 1
        : type === "deposite"
        && 2
  const [currentStep, setCurrentStep] = useState(initialStep);
  useEffect(() => {
    setCurrentStep(initialStep); // Update step when type changes
  }, [type]);
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      type === "reviewconfirm" ? navigate("/select-bank") : type === "deposite" ? navigate("/review-confirm") : navigate("/sell");
    } else {
      navigate("/sell");
    }
  }
  useEffect(() => {
    if (!globalYouGet || !globalYouSell || !globalCoin || !globalCurrency || !globalNetwork || !globalQuoteData) {
      navigate('/sell');
    }
  }, [globalYouSell, globalYouGet, globalCoin, globalCurrency, globalNetwork, globalQuoteData])

  return (
    <div className='flex justify-center '>
      <div className='flex items-center justify-between w-[350px]'>
        <FaChevronLeft onClick={() => handleBack()} className='text-gray-600 text-lg cursor-pointer mb-2' />
        <div className='flex-1 flex justify-center'>
          <div className='flex space-x-4 h-5'>
            {steps.map((step, index) => (
              <div key={index} className='flex flex-col items-center'>
                <div
                  className={`h-2 rounded-full transition-all duration-300 ${index === currentStep ? "w-10 bg-white" : "w-4 bg-gray-600"
                    }`}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveSection;
