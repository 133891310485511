import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaClock, FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import authFetch from '../utils/authFetch';
import { useNavigate } from 'react-router-dom';
import { convertToIST } from '../utils/dateTimeFormat';
import { LuRepeat } from 'react-icons/lu';
import useRetryTransactionStore from '../store/retryTransactionStore';
import RetryModal from '../Modal/RetryModal';
import {PuffLoader} from 'react-spinners';
const SellHistory = () => {
    const navigate = useNavigate()
    const {
        retryTransaction,
        setRetryTransaction
    } = useRetryTransactionStore();

    const [sellHistoryTx, setSellHistoryTx] = useState([])
    const [loading, setLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const getSellHistory = async () => {
        try {
            const response = await authFetch('/api/v1/offramp/getAllTransactions', {
                method: 'GET',
            });

            if (response?.code === 200) {
                setSellHistoryTx(response?.data);
                setLoading(false)
            } else {
                navigate('/login')
            }
        } catch (error) {
            console.error('Error fetching getSellHistory:', error);
            navigate('/login')
        }
    };
    const getStatusIcon = (txStatus) => {
        if (txStatus === "SUCCESS") {
            return <FaCheckCircle className="text-green-500 text-xl" />;
        } else if (txStatus === 'PENDING') {
            return <FaClock className="text-yellow-500 text-xl" />;
        } else if (txStatus === 'FAILED') {
            return <FaTimesCircle className="text-red-500 text-xl" />;
        }
    };
    useEffect(() => {
        getSellHistory()
    }, [])
    return (
        <div className="flex flex-col h-[570px] p-0 min-w-[20rem]">
            {loading ? (<>
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60 z-50">
                {/* PuffLoader for loading spinner */}
                <PuffLoader color="#28d849" size={60} />
            </div>
            </>):(<>
                <h2 className="text-center text-2xl text-gray-900 font-bold mb-2 ">Sell History</h2>
                { sellHistoryTx.length === 0 ? (
                // If no transactions, show this message
                <div className="flex justify-center items-center mt-10">
                    <div className="bg-white p-6 shadow-lg rounded-lg text-center w-[20rem]">
                        <p className="text-xl font-semibold text-gray-700">No Transactions Yet</p>
                        <p className="text-gray-500 mt-2 text-sm">
                            You haven't made any transactions yet. Once you make a transaction, your transaction history will be displayed here.
                        </p>
                        <Link to="/sell">
                            <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-200">
                                Start Selling
                            </button>
                        </Link>
                    </div>
                </div>
            ) : (

                <ul className="space-y-2">
                    {sellHistoryTx && sellHistoryTx.map((transaction, index) => (
                        <Link
                            key={index}
                            to={"/history-detail"}
                            state={{ transaction, transactionType: "sell" }}
                        >
                            <li

                                className="flex justify-between items-center border-b py-4 px-2 hover:bg-gray-100 transition-colors duration-200 rounded-lg"
                            >
                                <div className="flex items-center gap-x-4">
                                    {getStatusIcon(transaction?.status)}
                                    <div className="space-y-1 min-w-[160px]">
                                    <div className="relative group flex items-center">
                                        <p className="font-medium w-max">Tnx Id:
                                            {transaction?.transaction_id.length > 9
                                                ? `${transaction?.transaction_id.substring(0, 3)}...${transaction?.transaction_id.slice(-4)}`
                                                : transaction?.transaction_id}
                                        </p>
                                        <div className="absolute bottom-full w-max mb-2 left-3/4 transform -translate-x-1/2 hidden group-hover:block bg-gray-600 text-white text-sm font-medium rounded-lg px-2 py-1 shadow-lg">
                                            {transaction && transaction?.transaction_id}
                                        </div>
                                    </div>

                                        <p className="text-gray-500 text-sm">Status: {transaction?.status?.charAt(0)?.toUpperCase() + transaction?.status?.slice(1)?.toLowerCase()}</p>
                                    </div>
                                </div>
                                {(transaction?.status === "FAILED") && (
                                    <div className="relative group flex items-center">
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRetryTransaction(transaction);
                                                setIsModalOpen(true);
                                            }}
                                            className="rounded-lg shadow-sm border-2 flex p-2"
                                        >
                                            <LuRepeat className="text-lg text-gray-500" />
                                        </button>
                                        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-600 text-white text-sm font-medium rounded-lg px-2 py-1 shadow-lg">
                                            Retry
                                        </div>
                                    </div>
                                )}
                                <div className="flex flex-col">
                                    <span className="text-gray-500 text-xs">
                                        {convertToIST(transaction?.date).date}
                                    </span>
                                    <span className="text-gray-500 text-xs">
                                        {convertToIST(transaction?.date).time}
                                    </span>
                                </div>

                            </li>
                        </Link>

                    ))}
                </ul>
            )}
            {isModalOpen && (<RetryModal setIsModalOpen={setIsModalOpen} getSellHistory={getSellHistory} />
            )}
            </>)}
        </div>
    );
};

export default SellHistory;
