import React from "react";
import { Toaster, toast } from "react-hot-toast";

const Toast = () => {
  return (
    <Toaster
      toastOptions={{
        style: {
          width: "262px",
          height: "40px",
          padding: "4px, 16px",
          borderRadius: "12px",
        },
      }}
      position='top-center'
      reverseOrder={false}
    />
  );
};

export const showToast = (message, type = "success") => {
  switch (type) {
    case "success":
      toast.success(message, {
        icon: null,
        style: {
          borderRadius: "8px",
          background: "#F4F4F4",
          color: "#000000",
          fontWeight: "500",
          fontFamily: "GeneralSans, sans-serif",
          fontSize: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        },
      });
      break;
    case "error":
      toast.error(message, {
        icon: null,
        style: {
          borderRadius: "8px",
          background: "#FF5A5A",
          color: "#F4F4F4",
          fontWeight: "500",
          fontFamily: "GeneralSans, sans-serif",
          fontSize: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        },
      });
      break;
    case "info":
      toast(message, {
        style: {
          borderRadius: "8px",
          background: "#2196F3",
          color: "#fff",
          fontWeight: "bold",
        },
        icon: "ℹ️",
      });
      break;
    case "warning":
      toast(message, {
        style: {
          borderRadius: "8px",
          background: "#FF9800",
          color: "#fff",
          fontWeight: "bold",
        },
        icon: "⚠️",
      });
      break;
    default:
      toast(message, {
        style: {
          borderRadius: "8px",
          background: "#333",
          color: "#fff",
          fontWeight: "bold",
        },
      });
  }
};

export default Toast;
